module.exports = {
  themeConfig: {
    // theme主题配置项
    '@ant-prefix': 'ant',
    'primary-color': '#ff7b00'
  },
  navTheme: 'dark', // dark/light
  layout: 'sidemenu', // nav menu position: sidemenu or topmenu
  contentWidth: 'Fluid', // layout of content: Fluid or Fixed, only works when layout is topmenu
  fixedHeader: false, // sticky header
  autoHideHeader: false, // auto hide header
  fixSiderbar: false, // sticky siderbar
  showloginTheme: true, // show login theme
  multiTabs: true, // multiple Tabs mode
  title: 'U Mobile Partner SelfCare',
  logoTheme: 'logoCard', // logoCard/logoImgCard
  defaultLanguage: 'en-US', // 前后端默认语言
  allOrgDetail: true, // 组织详情表单是否显示全部
};
