export default {
  namespace: 'InquiryTabsPage',
  state: {
    defaultActiveKey: '',
  },

  effects: {
    *setActiveKey({ payload }, { call, put }) {
      yield put({ type: 'save', payload: { defaultActiveKey: payload.defaultActiveKey } });
      return Promise.resolve();
    },
  },

  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
