import { routerRedux } from 'dva/router';
import memoizeOne from 'memoize-one';
import { formatMessage } from 'umi/locale';
import { message } from '@whalecloud/fdx';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import get from 'lodash/get';
import router from 'umi/router';
import {
  query as queryUsers,
  queryCurrent,
  queryMenus,
  queryFavoriteMenu,
  addFavoriteMenu,
  queryPortalsByUser,
  setCurrentPortal,
  setFavorites,
  deleteFavorites,
  qryWorkerOrderAPI,
  confirmMcpAPI,
} from '@/services/user';
import { menuAdapter } from '@/utils/utils';
import loginSession from '@/utils/loginSession';

/**
 * 获取面包屑映射
 * @param {Object} menuData 菜单配置
 */
const getBreadcrumbNameMap = menuData => {
  const routerMap = {};
  let path = '';
  const flattenMenuData = data => {
    data.forEach((menuItem, index) => {
      if (menuItem.children) {
        path += menuItem.path;
        flattenMenuData(menuItem.children);
      }
      // Reduce memory usage
      routerMap[`${path}${menuItem.path}`] = menuItem;
      if (index === data.length - 1 && !menuItem.children && menuItem.type === '1') {
        path = '';
      }
    });
  };
  flattenMenuData(menuData);
  return routerMap;
};
const memoizeOneGetBreadcrumbNameMap = memoizeOne(getBreadcrumbNameMap, isEqual);

export default {
  namespace: 'user',

  state: {
    list: [],
    currentUser: {},
    menu: [],
    rawMenu: [],
    breadcrumbNameMap: {},
    portals: [],
    menuLoaded: false,
    favorites: [],
    isMcp: false,
    todo: 0,
  },

  effects: {
    *addFavoriteMenu(_, { call }) {
      yield call(addFavoriteMenu);
    },
    *fetchMenu({ payload = {} }, { call, put, select }) {
      const { currentUser } = yield select(state => state.global);
      const { portalId } = currentUser;
      const { success, data } = yield call(queryMenus, portalId);
      if (success) {
        const menuData = menuAdapter(data, 'partyId', 'parentId', 'children') || [];
        const menu = get(menuData, '[0].children') || [];
        const breadcrumbNameMap = memoizeOneGetBreadcrumbNameMap(menu);
        yield put({
          type: 'saveMenu',
          payload: {
            menu,
            rawMenu: data,
            breadcrumbNameMap,
            menuLoaded: true,
          },
        });
        const { from = '' } = payload;
        if (from === 'Login') {
          const { redirect = '/Own' } = payload;
          if (redirect && redirect.indexOf('/login') <= -1) {
            // 判断redirect是否存在于menuList
            if (data.some(v => includes(redirect, v.url))) {
              window.location.href = redirect;
            } else {
              yield put({
                type: 'fetchDefaultMenu',
                payload: {
                  from,
                },
              });
            }
          } else if (window.location.hash.indexOf('/login') <= -1) {
            yield put({
              type: 'fetchDefaultMenu',
              payload: {
                from,
              },
            });
          }
        }
      }
    },
    *fetchDefaultMenu({ payload = {} }, { call, put, select }) {
      const { currentUser } = yield select(state => state.global);
      const { portalId } = currentUser;
      const { success, data } = yield call(queryFavoriteMenu, portalId);
      if (success) {
        const defaultMenu =
          data.find(v => v.isDefaultOpen === 'Y') ||
          (data.length > 0 ? data[data.length - 1] : null);
        let defaultUrl = defaultMenu ? defaultMenu.url : '/profile/ownProfile';
        defaultUrl = defaultUrl.indexOf('/profile/ownProfile') > -1 ? defaultUrl : `/${defaultUrl}`;
        window.portal.defaultUrl = defaultUrl;
        if (payload.from || loginSession.getData('changePortal') === true) {
          yield put(routerRedux.push(defaultUrl));
          loginSession.saveData('changePortal', false);
        }
        if (payload.getData) {
          const newData = JSON.parse(JSON.stringify(data));
          newData.filter((item, index) => {
            Object.assign(item, { key: `favorite-${index}` });
            return item;
          });
          yield put({
            type: 'saveFavorites',
            payload: {
              favorites: newData,
            },
          });
        }
      }
    },

    *fetchPortals(_, { call, put, select }) {
      const {
        currentUser: { userId },
      } = yield select(state => state.global);
      const { success, data } = yield call(queryPortalsByUser, userId);
      if (success) {
        yield put({
          type: 'saveMenu',
          payload: { portals: data },
        });
      }
    },
    *setCurrentPortal({ payload }, { call }) {
      const { success, data } = yield call(setCurrentPortal, payload);
      if (success && data === 'success') {
        loginSession.saveData('changePortal', true);
        router.push('/');
        window.location.reload();
      }
    },
    *fetch(_, { call, put }) {
      const { success, data } = yield call(queryUsers);
      if (success) {
        yield put({
          type: 'save',
          payload: data,
        });
      }
    },
    *fetchCurrent(_, { call, put }) {
      const { success, data } = yield call(queryCurrent);
      if (success) {
        yield put({
          type: 'saveCurrentUser',
          payload: data,
        });
      }
    },
    *setFavorite({ payload }, { call, put }) {
      const { param, method } = payload;
      const response = yield call(setFavorites, param, method);
      if (response.success) {
        yield put({
          type: 'fetchDefaultMenu',
          payload: {
            getData: true,
          },
        });
        message.success(formatMessage({ id: 'SUCCESS_FAVORITE_NEMU' }));
      } else if (response === 'success') {
        yield put({
          type: 'fetchDefaultMenu',
          payload: {
            getData: true,
          },
        });
        message.warning(formatMessage({ id: 'FAILED_MODIFY_FAVORITE_NEMU' }));
      } else {
        message.warning(
          method === 'POST'
            ? formatMessage({ id: 'FAILED_FAVORITE_NEMU' })
            : formatMessage({ id: 'FAILED_MODIFY_FAVORITE_NEMU' }),
        );
      }
    },
    *delFavorite({ payload }, { call, put }) {
      const { menuFavId } = payload;
      const { success } = yield call(deleteFavorites, menuFavId);
      if (success) {
        yield put({
          type: 'fetchDefaultMenu',
          payload: {
            getData: true,
          },
        });
        message.success(formatMessage({ id: 'SUCCEED_DELETE_FAVORITE_NEMU' }));
      } else {
        message.warning(formatMessage({ id: 'FAILED_DELETE_FAVORITE_NEMU' }));
      }
    },
    *qryWorkerOrder(_, { call, put }) {
      const { data } = yield call(qryWorkerOrderAPI);
      if (data.code === 200) {
        yield put({
          type: 'savePayload',
          payload: {
            todo: get(data, 'data.value', 0),
          },
        });
      }
    },
    *confirmMcp(_, { call, put }) {
      const { data } = yield call(confirmMcpAPI);
      if (data.code === 200) {
        yield put({
          type: 'savePayload',
          payload: {
            isMcp: get(data, 'data.value'),
          },
        });
      }
    },
  },

  reducers: {
    saveMenu(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    savePayload(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload,
        },
      };
    },
    saveFavorites(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
