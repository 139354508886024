import request from '@/utils/request';
import { NGPORTAL_HEADER_API } from '@/ecareDefaultSettings';

export async function queryUserLastOper() {
  return request(`${NGPORTAL_HEADER_API}/users/lastoper`);
}

export async function queryUserLastLogin() {
  return request(`${NGPORTAL_HEADER_API}/users/lastlogin`);
}
export async function queryUserPortlets(portalId) {
  return request(`${NGPORTAL_HEADER_API}/portals/${portalId}/user/portlets`);
}

export async function queryUserFavorites(portalId) {
  return request(`${NGPORTAL_HEADER_API}/portals/${portalId}/user/favorites`);
}

export async function queryDashboardLayout(portalId) {
  return request(`${NGPORTAL_HEADER_API}/portals/${portalId}/layouts/default`);
}

export async function saveDashboardLayout({ portalId, params, method }) {
  return request(`${NGPORTAL_HEADER_API}/portals/${portalId}/layouts`, {
    method,
    body: params,
  });
}

export async function setDefaultLayout({ portalId, LayoutId }) {
  return request(`${NGPORTAL_HEADER_API}/portals/${portalId}/layouts/${LayoutId}/default`, {
    method: 'PATCH',
  });
}
