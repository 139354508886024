import { get } from 'lodash';
import {
  getOrderStates,
  getSubtypeList,
  qryTransData,
  refunt,
  transDataExport,
  downloadOfferList,
  getConfigItemParam,
  getEventType,
  getAuthority,
} from '../pages/Transaction/services';

const initialState = {
  rowTotal: 0,
  transactionList: [],
  offerList: [],
  partnerList: [],
  orderStateList: [],
  subtypeList: [],
  configItemParam: 0,
  transactionTypeList: [],
  authorities: [],
  maxQueryMonth: 6,
};

export default {
  namespace: 'transaction',
  state: {
    ...initialState,
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询合作伙伴及其子伙伴的Offer列表
    *qryTransData({ payload }, { call, put }) {
      const response = yield call(qryTransData, payload);
      if (response) {
        const { data: result } = response;
        const { prcTransDataDtos } = get(result, 'data') || [];
        const { rowTotal } = get(result, 'data') || 0;
        if (result && result.code === 200) {
          yield put({
            type: 'updateState',
            payload: {
              transactionList: prcTransDataDtos,
              rowTotal,
            },
          });
        }
      }
      return null;
    },

    // 通过配置项查最大可查询月份
    *getMaxQueryMonth({ payload }, { call, put }) {
      const { success, data } = yield call(getConfigItemParam, payload);
      if (success) {
        const maxQueryMonth = get(data, 'data.value');
        yield put({
          type: 'updateState',
          payload: {
            maxQueryMonth,
          },
        });
        return maxQueryMonth;
      }
      return null;
    },

    *getOrderStates(_, { call, put }) {
      const response = yield call(getOrderStates);
      if (response) {
        const { data: result } = response;
        if (result && result.code === 200) {
          yield put({
            type: 'updateState',
            payload: {
              orderStateList: get(result, 'data') || [],
            },
          });
        }
      }
    },

    *getSubtypeList(_, { call, put }) {
      const response = yield call(getSubtypeList);
      if (response) {
        const { data: result } = response;
        if (result && result.code === 200) {
          yield put({
            type: 'updateState',
            payload: {
              subtypeList: get(result, 'data') || [],
            },
          });
        }
      }
    },

    *refunt({ payload, success, error }, { call }) {
      const response = yield call(refunt, payload);
      if (response) {
        const { data } = response;
        const code = get(data, 'code') || 0;
        if (success && code === 200) {
          success();
        } else {
          error(code);
        }
      }
    },

    *transDataExport({ payload }, { call }) {
      const response = yield call(transDataExport, payload);
      const { data: result } = response;
      if (result && result.code === 200) {
        return result;
      }
      return null;
    },

    // 导出合作伙伴及其子伙伴的Offer列表：下载
    *downloadOfferList({ payload }, { call }) {
      const { data: result, success } = yield call(downloadOfferList, payload);
      if (success) {
        const fileBlob = new Blob([result]);
        const blobUrl = window.URL.createObjectURL(fileBlob); // 创建下载的链接
        const a = document.createElement('a'); // 创建完成动作的元素
        a.style.display = 'none';
        a.download = payload.fileName; // 下载后的文件名
        document.body.appendChild(a); // 添加元素
        a.href = blobUrl;
        a.click();
        document.body.removeChild(a); // 下载完成移除元素
        window.URL.revokeObjectURL(blobUrl); // 释放掉blob对象
        return result;
      }
      return null;
    },

    *getCurrencyPrecison({ payload }, { call, put }) {
      const response = yield call(getConfigItemParam, payload);
      const { data: result } = response;
      const configItemParam = get(result, 'data.value') || 0;
      yield put({
        type: 'updateState',
        payload: {
          configItemParam,
        },
      });
    },

    *getTransactionTypeList({ payload }, { call, put }) {
      const { success, data = {} } = yield call(getEventType, payload);
      if (success) {
        const transactionTypeList = get(data, 'data') || [];
        yield put({
          type: 'updateState',
          payload: {
            transactionTypeList,
          },
        });
      }
    },

    *initializeState(_, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          ...initialState,
        },
      });
    },
    // 获取页面权限控制
    *getAuthority({ payload }, { call, put }) {
      const { success, data } = yield call(getAuthority, payload);
      if (success) {
        yield put({
          type: 'updateState',
          payload: {
            authorities: get(data, 'data'),
          },
        });
      }
    },
  },
};
