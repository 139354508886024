/* eslint-disable */
export default {
  LOG_SRC_IP: 'Source IP',
  USERMGR_USER_NAME: 'User Name',
  USERMGR_USER_CODE: 'User Code',
  LOG_EVENT_SOURCE: 'Event Source',
  LOG_EVENT_TYPE: 'Event Type',
  LOG_EVENT_CODE: 'Event Code',
  LOG_LOG_DATE: 'Log Date',
  'navBar.lang': 'Languages',
  'menu.account.logout': 'Logout',
  'app.exception.back': 'Back to home',
  'app.exception.description.403': "Sorry, you don't have access to this page",
  'app.exception.description.404': 'Sorry, the page you visited does not exist',
  'app.exception.description.500': 'Sorry, the server is reporting an error',
  OPERATE: 'Action',
  COMMON_DETAIL: 'Detail',
  COMMON_YES: 'Y',
  COMMON_NO: 'N',
  COMMON_ACTIVE: 'Active',
  COMMON_INACTIVE: 'Inactive',
  COMMON_REMARKS: 'Remarks',
  BTN_SEARCH: 'Search',
  BTN_RESET: 'Reset',
  COMMON_NEW: 'New',
  COMMON_EDIT: 'Edit',
  COMMON_DELETE: 'Delete',
  COMMON_CONFIRM: 'Confirm',
  COMMON_MODAL: 'Modal',
  COMMON_OK: 'OK',
  COMMON_CANCEL: 'Cancel',
  COMMON_CLOSE: 'Close',
  COMMON_GRANT: 'Grant',
  COMMON_PORTAL: 'GrantPortal',
  COMMON_ROLE: 'GrantRole',
  COMMON_COMPONENT: 'GrantComponent',
  COMMON_PORTLET: 'GrantPortlet',
  COMMON_LOCK: 'Lock',
  COMMON_UNLOCK: 'Unlock',
  COMMON_DISABLE: 'Disable',
  COMMON_ENABLE: 'Enable',
  COMMON_SELECT_ICON: 'Please Select',
  COMMON_MONTH: 'This Mohth',
  COMMON_LAST_MONTH: 'Last Month',
  COMMON_WEEK: 'This Week',
  COMMON_LAST_WEEK: 'Last Week',
  COMMON_DAY: 'Today',
  COMMON_DATE_PLACE: 'Please Select Date',
  COMMON_RANGE_START_PLACE: 'Start Date',
  COMMON_RANGE_END_PLACE: 'End Date',
  COMMON_REQUIRED: 'Required',
  COMMON_NAME: 'Name',
  COMMON_URL: 'URL',
  COMMON_REQUIRED_TIP: 'The field is required.',
  COMMON_SEARCH: 'Search',
  COMMPN_DATA_EMPTY: 'No Data',
  COMMON_STATE: 'State',
  COMMON_NO_DATA: 'No data found',
  SECONDS: 'seconds',
  REQUEST_ERROR: 'Request Error',
  REQUEST_200: 'The server returned the requested data successfully',
  REQUEST_201: 'New or modified data successfully',
  REQUEST_202: 'A request has entered the background queue',
  REQUEST_204: 'Delete Successfully',
  REQUEST_400: 'An error was made in the request and the server did not create or modify the data',
  REQUEST_401: 'No Permission',
  REQUEST_403: 'Users are authorized, but access is prohibited',
  REQUEST_404:
    'The request was made for a record that did not exist and the server did not operate',
  REQUEST_406: 'The requested format is not available',
  REQUEST_410: 'The requested resource is permanently deleted and will not be retrieved',
  REQUEST_422: 'validation Error',
  REQUEST_500: 'An error occurred on the server. Please check the server',
  REQUEST_502: 'Gateway Error',
  REQUEST_503: 'Services Rre Unavailable',
  REQUEST_504: 'Gateway Timeout',
  BUTTON_ADD: 'Add',
  FRAME_FAVOURITE_CUSTOMERS: 'Favourite Customer',
  SYSTEM_SESSION_CONTINUE: 'Continue Session',
  SYSTEM_AUTO_LOGOUT: 'Your session is about to expire.',
  SYSTEM_SESSION_EXPIRE_NOTICE: 'You will be logged out in',
  ADD_COMPONENT_PRIVILEGE: 'Add Component Privilege',
  PRIV_NAME_ERR: 'Please input the privilege name of component',
  THEME_TITLE: 'Theme',
  COMMON_LOWER: 'Lower',
  COMMON_LOW: 'Low',
  COMMON_MODERATE: 'Medium',
  COMMON_HIGH: 'High',
  COMMON_HIGHER: 'Higher',
  COMMON_SUCCESS: 'Success',
  'menu.account.customize': 'Customize',
  'menu.expand': 'Expand Menu',
  'menu.shrink': 'Shrink Menu',
  'menu.home': 'Home',
  REFRESH_PAGE: 'Refresh Page',
  'menu.account.aboutus': 'Version Information',
  'menu.about': 'About',
  'menu.ok': 'Close',
  'menu.account.profile': 'Profile',
  COMMON_INPUT_REQUIRED: 'This field is required.',
  COMMON_SUBMIT: 'Submit',
  COMMON_OPEN: 'Open',
  SUCCESS_OPEN: 'Succeed in opening menu.',
  SUCCESS_FAVORITE_NEMU: 'Succeed in favorite menu.',
  FAILED_FAVORITE_NEMU: 'Failed in favorite menu.',
  SUCCESS_MODIFY_FAVORITE_NEMU: 'Succeed in modifing the favorite menu.',
  FAILED_MODIFY_FAVORITE_NEMU: 'Failed in modifing the favorite menu.',
  SUCCEED_DELETE_FAVORITE_NEMU: 'Succeed in deleting the favorite menu.',
  FAILED_DELETE_FAVORITE_NEMU: 'Failed in deleting the favorite menu.',
  FAVORITE_NEMU_ONLYONE_TIPS: 'The default open menu has been set, only one.',
  'menu.account.favorites': 'Favorites',
  'version.main.product': 'Include Products:',
  'version.copyright': 'Copyright(c) 2010-2019 Whale Cloud Technology Co., Ltd.',
  'version.welcome':
    'Copyright(c) 2010-2019 Whale Cloud Technology Co., Ltd. All Rights Reserved. If you need any help or have any concern, please email to Branding_Public@iwhalecloud.com or dial our customer support hotline +86-25-6669555 Thank you for using Whale Cloud ZSmart. Warning: this computer program is protected by copyright law and international treaties. Unauthorized reproduction or distribution of this program, or any portion of it, may result in severe civil and criminal penalties, and will be prosecuted to the maximum extent possible under the law.',
  'version.thank.using': 'Thank you for using ZSmart product.',
  'version.main.first': 'fix small bugs',
  'version.main.second': 'optimize',
  CHANGE_PWD_MSG: 'Please change your password',
  CHANGE_PWD_SUCCESS: 'Password reset complete',
  // todo_bankendtips
  // common
  'common.previous': 'Previous',
  'common.next': 'Next',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.ok': 'OK',
  'common.cancel': 'Cancel',
  'common.reset': 'Reset',
  'common.query': 'Query',
  'common.send': 'SEND',
  'common.confirm': 'Confirm',
  'common.download': 'Download',
  'common.export': 'Export',
  'common.upload': 'Upload',
  'common.detail': 'Detail',
  'common.delete': 'Delete',
  'common.edit': 'Edit',
  'common.join': 'Join',
  'common.join_us': 'Join Us',
  'common.approve': 'Approve',
  'common.reject': 'Reject',
  'common.gross': 'Gross',
  'common.nett': 'Nett',
  'common.submit': 'Submit',
  'common.check': 'Check',
  'common.cancelRequest': 'Cancel Request',
  'common.exit': 'Exit',
  'common.RFI': 'Request For Information',
  'common.fileTemplate': 'File Template',
  'common.match': 'Match',
  'common.viewDetail': 'View Detail',
  'common.selected': 'Selected',
  'common.excluded': 'Excluded',
  'common.label.attachment': 'Attachment',
  'common.button.Suspension': 'Suspension',
  'common.button.Resume': 'Resume',
  'common.button.Termination': 'Termination',
  'common.button.Block': 'Block',
  'common.single': 'Single',
  'common.batch': 'Batch',
  'common.msisdn': 'MSISDN',
  'common.PartnerStatus.Active': 'Active',
  'common.PartnerStatus.Suspension': 'Suspension',
  'common.PartnerStatus.Termination': 'Termination',
  'common.PartnerStatus.Register': 'Register',
  'common.PartnerStatus.Inactive': 'Inactive',
  'common.PartnerStatus.Lock': 'Lock',
  'common.tips.requestInProcessing': 'Processing your request...',
  'common.placeholder.verification_code': 'SMS Verification Code',
  'common.placeholder.captcha': "Please input the 'Captcha'",
  'common.tips.pleaseUploadAttachment': 'Attachment is mandatory.',
  'common.tips.pleaseUploadAllAttachment': 'Please upload all attachments.',
  'common.tips.unkownError': 'Unkown error, please check the network',
  'common.title.result_message': 'Result message',
  'common.title.order_number': 'Order Number: {orderNbr}',
  // join
  'join.button.add': '+ Add',
  'join.label.Authorized_Signatory_Person': 'Authorized Signatory Person',
  'join.label.Authorized_Signatory_Person.tooltip':
    'Director Name with authorized signatory for legal documents',
  'join.title.Partner_Information': 'Partner Information',
  'join.form.label.Partner_Name': 'Partner Name',
  'join.form.label.Partner_Name.required': "Please input 'Partner Name', such as 'Umobile'.",
  'join.form.label.Partner_Name.placeholder': "Please input 'Partner Name'.",
  'join.form.label.Partner_Name.verify': 'The maximum length is 255.',
  'join.form.label.Partner_Registration_No': 'Partner Registration No.',
  'join.form.label.ABN_VAT_GST_Number': 'ABN/VAT/GST Number',
  'join.form.label.Partner_Type': 'Partner Type',
  'join.form.label.Main_Contact_Name': 'Main Contact Name',
  'join.form.label.Main_Contact_Number': 'Main Contact Number',
  'join.form.label.Email': 'Email',
  'join.form.label.Main_Contact_Designation': 'Main Contact Designation',
  'join.form.label.Fax_Number': 'Fax Number',
  'join.form.label.Registered_Address': 'Registered Address',
  'join.form.label.Correspondence_Address': 'Correspondence Address',
  'join.form.label.Company_Type': 'Company Type',
  'join.form.label.Tax_Identification_Number': 'Tax Identification Number',
  'join.form.label.SST_Registration_Number': 'SST Registration Number',
  'join.form.label.Tourism_Tax_Registration_Number': 'Tourism Tax Registration Number',
  'join.form.label.Malaysia_Standard_Industrial_Classification_Code': 'MSIC Code',
  'join.form.label.Business_Activity_Description': 'Business Activity Description',
  'join.tabel.header.Contact_Name': 'Contact Name',
  'join.tabel.header.Designation': 'Designation',
  'join.tabel.header.Type': 'Type',
  'join.tabel.header.Is_Default': 'Is Default',
  'join.tabel.header.Email': 'Email',
  'join.tabel.header.Contact_Number': 'Contact Number',
  'join.tabel.header.Office_Contact_Number': 'Office Contact Number',
  'join.tabel.header.Extension': 'Extension',
  'join.tabel.header.Operation': 'Operation',
  'join.form.label.Service_Type': 'Service Type',
  'join.form.label.Service_Name': 'Service Name',
  'join.form.label.Short_Code': 'Short Code',
  'join.form.label.Attachment': 'Attachment',
  'join.form.label.moURL': 'MO URL',
  'join.form.label.dnURL': 'DN URL',

  'join.title.Other_Contacts_Information': 'Other Contacts Information',
  'join.title.Other_Contacts_Information.tooltip':
    'Kindly provide other contacts such as Customer Care, Finance and Technical Support Contact Details',
  'join.title.Service_Information': 'Service Information',
  'join.title.Service_Proposal': 'Service Proposal',
  'join.alert.warning.readConditionsFirst':
    'Please indicate that you have read and agreed to the Terms and Conditions.',
  'join.alert.success.registrition':
    'Registration has been submitted for review. Status of registration will be notified to you via email soon.',
  'join.options.partner_type.financial': 'Financial Services Partner',
  'join.options.partner_type.enterprise': 'Enterprise Partner',
  'join.options.partner_type.consumer': 'Consumer Services Partner',
  // login
  'login.tips.passwordIsDefault': 'The password is default, please modify it.',
  // 'login.bodyInfo.Partner_Relationship': 'Partner Relationship',
  // 'login.bodyInfo.Management_Introduction': 'Management Introduction',
  'login.bodyInfo.Partner_Self_Care_Portal': 'Partner SelfCare Portal',
  'login.title.Partner_Login': 'Partner Login',
  'login.form.label.Login_Email': 'Login Email',
  'login.form.label.Login_Email.required': 'Login email is required.',
  'login.form.label.Password': 'Password',
  'login.form.label.Password.required': 'Password is required.',
  'login.form.label.Captcha': 'Verification Code',
  'login.form.label.Captcha.required': 'Verification Code is required.',
  'login.form.label.NewCaptcha': 'OTP Verification Code',
  'login.form.label.NewCaptcha.required': 'OTP Verification Code is required.',
  'login.form.Checkbox.Remember_Me': 'Remember Me',
  'login.form.button.login': 'Log In',
  'login.form.linkto.Partner_Registration': 'Partner Registration',
  'login.form.linkto.Forgot_Password': 'Forgot Password?',
  // MCP Partner Profile
  'MCPPartnerProfile.tips.at_least_one_partner_to_choose':
    'Choose at least one partner to manipulate each time.',
  'MCPPartnerProfile.title.MCP_Partner_Profile': "My Partner's Profile",
  'MCPPartnerProfile.title.Batch_Request_Result': 'Batch Request Result',
  'MCPPartnerProfile.tips.fileAnalyzeHasFailed':
    "'For some reason, the file analyze has failed! Please try again later.'",
  'MCPPartnerProfile.tips.noPartner': 'No partner',
  'MCPPartnerProfile.tips.BlockChargingSuccessfully': 'Block charging successfully',
  'MCPPartnerProfile.title.Customer_Block_Charging': 'Customer Block Charging',
  'MCPPartnerProfile.label.Success_Count': 'Success Count:',
  'MCPPartnerProfile.label.Fail_Count': 'Fail Count:',
  'MCPPartnerProfile.form.label.Partner_Name': 'Partner Name',
  'MCPPartnerProfile.form.label.Partner_Name.placeholder': "Please input 'Partner Name'.",
  'MCPPartnerProfile.form.label.Partner_Code.': 'Partner Code',
  'MCPPartnerProfile.form.label.Partner_Code.placeholder': "Please input 'Partner Code'.",
  'MCPPartnerProfile.form.label.Partner_Status': 'Partner Status',
  'MCPPartnerProfile.form.label.Partner_Status.placeholder': "Please select 'Partner Status'.",
  'MCPPartnerProfile.form.label.Operation_Type': 'Operation Type',
  'MCPPartnerProfile.form.label.MSISDN': 'MSISDN',
  'MCPPartnerProfile.form.label.MSISDN.required': "Please your 'MSISDN'.",
  'MCPPartnerProfile.form.label.MSISDN.placeholder': 'Single Block',
  'MCPPartnerProfile.form.label.Batch_MSISDN': 'Batch MSISDN',
  'MCPPartnerProfile.form.label.Batch_MSISDN.required': "Please upload your 'Batch MSISDN'.",
  'MCPPartnerProfile.form.label.Partner_Operation': 'Partner Operation',
  'MCPPartnerProfile.title.Basic_Information': 'Basic Information',
  'MCPPartnerProfile.detail.label.Partner_Name': 'Partner Name',
  'MCPPartnerProfile.detail.label.Partner_Code': 'Partner Code',
  'MCPPartnerProfile.detail.label.Partner_Type': 'Partner Type',
  'MCPPartnerProfile.detail.label.Partner_Registration_No': 'Partner Registration No.',
  'MCPPartnerProfile.detail.label.Old_Registration_No': 'Old Registration No.',
  'MCPPartnerProfile.detail.label.Legal_Represenative': 'Legal Representative',
  'MCPPartnerProfile.detail.label.Registered_Address': 'Registered Address',
  'MCPPartnerProfile.detail.label.Contact_Information': 'Contact Information',
  'MCPPartnerProfile.detail.label.Contact_Name': 'Contact Name',
  'MCPPartnerProfile.detail.label.Email': 'Email',
  'MCPPartnerProfile.detail.label.Contact_Number': 'Contact Number',
  'MCPPartnerProfile.detail.label.Partner_Status': 'Partner Status',
  'MCPPartnerProfile.detail.label.Fax_Number': 'Fax Number',
  'MCPPartnerProfile.detail.label.Company_Type': 'Company Type',
  'MCPPartnerProfile.detail.label.ABN_VAT_GSTNumber': 'ABN/VAT/GSTNumber',
  'MCPPartnerProfile.detail.label.Deposit_Type': 'Deposit Type',
  'MCPPartnerProfile.detail.label.Registration_Date': 'Registration Date',
  'MCPPartnerProfile.detail.label.Correspondence_Address': 'Correspondence Address',
  'MCPPartnerProfile.form.label.Upload_Date_From': 'Upload Date From',
  'MCPPartnerProfile.form.label.Upload_Date_From.placeholder': "Please Select 'Upload Date From'",
  'MCPPartnerProfile.form.label.Upload_Date_To': 'Upload Date To',
  'MCPPartnerProfile.form.label.Upload_Date_To.placeholder': "Please Select 'Upload Date To'",
  'MCPPartnerProfile.form.label.Event_Type': 'Event Type',
  'MCPPartnerProfile.form.label.Event_Type.placeholder': "Please Select 'Event Type'",
  // Order Entry
  'OrderEntry.tips.endTime_startTime_check': 'The end time should be later than the start time.',
  'OrderEntry.tips.endTime_startTime_check2':
    "The interval between start time and end time can't be more than 6 months.",
  'OrderEntry.tips.title.cancelOrder': 'Are you sure to cancel the order?',
  'OrderEntry.tips.button.tips.cancelOrder': 'Cancel the order',
  'OrderEntry.form.label.Type': 'Type',
  'OrderEntry.form.label.Type.required': "'Type' is required.",
  'OrderEntry.form.label.Type.placeholder': 'Type',
  'OrderEntry.form.label.Order_Number': 'Order Number',
  'OrderEntry.form.label.Order_Number.placeholder': "Please input 'Order Number'.",
  'OrderEntry.form.label.Event_Type': 'Event Type',
  'OrderEntry.form.label.Event_Type.placeholder': "Please select 'Event Type'.",
  'OrderEntry.form.label.Event_Type.required': "Please select 'Event Type'.",
  'OrderEntry.form.label.Order_State': 'Order State',
  'OrderEntry.form.label.Order_State.placeholder': "Please select 'Order State'.",
  'OrderEntry.form.label.Create_Date_From': 'Create Date From',
  'OrderEntry.form.label.Create_Date_From.placeholder': "Please select 'Create Date From'.",
  'OrderEntry.form.label.Create_Date_From.required': 'Create date from is required.',
  'OrderEntry.form.label.Create_Date_To': 'Create Date To',
  'OrderEntry.form.label.Create_Date_To.placeholder': "Please select 'Create Date To'.",
  'OrderEntry.form.label.Create_Date_To.required': 'Create date to is required.',
  'OrderEntry.form.label.MO_URL.placeholder': "Please input 'MO URL'",
  'OrderEntry.form.label.DN_URL.placeholder': "Please input 'DN URL'",
  'OrderEntry.tips.submitted.successfully': 'The order is submitted successfully.',
  'OrderEntry.title.Approval_Information': 'Approval Information',
  'OrderEntry.form.label.Check_Result': 'Check Result',
  'OrderEntry.form.label.Proceed_Settlement': 'Proceed Settlement',
  'OrderEntry.form.label.Dispute': 'Dispute',
  'OrderEntry.form.label.Comments': 'Comments',
  'OrderEntry.form.label.Comments.required': "'Comments' is required.",
  'OrderEntry.title.Approve_History': 'Approve History',
  'OrderEntry.form.label.Approve_Result': 'Approve Result',
  'OrderEntry.title.Bank_Information': 'Bank Information',
  'OrderEntry.form.label.Bank_Country': 'Bank Country',
  'OrderEntry.form.label.Bank_Country.required': "'Bank Country' is required.",
  'OrderEntry.form.label.Bank_Name': 'Bank Name',
  'OrderEntry.form.label.Bank_Name.required': "'Bank Name' is required.",
  'OrderEntry.form.label.option.Other': 'Other',
  'OrderEntry.form.label.Bank_Name.verify': "'Bank Name' cannot be empty.",
  'OrderEntry.form.label.Bank_Key': 'Bank Key',
  'OrderEntry.form.label.SWIFT_Code': 'SWIFT Code',
  'OrderEntry.form.label.SWIFT_Code.required': "'SWIFT Code' is required.",
  'OrderEntry.form.label.SWIFT_Code.verify.OnlyNumbersLetters':
    'Only enter numbers and uppercase and lowercase letters.',
  'OrderEntry.form.label.Bank_Account_Number': 'Bank Account Number',
  'OrderEntry.form.label.Bank_Account_Number.required': "'Bank Account Number' is required.",
  'OrderEntry.form.label.Bank_Account_Number.verify': "'Bank Account Number' cannot be empty.",
  'OrderEntry.form.label.Bank_Account_Number.verify.Maximum': 'Maximum input of 19 characters.',
  'OrderEntry.form.label.Bank_Account_Number.verify.OnlyNumbers': 'Only numbers may be entered.',
  'OrderEntry.form.label.Bank_Account_Holder': 'Bank Account Holder',
  'OrderEntry.form.label.Bank_Account_Holder.required': "'Bank Account Holder' is required.",
  'OrderEntry.form.label.Bank_Account_Holder.verify': "'Bank Account Holder' cannot be empty.",
  'OrderEntry.form.label.IBAN': 'IBAN',
  'OrderEntry.form.label.IBAN.required': "'IBAN' is required.",
  'OrderEntry.form.label.IBAN.verify': "'IBAN' cannot be empty.",
  'OrderEntry.form.label.IBAN.verify.Maximum': 'Maximum input of 34 characters.',
  'OrderEntry.form.label.IBAN.verify.OnlyNumbersLetters':
    'Only enter numbers and uppercase and lowercase letters.',
  'OrderEntry.form.label.Attachment': 'Attachment',
  'OrderEntry.label.Download_Template': 'Download Template',
  'OrderEntry.label.Settlement_Type': 'Settlement Type',
  'OrderEntry.label.Account_Name': 'Account Name',
  'OrderEntry.label.Account_Number': 'Account Number',
  'OrderEntry.label.Settlement_Amount': 'Settlement Amount',
  'OrderEntry.label.Settlement_Currency': 'Settlement Currency',
  'OrderEntry.label.Payment_Amount': 'Payment Amount',
  'OrderEntry.label.Payment_Currency': 'Payment Currency',
  'OrderEntry.label.Payment_Type': 'Payment Type',
  'OrderEntry.label.Local_Amout': 'Local Amount',
  'OrderEntry.title.Deposit_Information': 'Deposit Information',
  'OrderEntry.label.Partner_Deposit_Type': 'Partner Deposit Type',
  'OrderEntry.label.Partner_Deposit_Amount': 'Partner Deposit Amount',
  'OrderEntry.label.Service_Deposit_Type': 'Service Deposit Type',
  'OrderEntry.label.Service_Deposit_Amount': 'Service Deposit Amount',
  'OrderEntry.label.Deposit_Total_Amount': 'Deposit Total Amount',
  'OrderEntry.label.Deposit_Proof': 'Deposit Proof',
  'OrderEntry.title.NDA_Template': 'NDA Template',
  'OrderEntry.label.NDA_Template': 'NDA Template',
  'OrderEntry.label.NDA_Template_Upload': 'NDA Template Upload',
  'OrderEntry.label.Order_Number': 'Order Number',
  'OrderEntry.label.Event_Type': 'Event Type',
  'OrderEntry.label.Order_State': 'Order State',
  'OrderEntry.label.Created_Date': 'Created Date',
  'OrderEntry.label.Contact_Channel': 'Contact Channel',
  'OrderEntry.label.Operator_Type': 'Operator Type',
  'OrderEntry.label.Operator': 'Operator',
  'OrderEntry.label.Process_No': 'Process No.',
  'OrderEntry.label.Comments': 'Comments',
  'OrderEntry.label.Reconciliation_Order_Number': 'Reconciliation Order Number',
  'OrderEntry.label.Is_ByPass': 'Is ByPass',
  'OrderEntry.label.Attachment': 'Attachment',
  'OrderEntry.label.Partner_Name': 'Partner Name',
  'OrderEntry.label.Partner_Registration_No': 'Partner Registration No.',
  'OrderEntry.label.ABN_VAT_GST_Number': 'ABN/VAT/GST Number',
  'OrderEntry.label.Partner_Code': 'Partner Code',
  'OrderEntry.label.Partner_Grade': 'Partner Grade',
  'OrderEntry.label.Partner_Type': 'Partner Type',
  'OrderEntry.label.Main_Contact_Name': 'Main Contact Name',
  'OrderEntry.label.Main_Contact_Number': 'Main Contact Number',
  'OrderEntry.label.Email': 'Email',
  'OrderEntry.label.Main_Contact_Designation': 'Main Contact Designation',
  'OrderEntry.label.Authorized_Signatory_Person': 'Authorized Signatory Person',
  'OrderEntry.label.Fax_Number': 'Fax Number',
  'OrderEntry.label.Registered_Address': 'Registered Address',
  'OrderEntry.label.Correspondence_Address': 'Correspondence Address',
  'OrderEntry.label.Company_Type': 'Company Type',
  'OrderEntry.label.Is_Local_Merchant': 'Is Local Merchant',
  'OrderEntry.title.Other_Contacts_Information': 'Other Contacts Information',
  'OrderEntry.label.Field_Name': 'Field Name',
  'OrderEntry.label.Old_Value': 'Old Value',
  'OrderEntry.label.New_Value': 'New Value',
  'OrderEntry.label.Service_Name': 'Service Name',
  'OrderEntry.label.Service_Code': 'Service Code',
  'OrderEntry.label.Service_Type': 'Service Type',
  'OrderEntry.label.Service_Catalog': 'Service Catalog',
  'OrderEntry.label.Service_Provider': 'Service Provider',
  'OrderEntry.label.Provision_Flag': 'Provision Flag',
  'OrderEntry.label.Bill_Type': 'Bill Type',
  'OrderEntry.label.option.General_Template': 'General Template (Single Invoice Reference)',
  'OrderEntry.label.option.Template_for_Multiple': 'Template for Multiple Reference Numbers',
  'OrderEntry.label.Bill_Import': 'Bill Import',
  'OrderEntry.label.Match_Amount': 'Match Amount',
  'OrderEntry.title.Order_Detail': 'Order Detail',
  'OrderEntry.title.Summary_Monthly_Result': 'Summary Monthly Result',
  'OrderEntry.title.Daily_Result': 'Daily Result',
  'OrderEntry.tab.title.Order_Information': 'Order Information',
  'OrderEntry.tab.title.Process_Information': 'Process Information',
  'OrderEntry.tab.title.Partner_Information': 'Partner Information',
  'OrderEntry.tab.title.Payment_Information': 'Payment Information',
  'OrderEntry.tab.title.Offer_Information': 'Offer Information',
  'OrderEntry.tab.title.Reconciliation_Information': 'Reconciliation Information',
  'OrderEntry.tab.title.Invoice_Information': 'Invoice Information',
  'OrderEntry.tab.title.Change_History': 'Change History',
  'OrderEntry.tab.title.Lock_Staff_History': 'Lock Staff History',
  'OrderEntry.tab.title.Partner_Service_Information': 'Partner Service Information',
  'OrderEntry.tab.title.Order_Attribute': 'Order Attribute',
  'OrderEntry.tab.title.Additional_Information': 'Additional Information',
  'OrderEntry.tab.title.Process_Operation': 'Process Operation',
  'OrderEntry.tab.title.Process_History': 'Process History',
  'OrderEntry.tab.title.Attachment': 'Attachment',
  'OrderEntry.title.Order_Information': 'Order Information',
  'OrderEntry.title.Bill_Item': 'Bill Item',
  'OrderEntry.title.ByPassTitle': 'ByPass Invoice Details',
  'OrderEntry.title.To_Draft_Agreement': 'To Draft Agreement',
  'OrderEntry.title.Agreement_Information': 'Agreement Information',
  'OrderEntry.title.Upload_Invoice_By_Pass': 'Upload Invoice By Pass',
  'OrderEntry.title.Upload_Invoice': 'Upload Invoice',
  'OrderEntry.label.Order_Date': 'Order Date',
  'OrderEntry.label.Create_Date': 'Create Date',
  'OrderEntry.label.Operator_Name': 'Operator Name',
  'OrderEntry.title.Partner_Information': 'Partner Information',
  'OrderEntry.label.ABN_VAT_Number': 'ABN/VAT Number',
  'OrderEntry.label.Customer_Care_Phone_Number': 'Customer Care Phone Number',
  'OrderEntry.label.Customer_Care_Email_Address': 'Customer Care Email Address',
  'OrderEntry.label.Technical_Support_Email_Address': 'Technical Support Email Address',
  'OrderEntry.label.Financial_Support_Email_Address': 'Financial Support Email Address',
  'OrderEntry.label.To_Modify_Partner_Suspension_Reason': 'To Modify Partner Suspension Reason',
  'OrderEntry.label.To_Modify_Partner_Termination_Reason': 'To Modify Partner Termination Reason',
  'OrderEntry.label.To_Modify_Partner_Resume_Reason': 'To Modify Partner Resume Reason',
  'OrderEntry.label.To_Modify_Service_Suspension_Reason': 'To Modify Service Suspension Reason',
  'OrderEntry.label.To_Modify_Service_Termination_Reason': 'To Modify Service Termination Reason',
  'OrderEntry.label.To_Modify_Service_Resume_Reason': 'To Modify Service Resume Reason',
  'OrderEntry.label.Termination_Information': 'Termination Information',
  'OrderEntry.label.Suspension_Information': 'Suspension Information',
  'OrderEntry.label.Resume_Information': 'Resume Information',
  'OrderEntry.label.Reason': 'Reason',
  'OrderEntry.label.Shortcode': 'Shortcode',
  'OrderEntry.label.moURL': 'MO URL',
  'OrderEntry.label.dnURL': 'DN URL',
  'OrderEntry.tips.delete.confirm': 'Are you sure you want to delete this item?',
  'OrderEntry.tips.check.uploadFileType': "The file should be type of '{uploadFileType}'.",
  'OrderEntry.tips.check.uploadFileSize':
    "The file '{name}' is exceed to maximum {sizeLimitation}MB.",
  'OrderEntry.tips.failToUploadAttachment': 'Fail to upload attachment, please try again.',
  'OrderEntry.title.Reconciliation_Information': 'Reconciliation Information',
  'OrderEntry.label.Reconciliation_Result': 'Reconciliation Result',
  'OrderEntry.tips.keyword.required': 'Please input keyword.',
  'OrderEntry.tips.keyword.available': 'The keyword is available.',
  'OrderEntry.tips.keyword.notAvailable': 'The keyword is not available.',
  'OrderEntry.title.Service_Information': 'Service Information',
  'OrderEntry.form.label.Shortcode': 'Shortcode',
  'OrderEntry.form.label.option.One_Time_Charge': 'One Time Charge',
  'OrderEntry.form.label.option.Subscription': 'Subscription',
  'OrderEntry.form.label.Keyword': 'Keyword',
  'OrderEntry.form.label.Keyword.required': 'Keyword is required.',
  'OrderEntry.form.label.Message_Content': 'Message Content',
  'OrderEntry.form.label.Message_Content.required': 'Message Content is required.',
  'OrderEntry.form.label.URL': 'URL',
  'OrderEntry.form.label.URL.required': 'URL is required.',
  'OrderEntry.form.label.Promotion_Banner': 'Promotion’s Banner',
  'OrderEntry.form.label.Promotion_Banner.required': "Please upload the promotion's banner",
  'OrderEntry.form.label.Service_Type': 'Service Type',
  'OrderEntry.form.label.Bill': 'Bill',
  'OrderEntry.button.add': 'Add',
  'OrderEntry.title.Keyword_List': 'Keyword List',
  'OrderEntry.tips.uplaodTesting': 'Please upload testing result.',
  'OrderEntry.title.To_Complete_Testing': 'To Complete Testing',
  'OrderEntry.title.To_Confirm_Launch_Date': 'To Confirm Launch Date',
  'OrderEntry.title.Launch_Date': 'Launch Date',
  'OrderEntry.title.To_MCP_User_Approval': 'To MCP User Approval',
  'OrderEntry.title.To_Upload_Deposit_Proof': 'To Upload Deposit Proof',
  'OrderEntry.title.To_Upload_New_Documents': 'To Upload New Documents',
  'OrderEntry.title.Partner_Information_Supplement': 'Partner Information Supplement',
  'OrderEntry.label.Testing_Result': 'Testing Result',
  'OrderEntry.label.Billing_Cycle': 'Billing Cycle',
  'OrderEntry.title.Testing_Result': 'Testing Result',
  'OrderEntry.title.To_Download_NDA': 'To Download NDA',
  'OrderEntry.title.NDA_Attachment': 'NDA Attachment',
  'OrderEntry.title.To_Reconciliation_Detail': 'To Reconciliation Detail',
  'OrderEntry.title.Reconciliation_Detail': 'Reconciliation Detail',
  'OrderEntry.tips.BankAccountNumberOrIBAN':
    'Please fill in Bank Account Number or IBAN for non-Malaysia Banks.',
  'OrderEntry.title.To_Complete_Bank_Information': 'To Complete Bank Information',
  'OrderEntry.tips.PleaseCheckYourFormField':
    'Please check your form field, Keyword List of CPA Service should have at least one keyword.',
  'OrderEntry.tips.readAndAgreeTerms':
    'Please indicate that you have read and agreed to the Terms and Conditions.',
  'OrderEntry.tips.makeSureAllCompleted':
    'Please complete required information in Keyword List and check keyword availability.',
  'OrderEntry.title.To_Complete_Service_Detail_By_Partner': 'To Complete Service Detail By Partner',
  'OrderEntry.label.agreeTo': 'I have read and agreed to the',
  'OrderEntry.label.termsAndConditions': 'Terms and Conditions',
  'OrderEntry.tips.uploadTestingResult': 'Please upload testing result.',
  'OrderEntry.tips.submittedSuccessfully': 'Submitted successfully.',
  'OrderEntry.tips.submissionFailed': 'Submission failed.',
  'OrderEntry.button.viewBillItem': 'View Bill Item',
  // Customer Consent
  'CustomerConsent.title.Customer_Consent': 'Customer Consent',
  'CustomerConsent.tips.successfully_sent_verification_code':
    'Successfully sent verification code.',
  'CustomerConsent.label.mobile_number': 'Mobile Number',
  'CustomerConsent.label.provier': 'Provider',
  'CustomerConsent.label.service': 'Service',
  'CustomerConsent.label.price': 'Price',
  'CustomerConsent.tips.your_mobile_number_successful_submission':
    'Your mobile number will be charged upon successful submission.',
  // Customer Subscription
  'CustomerSubscription.tips.endtime_later_than_start_time':
    'The end time should be later than the start time.',
  'CustomerSubscription.label.MSISDN': 'MSISDN',
  'CustomerSubscription.tips.MSISDN_required': 'MSISDN is required.',
  'CustomerSubscription.tips.MSISDN.limit': "'MSISDN' should be 12 digits or less.",
  'CustomerSubscription.label.status': 'Status',
  'CustomerSubscription.label.service_type': 'Service Type',
  'CustomerSubscription.label.offer': 'Offer',
  'CustomerSubscription.label.keyword': 'Keyword',
  'CustomerSubscription.label.subscription_from': 'Subscription From',
  'CustomerSubscription.label.subscription_to': 'Subscription To',
  'CustomerSubscription.label.partner': 'Partner',
  // Home
  'home.title.financial_services_partner': 'Financial Services Partner',
  'home.title.consumer_services_partner': 'Consumer Services Partner',
  'home.title.enterprise_partner': 'Enterprise Partner',
  'home.subtitle.financial_services_partner':
    'Grow your financial service business together with U Mobile because we have a full-fledged financial ecosystemto meet your business needs.',
  'home.subtitle.consumer_services_partner':
    'Supplement your business to consumers with our suite of services, including Direct Carrier Billing, CPA services and Bulk SMS.',
  'home.subtitle.enterprise_partner':
    ' Partner with U Mobile in growing your business. At U Mobile, we support businesses, small to big.',
  // Transaction
  'Transaction.tips.refund_success': 'Refund charge successfully.',
  'Transaction.tips.refund_fail': 'Refund charge failed.',
  'Transaction.tips.refund_attempt_fail': 'Refund attempt failed.',
  'Transaction.tips.refund_fail_reason': 'Reason: Customer not eligible to receive refund.',
  'Transaction.label.transaction_start_time': 'Transaction Start Time',
  'Transaction.label.transaction_end_time': 'Transaction End Time',
  'Transaction.label.msisdn': 'MSISDN',
  'Transaction.label.msisdn.limit': "'MSISDN' should be 12 digits or less.",
  'Transaction.label.service_name': 'Service Name',
  'Transaction.label.state': 'State',
  'Transaction.label.service_type': 'Service Type',
  'Transaction.label.transaction_type': 'Transaction Type',
  'Transaction.label.partner_name': 'Partner Name',
  'Transaction.refund_detail.label.refund_amount': 'Refund Amount',
  'Transaction.refund_detail.label.refund_reason': 'Refund Reason',
  'Transaction.detail.label.prm_transaction_id': 'PRM Transaction ID',
  'Transaction.detail.label.partner_transaction_id': 'Partner Transction ID',
  'Transaction.detail.label.service_type': 'Service Type',
  'Transaction.detail.label.transaction_type': 'Transaction Type',
  'Transaction.detail.label.transaction_time': 'Transaction Time',
  'Transaction.detail.label.msisdn': 'MSISDN',
  'Transaction.detail.label.partner_name': 'Partner Name',
  'Transaction.detail.label.offer_name': 'Offer Name',
  'Transaction.detail.label.amount': 'Amount',
  'Transaction.detail.label.tax': 'Tax',
  'Transaction.detail.label.refund_charge': 'Refund Charge',
  'Transaction.detail.label.authentication': 'Authentication',
  'Transaction.detail.label.channel': 'Channel',
  'Transaction.detail.label.state': 'State',
  'Transaction.detail.label.result': 'Result',
  'Transaction.detail.label.cutomerRequestChannel': 'Customer Request Channel',
  'Transaction.detail.label.refund_reason': 'Refund Reason',
  'Transaction.detail.label.original_transaction_id': 'Original Transaction ID',
  // Settlement
  'Settlement.tips.fail_find_billing_period': 'Failed to find the billing period.',
  'Settlement.label.cycle_begin_date': 'Cycle Begin Time',
  'Settlement.label.cycle_end_time': 'Cycle End Time',
  'Settlement.label.partner_account': 'Partner Account',
  // Services
  'Services.banner.capabilities_for_you': 'Be a U Mobile Partner',
  'Services.banner.description_1':
    'Different partnership options available to suit your business needs.',
  'Services.banner.description_2': 'Join us today!',
  'Serivces.title.financial_services_partner': 'Financial Services Partner',
  'Serivces.title.consumer_services_partner': 'Consumer Services Partner',
  'Serivces.title.enterprise_partner': 'Enterprise Partner',
  'Services.subtitle.digital_financial_ecosystem': ' Digital Financial Ecosystem',
  'Services.subtitle.digital_enterprise_services': ' Digital Enterprise Services',
  'Services.subtitle.digital_consumer_services': ' Digital Consumer Services',
  'Services.desc.we_provide...':
    'We provide financial solutions from products to collection, settlement and reconciliation to help your business achieve financial returns.',
  'Services.desc.wish_to...':
    'Wish to leverage on our strength to grow your business? Diversities of businesses not limited to connectivity, solutions, gadgets, licenses etc. are welcomed.',
  'Services.desc.stay_ahead...':
    'Stay ahead in the crowded market. We offer a suite of services including Direct Carrier Billing, CPA services and Bulk SMS to help supplement your business to consumers.',
  // Partner Services
  'Partner_Services.tips.choose_one...': 'Choose one service to manipulate each time.',
  'Partner_Services.tips.choose_at_least_one...':
    'Choose at least one service to manipulate each time.',
  'Partner_Services.label.service_name': 'Service Name',
  'Partner_Services.label.service_code': 'Service Code',
  'Partner_Services.label.service_status': 'Service Status',
  'Partner_Services.label.partner_name': 'Partner Name',
  'Partner_Services.label.keyword': 'Keyword',
  'Partner_Services.label.service_type': 'Service Type',
  'Partner_Services.button.register': '+ Register',
  'Partner_Services.block_result.success_count': 'Success Count: ',
  'Partner_Services.block_result.fail_count': 'Fail Count: ',
  'Partner_Services.block_result.view_detail_result': 'View Detail Result:',
  'Partner_Services.block_result.processing...': 'Processing your request... ',
  // Partner Services - Customer Block
  'Customer_Block.tips.block_succesccfully': 'Block charging successfully',
  'Customer_Block.tips.unknown_error': 'Unkown error, please check the network',
  'Customer_Block.tips.file_analyze_fail':
    'For some reason, the file analyze has failed! Please try again later.',
  'Customer_Block.title': 'Customer Block Charging',
  'Customer_Block.label.batch_msisdn': 'Batch MSISDN',
  'Customer_Block.label.file_template': 'File Template',
  // Partner Services - Labels

  'Service_Detail.label.service_name': 'Service Name',
  'Service_Detail.label.service_type': 'Service Type',
  'Service_Detail.label.offer_name': 'Offer Name',
  'Service_Detail.label.offer_code': 'Offer Code',
  'Service_Detail.label.keyword': 'Keyword',
  'Service_Detail.label.promotions_banner': 'Promotion Banner',
  'Service_Detail.label.event_type': 'Event Type',
  'Service_Detail.label.charge_mode': 'Charge Mode',
  'Service_Detail.label.price': 'Price',
  'Service_Detail.label.service_category': 'Service Category',
  'Service_Detail.label.subscription_fee': 'Subscription Fee',
  'Service_Detail.label.subscription_frequency': 'Subscription Frequency',
  'Service_Detail.label.service_description': 'Service Description',
  'Service_Detail.label.content_fee': 'Content Fee',
  'Service_Detail.label.content_frequency': 'Content Frequency',
  'Service_Detail.label.content_frequency_cycle': 'Content Frequency Cycle',
  'Service_Detail.label.message_content': 'Message Content',
  'Service_Detail.label.url': 'URL',
  'Service_Detail.label.renewal_day': 'Renewal Day',
  'Service_Detail.label.aggregator_flag': 'Aggregator Flag',
  'Service_Detail.label.customer_email': 'Customer Care Email Address',
  'Service_Detail.label.customer_phone': 'Customer Care Phone Number & Operation Hour',
  'Service_Detail.label.asynchronous_url': 'Asynchronous Notification URL',
  'Service_Detail.label.integration_type': 'Integration type',
  'Service_Detail.label.3g_recognition_is_allowed': '3G Recognition Is Allowed',
  'Service_Detail.label.delegated_subscriptions': 'Delegated subscriptions',
  'Service_Detail.label.delegated_full_trusted': 'Delegated (Full Trusted)',
  'Service_Detail.label.no_trusted': 'Not trusted (No token, PIN in purchase)',
  'Service_Detail.label.persistent_token': 'Not Trusted Persistent Token (Pin In Identify)',
  'Service_Detail.label.pin_in_3g_requested': 'PIN in 3G requested',
  'Service_Detail.label.masked_msisdn': 'Masked MSISDN',
  'Service_Detail.label.payment_pages_are_customizable': 'Payment Pages Are Customizable',
  'Service_Detail.label.thank_you_page_shown': 'Thank You Page Shown',
  'Service_Detail.label.refund_via_api_is_allowed': 'Refund via API is allowed',
  'Service_Detail.label.expected_lauch_date': 'Expected launch date',
  'Service_Detail.label.estimated_monthly_traffic': 'Estimated Monthly Traffic',
  'Service_Detail.label.estimated_daily_distribution': 'Estimated daily distribution',
  'Service_Detail.label.peak_daily_traffic': 'Peak daily traffic',
  'Service_Detail.label.service_temrs_conditions_url': 'Service Terms& Conditions URL',
  'Service_Detail.label.nda_template': 'NDA Template',
  'Service_Detail.title.partner_service_detail': 'Partner Service Detail',
  // Service Register
  'Service_Register.button.add': '+ Add',
  'Service_Register.title.service_register': 'Service Register',
  'Service_Register.subtitle.keyword_list': 'Keyword List',
  'Service_Register.subtitle.service_information': 'Serivce Information',
  'Service_Register.label.classification': 'Classification',
  'Service_Register.label.shortcode': 'Shortcode',
  'Service_Register.label.moURL': 'MO URL',
  'Service_Register.label.dnURL': 'DN URL',
  'Service_Register.label.service_name': 'Service Name',
  'Service_Register.label.event_type': 'Event Type',
  'Service_Register.label.nda_template': 'NDA Template',
  'Service_Register.label.service_type': 'Service Type',
  'Service_Register.label.service_type_required': "Please select 'Service Type'.",
  'Service_Register.label.comments': 'Comments',
  'Service_Register.label.attachment': 'Attachment',
  'Service_Regsiter.label.promotions_banner': 'Promotion’s Banner',
  'Service_Regsiter.label.promotions_banner_required': "Please upload the promotion's banner",
  'Service_Register.label.required': '{title} is required',
  'Serivce_Register.tips.file_size_limitation':
    "The file '{fileName}' is exceed to maximum {sizeLimitation}MB.",
  'Serivce_Register.tips.file_type_limitation': "The file should be type of '{uploadFileType}'.",
  'Service_Register.tips.add_at_least_one_form': 'Please add at least one registration form.',
  'Service_Register.tips.add_at_least_one_keyword':
    'Please check your form field, Keyword List of CPA Service should have at least one keyword.',
  'Service_Register.tips.make_sure_form_completed':
    'Please complete required information in Keyword List and check keyword availability.',
  'Service_Register.tips.agree_terms_conditions':
    'Please indicate that you have read and agreed to the Terms and Conditions.',
  'Service_Register.tips.service_submitted_success':
    'Service registration has been submitted for review. ',
  'Service_Register.tips.service_submitted_failed':
    'Service registration below was failed to submit.',
  'Service_Register.tips.the_service_of_main...':
    'The service of main platform type has not been created yet, please try another service type',
  'Service_Register.text.i_have_read...': 'I have read and agreed to the',
  'Service_Register.text.terms_and_conditions': 'Terms and Conditions',
  'Service_Register.tips.repetitive_keyword_existed': 'Repetitive keyword existed!',
  'Service_Register.tips.keyword_available': 'Keyword is available.',
  'Service_Register.tips.keyword_not_available': 'The keyword is not available!',
  'Service_Register.tips.sure_to_delete': 'Are you sure you want to delete this item?',
  'Service_Register.tips.wait_for_uploading': 'Please wait for uploading!',
  // Own Partner Profile
  'Own_Partner_Profile.title.partner_profile': 'Partner Profile',
  'Own_Partner_Profile.tab_title.basic_info': 'Basic Information',
  'Own_Partner_Profile.tab_title.account_info': 'Account Information',
  'Own_Partner_Profile.tab_title.staff_info': 'Staff Information',
  'Own_Partner_Profile.modal_title.attachment_upload': 'Attachment Upload',
  'Own_Partner_Profile.subtitle.bank_info': 'Bank Information',
  'Own_Partner_Profile.subtitle.partner_modify_profile': 'Partner Modify Profile',
  'Own_Partner_Profile.subtitle.attachment': 'Attachment',
  'Own_Partner_Profile.subtitle.other_contacts_information': 'Other Contacts Information',
  'Own_Partner_Profile.button.add': '+ Add',
  'Own_Partner_Profile.tips.pending_order':
    'Related orders already exist, please check. Order number is {orderNbr}.',
  'Own_Partner_Profile.tips.the_order_is_not_completed...':
    "The order {value} is not completed, can't process this operation.",
  'Own_Partner_Profile.tips.the_number_of_banks...':
    'The number of banks added to each account exceeds the maximum.',
  'Own_Partner_Profile.tips.add_bank_account_success':
    'Bank modification request has been submitted for review.',
  'Own_Partner_Profile.tips.add_bank_account_fail': 'Fail to add bank account.',
  'Own_Partner_Profile.tips.mod_bank_account_success':
    'Bank modification request has been submitted for review.',
  'Own_Partner_Profile.tips.mod_bank_account_fail': 'Fail to modify bank account information.',
  'Own_Partner_Profile.tips.add_account_succuess':
    'Add account successfully, please wait for review',
  'Own_Partner_Profile.tips.add_account_fail': 'Add account failed.',
  'Own_Partner_Profile.tips.mod_account_success':
    'Modify account successfully, please wait for review.',
  'Own_Partner_Profile.tips.mod_account_fail': 'Modify account failed.',
  'Own_Partner_Profile.tips.del_account_success': 'Delete account successfully',
  'Own_Partner_Profile.tips.add_partner_attachment_success': 'Your file is uploaded.',
  'Own_Partner_Profile.tips.add_partner_attachment_fail': 'Add partner attachment failed.',
  'Own_Partner_Profile.tips.order_number': 'Order Number: {orderNbr}',
  'Own_Partner_Profile.tips.please_fill_in...':
    'Please fill in Bank Account Number or IBAN for non-Malaysia Banks.',
  'Own_Partner_Profile.tips.delete_attachment_success': 'Succeed in deleting attachment.',
  'Own_Partner_Profile.tips.delete_attachment_fail': 'Fail to delete attachment.',
  'Own_Partner_Profile.tips.partner_unavailable': 'This partner is unavailable',
  'Own_Partner_Profile.tips.modification_submit_success':
    'Profile modification request has been submitted for review.',
  'Own_Partner_Profile.tips.modification_submit_fail': 'Fail to modify partner basic inforamtion.',
  'Own_Partner_Profile.tips.add_contact_man_success':
    'Contact modification request has been submitted for review.',
  'Own_Partner_Profile.tips.add_contact_man_fail': 'Fail to add contact person.',
  'Own_Partner_Profile.tips.del_contact_man_succeed':
    'Contact modification request has been submitted for review.',
  'Own_Partner_Profile.tips.mod_contact_man_success':
    'Contact modification request has been submitted for review.',
  'Own_Partner_Profile.tips.mod_contact_man_fail': 'Fail to modify contact person.',
  'Own_Partner_Profile.tips.del_contact_man_fail': 'Fail to delete contact person.',
  'Own_Partner_Profile.tips.add_staff_success':
    'Staff modification request has been submitted for review.',
  'Own_Partner_Profile.tips.add_staff_fail': 'Fail to add staff.',
  'Own_Partner_Profile.tips.mod_staff_success':
    'Staff modification request has been submitted for review.',
  'Own_Partner_Profile.tips.mod_staff_fail': 'Fail to modify staff.',
  'Own_Partner_Profile.tips.del_staff_success':
    'Staff modification request has been submitted for review.',
  'Own_Partner_Profile.tips.del_staff_fail': 'Fail to delete staff.',
  'Own_Partner_Profile.label.bank_country': 'Bank Country',
  'Own_Partner_Profile.label.bank_country_required': "'Bank Country' is required.",
  'Own_Partner_Profile.label.bank_name': 'Bank Name',
  'Own_Partner_Profile.label.bank_name_required': "'Bank Name' is required.",
  'Own_Partner_Profile.label.bank_key': 'Bank Key',
  'Own_Partner_Profile.label.swift_code': 'SWIFT Code',
  'Own_Partner_Profile.label.swift_code_required': "'SWIFT Code' is required.",
  'Own_Partner_Profile.label.bank_account_number': 'Bank Account Number',
  'Own_Partner_Profile.label.bank_account_number_required': "'Bank Account Number' is required.",
  'Own_Partner_Profile.label.bank_account_holder': 'Bank Account Holder',
  'Own_Partner_Profile.label.bank_account_holder_required': "'Bank Account Holder' is required.",
  'Own_Partner_Profile.label.bank_account_holder_empty': "'Bank Account Holder' cannot be empty.",
  'Own_Partner_Profile.label.iban': 'IBAN',
  'Own_Partner_Profile.label.account_number': 'Account Number',
  'Own_Partner_Profile.label.account_name': 'Account Name',
  'Own_Partner_Profile.label.account_type': 'Account Type',
  'Own_Partner_Profile.label.billing_cycle_type': 'Billing Cycle Type',
  'Own_Partner_Profile.label.currency_type': 'Currency Type',
  'Own_Partner_Profile.label.payment_term': 'Payment Term',
  'Own_Partner_Profile.label.event_type': 'Event Type',
  'Own_Partner_Profile.label.event_type_required': 'Event Type is required.',
  'Own_Partner_Profile.label.upload_date_from': 'Upload Date From',
  'Own_Partner_Profile.label.upload_date_to': 'Upload Date To',
  'Own_Partner_Profile.label.partner_name': 'Partner Name',
  'Own_Partner_Profile.label.partner_code': 'Partner Code',
  'Own_Partner_Profile.label.partner_type': 'Partner Type',
  'Own_Partner_Profile.label.partner_registration_no': 'Partner Registration No.',
  'Own_Partner_Profile.label.partner_registration_no_required':
    "Please input 'Partner Registration No.",
  'Own_Partner_Profile.label.old_registration_no': 'Old Registration No.',
  'Own_Partner_Profile.label.abn_vat_gst_number': 'ABN/VAT/GST Number',
  'Own_Partner_Profile.label.main_contact_name': 'Main Contact Name',
  'Own_Partner_Profile.label.main_contact_name_required': "Please input 'Main Contact Name'.",
  'Own_Partner_Profile.label.main_contact_designation': 'Main Contact Designation',
  'Own_Partner_Profile.label.main_contact_number': 'Main Contact Number',
  'Own_Partner_Profile.label.main_contact_number_required': "Please input 'Main Contact Number'.",
  'Own_Partner_Profile.label.country_code_required': "Please input 'Country Code'.",
  'Own_Partner_Profile.label.email': 'Email',
  'Own_Partner_Profile.label.email_required': "Please input 'Email'.",
  'Own_Partner_Profile.label.registered_address': 'Registered Address',
  'Own_Partner_Profile.label.registered_address_required': "Please input 'Registered Address'.",
  'Own_Partner_Profile.label.partner_status': 'Partner Status',
  'Own_Partner_Profile.label.registration_date': 'Registration Date',
  'Own_Partner_Profile.label.campany_type': 'Company Type',
  'Own_Partner_Profile.label.campany_type_required': "Please input 'Company Type'.",
  'Own_Partner_Profile.label.fax_number': 'Fax Number',
  'Own_Partner_Profile.label.deposit_type': 'Deposit Type',
  'Own_Partner_Profile.label.correspondence_address': 'Correspondence Address',
  'Own_Partner_Profile.label.Tax_Identification_Number': 'Tax Identification Number',
  'Own_Partner_Profile.label.SST_Registration_Number': 'SST Registration Number',
  'Own_Partner_Profile.label.Tourism_Tax_Registration_Number': 'Tourism Tax Registration Number',
  'Own_Partner_Profile.label.Malaysia_Standard_Industrial_Classification_Code': 'MSIC Code',
  'Own_Partner_Profile.label.Business_Activity_Description': 'Business Activity Description',
  'Own_Partner_Profile.tooltip_title.director...':
    'Director Name with authorized signatory for legal documents.',
  'Own_Partner_Profile.tooltip_text.authorized...': 'Authorized Signatory Person',
  'Own_Partner_Profile.tooltip_title.kindly...':
    'Kindly provide other contacts such as Customer Care, Finance and Technical Support Contact Details',
  'Own_Partner_Profile.tooltip_title.ManageStaff...':
    'Manage Staff Login to grant access to Partner SelfCare Portal.',
  // Onboarding Process
  'Onboarding_Process.title.be_our_partner': 'Digitized Experience',
  'Onboarding_Process.desc.we_can_help_you...': 'Register your interest online and our partner',
  'Onboarding_Process.help2':
    'management ecosystem will guide you through the process from the get-go.',
  'Onboarding_Process.title.enterprise_partner': 'Enterprise Partner',
  'Onboarding_Process.step_name.registration': 'Registration',
  'Onboarding_Process.step_name.approval': 'Approval',
  'Onboarding_Process.step_name.contract_signing': 'Contract Signing',
  'Onboarding_Process.step_name.configuration': 'Configuration',
  'Onboarding_Process.step': 'Step {number}',
  // Customer Block History Query
  'Customer_Block_History_Query.label.Start_Time': 'Start Time',
  'Customer_Block_History_Query.label.End_Time': 'End Time',
  'Customer_Block_History_Query.label.Partner_Name': 'Partner Name',
  'Customer_Block_History_Query.label.Service_Type': 'Service Type',
  'Customer_Block_History_Query.label.Offer': 'Offer',
  'Customer_Block_History_Query.label.Keyword': 'Keyword',
  'Inquiry_Tabs_Page.title.Transaction': 'Transaction Query',
  'Inquiry_Tabs_Page.title.Customer': 'Customer Subscription',
  'ByPass_Invoice.Ntr': 'Invoice Number',
  'ByPass_Billing.Cycle': 'Billing Cycle',
  'ByPass_Partner.Name': 'Partner Name',

  'ByPass_Partner.Code': 'Partner Code',
  'ByPass_Issue.Date': 'Issue Date',
  'ByPass_Due.Name': 'Due Date',
  'ByPass_Without.Tax': 'Amount',
  'ByPass_Tax.Code': 'Tax Code',
  'ByPass_With.Tax': 'Tax Amount',
  'ByPass_Partner.Remarks': 'Remarks',
  'ByPass_Partner.Currency': 'Currency',
  'ByPass_Partner.BaseAmout': 'Withholding Tax Base Amount',
  'ByPass_Partner.TaxType': 'Tax Type',
  'ByPass_Partner.TaxAmount': 'Tax Amount',
  'ByPass_Partner.Description': 'Product Code Description',
  'ByPass_Partner.TotalAmount': 'Total Amount',
  'ByPass_Partner.TotalTaxAmount': 'Total Tax Amount',
  'ByPass_Partner.View': 'View Invoice Details',
};
