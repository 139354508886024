import { stringify } from 'qs';
import request from '@/utils/request';
import { PRC_HEADER_API } from '@/ecareDefaultSettings';

// 查询合作伙伴及其子伙伴的Offer列表
export async function qryTransData(params) {
  return request(`${PRC_HEADER_API}/prcTransData/qryTransData`, {
    method: 'POST',
    body: params,
  });
}

// 查询合作伙伴及其子伙伴的Offer列表
export async function getOrderStates() {
  return request(`${PRC_HEADER_API}/profile/orderStates`, {
    method: 'GET',
  });
}

// 查询合作伙伴及其子伙伴的Offer列表
export async function getSubtypeList() {
  return request(`${PRC_HEADER_API}/getSubtypeList`, {
    method: 'GET',
  });
}

// 导出合作伙伴及其子伙伴的Offer列表
export async function refunt(data) {
  return request(`${PRC_HEADER_API}/prcTransData/refund`, {
    method: 'POST',
    body: data,
  });
}

// 导出合作伙伴及其子伙伴的Offer列表
export async function transDataExport(data) {
  return request(`${PRC_HEADER_API}/prcTransData/transDataExport`, {
    method: 'POST',
    body: data,
  });
}

// 导出合作伙伴及其子伙伴的Offer列表：获取名字
export async function exportOfferList(data) {
  return request(`${PRC_HEADER_API}/exportOfferList`, {
    method: 'POST',
    body: data,
  });
}

// 导出合作伙伴及其子伙伴的Offer列表：下载
export async function downloadOfferList(data) {
  return request(`${PRC_HEADER_API}/down/${data.fileName}`, {
    getResponse: true,
    responseType: 'blob',
  });
}

export async function getConfigItemParam(data) {
  return request(`${PRC_HEADER_API}/prcConfigItemParam/${data.configCode}`, {
    method: 'GET',
  });
}

export function getEventType(params) {
  return request(`${PRC_HEADER_API}/domains?${stringify(params)}`);
}

// 获取权限
export function getAuthority(params) {
  return request(`${PRC_HEADER_API}/userPrivList`, {
    method: 'POST',
    body: params.dataPrivCodes,
  });
}
