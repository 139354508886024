import { searchCustomer, fetchFavorites } from '@/services/api';

export default {
  namespace: 'customer',

  state: {
    customers: [],
    favorites: [],
  },

  effects: {
    *searchCustomer({ payload }, { call, put }) {
      const response = yield call(searchCustomer, payload);
      if (!response) return;
      const {
        data: { result },
      } = response;
      if (response.success === true) {
        yield put({
          type: 'updateCustomer',
          payload: result,
        });
      }
    },
    *fetchFavorites({ payload }, { call, put }) {
      const response = yield call(fetchFavorites, payload);
      if (!response) return;
      const {
        data: { result },
      } = response;
      if (response.success === true) {
        yield put({
          type: 'updateFavorites',
          payload: result,
        });
      }
    },
  },

  reducers: {
    updateCustomer(state, action) {
      return {
        ...state,
        customers: action.payload,
      };
    },
    updateFavorites(state, action) {
      return {
        ...state,
        favorites: action.payload,
      };
    },
  },
};
