import { PRC_HEADER_API } from '@/ecareDefaultSettings';
import request from '@/utils/request';

export function getOrderStates() {
  return request(`${PRC_HEADER_API}/qryOfferInstStateList`);
}

export function getCustomerSubscriptionData(data) {
  return request(`${PRC_HEADER_API}/qryOfferInstByConditions`, {
    method: 'POST',
    body: data,
  });
}

export function getSubtypeList() {
  return request(`${PRC_HEADER_API}/getSubtypeList`, {
    method: 'GET',
  });
}

export function customerSubscriptionExport(data) {
  return request(`${PRC_HEADER_API}/offerInstDataExport`, {
    method: 'POST',
    body: data,
  });
}

export async function downloadOfferList(data) {
  return request(`${PRC_HEADER_API}/down/${data.fileName}`, {
    getResponse: true,
    responseType: 'blob',
  });
}
