/* eslint-disable indent */
import { routerRedux } from 'dva/router';
import { stringify } from 'qs';
import { formatMessage } from 'umi/locale';
import { get, isEmpty } from 'lodash';
import { message } from '@whalecloud/fdx';
import {
  logged,
  queryLocal,
  queryLangs,
  current,
  queryUserPortals,
  querySysparams,
  queryAdmin,
  getContactInfo,
  queryCurrent,
  getInterval,
  getRemainingTime,
  getNoticeTime,
  addComponentPrivilege,
  checkComponentPrivilege,
  getMenuComponentPrivs,
  delComponentPrivs,
  recoverSession,
  getAsyncConfig,
  getMagicCode,
  qryCurrentSecurityRule,
  queryUserRoles,
  getPartnerType,
  modServiceState,
  modPartnerState,
  qryAddrAttrValueList,
  qryPostAddrList,
  prcConfigItemParam,
  checkOngoingOrder4LifeCycle,
  prcPartnerProfile,
  prcPartnerType,
  getUploadFileSize,
  getUploadFileType,
  getUploadFileSizePre,
  getUploadFileTypePre,
  serviceLifeCyclePreCheck4UM,
  qryMcpPartner,
  modSingleServiceState,
  getPrcConfigParam,
  getStaffJob,
  getCurrentStaff,
  getCurrentPosition,
} from '@/services/api';
import { ADDRESS_UM_INFO, SELECT_TYPE_INFO, UPLOAD_FILE_LIMITATION } from '@/constants';
import loginSession from '@/utils/loginSession';
import { getWebRoot, transformFormat, collapseMenu, openMenu, setLang } from '@/utils/utils';

const loadjs = require('loadjs');

export default {
  namespace: 'global',

  state: {
    collapsed:
      loginSession.getData('collapsed') === null ? false : loginSession.getData('collapsed'),
    currentUser: {},
    languages: [],
    currentUserRole: [],
    contactInfo: {
      address: '',
      email: '',
    },
    menuComponentPrivs: [],
    minWidthState: true,
    sysparams: [], // 系统参数
    securityRule: {}, // 安全规则
    pageShow: 'block', // 控制菜单界面的展示还是隐藏
    defaultUrl: '/',
    asyncComponent: {},
    auditLogFilterKeys: [],
    auditLogFilterValues: [],
    partnerEventTypeList: [],
    partnerCompanyTypeList: [],
    partnerStateList: [],
    partnerContactTypeList: [],
    prcAttrCountryList: [],
    prcAttrStateList: [],
    prcAttrCityList: [],
    prcPostCodeList: [],
    partnerStandardAddress: {},
    correspondenceAddress: {},
    configItemParam: [],
    partnerProfile: {},
    partnerTypeList: [],
    uploadFileSize: 0,
    uploadFileType: '',
    KBSUrl: '',
    KBSSearchAvailable: '',
    savedstaffJobs: [], // 保存 staff
  },

  effects: {
    *fetchLocal(_, { call, put }) {
      const { success, data } = yield call(queryLocal);
      if (success) {
        const { language = '', auditLogEventCode = '', auditLogEventCodeRes } = data;
        const key = language === 'zh' ? 'zh-CN' : 'en-US';
        setLang(key);
        window.g_lang = key;
        if (auditLogEventCode !== '') {
          const auditLogFilterKeys = auditLogEventCode.split('|');
          const auditLogFilterValues = auditLogEventCodeRes.split('|');
          yield put({
            type: 'updateState',
            payload: {
              auditLogFilterKeys,
              auditLogFilterValues,
            },
          });
        }
      }
    },
    *fetchLangs(_, { call, put }) {
      const { success, data } = yield call(queryLangs);
      if (success) {
        const { languageList } = data;
        yield put({
          type: 'updateState',
          payload: {
            languages: languageList,
          },
        });
      }
    },
    *setCurrentMenu(_, { call }) {
      yield call(current, { menuId: -2 });
      // yield put({
      //   type: 'fetchCurrentAdmin',
      // });
    },
    *fetchCurrentPortal({ payload }, { call }) {
      yield call(queryUserPortals, payload);
    },
    *fetchSys(_, { call, put }) {
      const { success, data } = yield call(querySysparams);
      if (success) {
        const sysparams = {
          timeFormat: transformFormat(data[4]),
          dateFormat: transformFormat(data[3]),
          dataTimeFormat: transformFormat(data[2]),
        };
        yield put({
          type: 'updateState',
          payload: {
            sysparams,
          },
        });
        loginSession.saveData('sysparams', sysparams);
      }
    },
    *fetchAdmin(_, { call }) {
      yield call(queryAdmin);
    },
    *fetchCurrentAdmin(_, { call, put, select }) {
      const { success, data } = yield call(queryCurrent);
      if (success) {
        // yield put({
        //   type: 'fetchCurrentPortal',
        //   payload: {
        //     ...data,
        //   },
        // });
        const { currentUser } = yield select(state => state.global);
        const newCurrent = {
          ...currentUser,
          ...data,
        };
        loginSession.saveUser(newCurrent);
        yield put({
          type: 'updateState',
          payload: {
            currentUser: newCurrent,
          },
        });
      }
    },
    *logged({ payload = {} }, { call, put }) {
      const { success, data } = yield call(logged);
      if (success) {
        if (!window.portal) window.portal = {};
        const urlParams = new URL(window.location.href);
        const { hash } = urlParams;
        const { _csrf, portalId } = data;
        if (_csrf) {
          const { headerName, token } = _csrf;
          loginSession.saveData(headerName, token);
          loginSession.saveUser(data);
          yield put({
            type: 'updateState',
            payload: {
              currentUser: {
                ...data,
                password: payload.password,
              },
            },
          });
          // 抛出全局对象portal：登录状态logonStatus/获取菜单路由方法collapseMenu/打开菜单方法openMenu
          window.portal.portalId = portalId;
          window.portal.collapseMenu = collapseMenu;
          window.portal.openMenu = openMenu;
          // window.portal.logonStatus = true;
          window.portal.defaultUrl = '/home';

          if (!window.AppGlobal) window.AppGlobal = {};
          window.AppGlobal.userId = data.userId;
          window.AppGlobal.userName = data.userName;
          window.AppGlobal.webroot = getWebRoot();
          window.AppGlobal.version = '0.0.3';
          const taskArr = [
            put({
              type: 'user/qryWorkerOrder',
            }),
            put({ type: 'fetchSecurityRule' }),
            put({ type: 'fetchAsyncConfig' }),
            put({ type: 'setCurrentMenu' }),
            put({ type: 'fetchSys' }),
            put({
              type: 'fetchCurrentUserRole',
              payload: data.userId,
            }),
            put({ type: 'user/fetchPortals' }),
            put({ type: 'prcPartnerProfile' }),
          ];
          yield taskArr;
          yield call(getCurrentStaff, token);
          const { data: array } = yield call(getStaffJob);
          if (!isEmpty(array)) {
            yield put({
              type: 'setStaffJobs',
              payload: array,
            });
            const { data } = yield call(getCurrentPosition, {
              staffJobId: array[0].staffJobId,
            });
          }
          yield put({
            type: 'user/fetchMenu',
            payload: {
              ...payload,
            },
          });
          // yield call(getCurrentPosition, 11);
        } else if (hash.indexOf('/terms') !== -1) {
          // nothing to do
        } else if (hash.indexOf('/login') <= -1) {
          // yield put({
          //   type: 'login/logout',
          // });
          // 重定向到user/login
          yield put(
            routerRedux.push({
              // pathname: '/userLogin',
              pathname: '/login',
              search:
                hash !== '#/'
                  ? stringify({
                      redirect: hash.indexOf('Own') > -1 ? `${hash.split('Own')[0]}Search` : hash,
                    })
                  : '',
            }),
          );
        }
      } else {
        // 销户时退出登录
        yield put({
          type: 'login/logout',
        });
      }
    },
    *getContactInfo(_, { call, put }) {
      const { data } = yield call(getContactInfo);
      yield put({
        type: 'setContactInfo',
        payload: data,
      });
    },
    *upateCustomerId({ payload }, { put }) {
      yield put({ type: 'upateCustomer', payload });
    },
    *getSessionTime(_, { call }) {
      const { data: noticeTime } = yield call(getNoticeTime);
      const { data: interval } = yield call(getInterval);
      return { noticeTime, interval };
    },
    *getSessionRemineTime(_, { call }) {
      const { data } = yield call(getRemainingTime);
      return Number(data);
    },
    *refreshSessionTime(_, { call }) {
      yield call(recoverSession);
    },
    *fetchCurrentUserRole({ payload }, { call, put }) {
      const { success, data } = yield call(queryUserRoles, payload);
      if (success) {
        yield put({
          type: 'updateState',
          payload: {
            currentUserRole: data,
          },
        });
      }
    },
    *addComponentPrivilege({ payload }, { call }) {
      const { method, ...resetProps } = payload;
      const { success, data } = yield call(addComponentPrivilege, method, resetProps);
      if (success) {
        if (data === 'success') {
          message.success(formatMessage({ id: 'COMPONENT_PRIVILEGE_EDIT_SUCCESS' }));
        } else {
          message.success(formatMessage({ id: 'COMPONENT_PRIVILEGE_ADD_SUCCESS' }));
        }
      }
    },
    *checkComponentPrivilege({ payload }, { call }) {
      const { success, data } = yield call(checkComponentPrivilege, payload);
      if (success) {
        return data || '';
      }
      return null;
    },
    *getMenuComponentPrivs({ payload }, { call, put }) {
      const { success, data } = yield call(getMenuComponentPrivs, payload);
      if (success) {
        yield put({
          type: 'updateState',
          payload: {
            menuComponentPrivs: data,
          },
        });
      }
    },
    *delComponentPrivs({ payload }, { call }) {
      const { success, data } = yield call(delComponentPrivs, payload);
      if (success) {
        if (data === 'success') {
          message.success(formatMessage({ id: 'COMPONENT_PRIVILEGE_DELETE_SUCCESS' }));
        }
      }
    },

    *fetchAsyncConfig(_, { call, put, select }) {
      const { success, data } = yield call(getAsyncConfig, {
        mask: 'ASYNC_COMPONENT',
      });
      const asyncConfig = data && data[0];
      const { asyncComponent: ac } = yield select(state => state.global);
      if (asyncConfig && asyncConfig.currentValue) {
        try {
          const configs = JSON.parse(asyncConfig.currentValue);
          const { search = [], rightHeader = [] } = ac;
          // let newSearch = search,newRightHeader=rightHeader;
          configs.forEach((config, i) => {
            loadjs(config.url, {
              success: () => {
                const asyncComponent = window[config.library];
                if (asyncComponent) {
                  if (asyncComponent[config.search]) {
                    search[i] = {
                      component: asyncComponent[config.search],
                      label: config.name || '',
                      privCode: config.privCode,
                    };
                  }

                  if (asyncComponent[config.rightHeader]) {
                    rightHeader[i] = {
                      component: asyncComponent[config.rightHeader],
                      privCode: config.privCode,
                    };
                  }
                }
                // eslint-disable-next-line no-underscore-dangle
                window.g_app._store.dispatch({
                  type: 'global/sAsyncComponent',
                  payload: {
                    search: search.slice(0),
                    rightHeader: rightHeader.slice(0),
                  },
                });
              },
            });
          });
        } catch {
          // console.error('Check your async component configuration');
        }
      }
      if (success) {
        yield put({
          type: 'saveAsyncConfig',
          payload: data,
        });
      }
    },
    *sAsyncComponent({ payload }, { put }) {
      yield put({
        type: 'saveAsyncComponent',
        payload,
      });
    },
    *fetchLogged(_, { call }) {
      const { success, data } = yield call(logged);
      if (success) {
        return data;
      }
      return null;
    },
    *getMagicCode({ payload }, { call, select }) {
      const { currentUser } = yield select(state => state.global);
      // eslint-disable-next-line no-param-reassign
      payload.password = currentUser.password;
      const { success, data } = yield call(getMagicCode, payload);
      if (success) {
        return data;
      }
      return null;
    },
    *fetchSecurityRule(_, { call, put }) {
      const { success, data } = yield call(qryCurrentSecurityRule);
      if (success) {
        yield put({
          type: 'updateState',
          payload: {
            securityRule: data,
          },
        });
      }
    },

    *getType({ payload, success }, { call, put }) {
      const res = yield call(getPartnerType, payload);
      if (res) {
        const { columnName } = payload;
        const { data } = res;
        if (columnName === SELECT_TYPE_INFO.PARTNER_EVENT_TYPE_COLUMN_NAME) {
          yield put({
            type: 'updateState',
            payload: { partnerEventTypeList: data.data },
          });
        } else if (columnName === SELECT_TYPE_INFO.PARTNER_COMPANY_TYPE_COLUMN_NAME) {
          yield put({
            type: 'updateState',
            payload: { partnerCompanyTypeList: data.data },
          });
        } else if (columnName === SELECT_TYPE_INFO.PARTNER_STATE_COLUMN_NAME) {
          yield put({
            type: 'updateState',
            payload: { partnerStateList: data.data },
          });
        } else if (columnName === SELECT_TYPE_INFO.PARTNER_CONTACT_TYPE_COLUMN_NAME) {
          yield put({
            type: 'updateState',
            payload: { partnerContactTypeList: data.data },
          });
        }
        if (success) {
          success(data.data);
        }
      }
    },

    *modServiceState({ payload }, { call }) {
      const res = yield call(modServiceState, payload);
      if (res) {
        const { data } = res;
        const value = get(data, 'data.value') || '';
        return value;
      }
      return false;
    },

    *modSingleServiceState({ payload }, { call }) {
      const res = yield call(modSingleServiceState, payload);
      if (res) {
        const { data } = res;
        return data;
      }
      return null;
    },

    *modPartnerState({ payload, success }, { call }) {
      const res = yield call(modPartnerState, payload);
      if (res) {
        const { data } = res;
        if (success) {
          success(data);
        }
      }
    },

    *qryAddrAttrValueList({ payload }, { call, put }) {
      const res = yield call(qryAddrAttrValueList, payload);
      if (res) {
        const { data } = res;
        const { attrCode } = payload;
        const { prcAttrValueDtoList } = get(data, 'data') || [];
        if (attrCode === ADDRESS_UM_INFO.UM_COUNTRY) {
          yield put({
            type: 'updateState',
            payload: { prcAttrCountryList: prcAttrValueDtoList },
          });
        } else if (attrCode === ADDRESS_UM_INFO.UM_STATE) {
          yield put({
            type: 'updateState',
            payload: { prcAttrStateList: prcAttrValueDtoList },
          });
        } else if (attrCode === ADDRESS_UM_INFO.UM_CITY) {
          yield put({
            type: 'updateState',
            payload: { prcAttrCityList: prcAttrValueDtoList },
          });
        }
      }
    },

    *qryPostAddrListByState({ payload }, { call, put }) {
      const res = yield call(qryPostAddrList, payload);
      if (res) {
        const { data } = res;
        const prcPostCodeAddrDtos = get(data, 'data.prcPostCodeAddrDtos') || [];
        const cityList = get(data, 'data.cityList') || [];
        const prcPostCodeList = [];
        const prcAttrCityList = [];
        prcPostCodeAddrDtos.forEach(item => {
          prcPostCodeList.push({ valueMark: item.postCode });
        });
        cityList.forEach(item => {
          prcAttrCityList.push({ valueMark: item.city });
        });
        if (!isEmpty(prcAttrCityList)) {
          yield put({
            type: 'updateState',
            payload: { prcPostCodeList, prcAttrCityList },
          });
        } else {
          yield put({
            type: 'updateState',
            payload: { prcPostCodeList },
          });
        }
      }
    },

    *qryPostAddrListByCity({ payload }, { call, put }) {
      const res = yield call(qryPostAddrList, payload);
      if (res) {
        const { data } = res;
        const prcPostCodeAddrDtos = get(data, 'data.prcPostCodeAddrDtos') || [];
        const prcPostCodeList = [];
        prcPostCodeAddrDtos.forEach(item => {
          prcPostCodeList.push({ valueMark: item.postCode });
        });
        const prcAttrState = get(data, 'data.prcPostCodeAddrDtos[0].state') || '';
        yield put({
          type: 'updateState',
          payload: { prcPostCodeList },
        });
        return prcAttrState;
      }
      return false;
    },

    *qryPostCodeList({ payload }, { call, put }) {
      const { success, data } = yield call(qryPostAddrList, payload);
      if (success) {
        const prcPostCodeAddrDtos = get(data, 'data.prcPostCodeAddrDtos') || [];
        const prcPostCodeList = [];
        prcPostCodeAddrDtos.forEach(item => {
          prcPostCodeList.push({ valueMark: item.postCode });
        });
        yield put({
          type: 'updateState',
          payload: {
            prcPostCodeList,
          },
        });
      }
    },

    *selectPostCodeChange({ payload }, { call }) {
      const res = yield call(qryPostAddrList, payload);
      if (res) {
        const { data } = res;
        const { prcPostCodeAddrDtos } = get(data, 'data') || [];
        return prcPostCodeAddrDtos;
      }
      return false;
    },

    *selectAddressCancel({ success }, { put }) {
      yield put({ type: 'updateState', payload: { prcPostCodeList: [] } });
      if (success) {
        success();
      }
    },

    *selectStandardAddressConfirm({ payload, success }, { put }) {
      yield put({
        type: 'updateState',
        payload: { partnerStandardAddress: payload },
      });
      if (success) {
        success();
      }
    },

    *selectCorrespondenceAddressConfirm({ payload, success }, { put }) {
      yield put({
        type: 'updateState',
        payload: { correspondenceAddress: payload },
      });
      if (success) {
        success();
      }
    },

    *prcConfigItemParam(_, { call, put }) {
      const res = yield call(prcConfigItemParam);
      if (res) {
        const { data } = res;
        const str = get(data, 'data.value') || '';
        const configItemParam = str.split('|');
        yield put({
          type: 'updateState',
          payload: { configItemParam },
        });
      }
    },

    *checkOngoingOrder4LifeCycle({ payload }, { call }) {
      const res = yield call(checkOngoingOrder4LifeCycle, payload);
      if (res) {
        const { data } = res;
        return get(data, 'data.value') || '';
      }
      return false;
    },

    *prcPartnerProfile(_, { call, put }) {
      const res = yield call(prcPartnerProfile);
      if (res) {
        const { data } = res;
        if (data) {
          yield put({
            type: 'updateState',
            payload: { partnerProfile: get(data, 'data') || {} },
          });
        }
      }
    },

    *prcPartnerType(_, { call, put }) {
      const res = yield call(prcPartnerType);
      if (res) {
        const { data } = res;
        yield put({
          type: 'updateState',
          payload: {
            partnerTypeList: get(data, 'data') || [],
          },
        });
      }
    },

    // 通过配置项查附件大小
    *getUploadFileSize({ payload }, { call }) {
      let response = {};
      if (payload.paramCode === UPLOAD_FILE_LIMITATION.CONTRACT_SIZE) {
        response = yield call(getUploadFileSizePre, payload);
      } else {
        response = yield call(getUploadFileSize, payload);
      }
      const { success, data } = response;
      if (success) {
        const uploadFileSize = get(data, 'data.value', 0);
        return uploadFileSize;
      }
      return false;
    },

    *getUploadFileType({ payload }, { call }) {
      let response = {};
      if (payload.paramCode === UPLOAD_FILE_LIMITATION.CONTRACT_TYPE) {
        response = yield call(getUploadFileTypePre, payload);
      } else {
        response = yield call(getUploadFileType, payload);
      }
      const { success, data } = response;
      if (success) {
        const uploadFileType = get(data, 'data.value');
        return uploadFileType;
      }
      return false;
    },

    *qryMcpPartner(_, { call }) {
      const res = yield call(qryMcpPartner);
      if (res) {
        const { data } = res;
        return get(data, 'data.value') || false;
      }
      return false;
    },

    *serviceLifeCyclePreCheck4UM({ payload }, { call }) {
      const res = yield call(serviceLifeCyclePreCheck4UM, payload);
      if (res) {
        const { data } = res;
        return data;
      }
      return false;
    },
    *getKBSUrl({ payload }, { call, put }) {
      const { success, data = {} } = yield call(getPrcConfigParam, payload);
      if (success) {
        const KBSUrl = get(data, 'data.value') || '';
        if (KBSUrl) {
          yield put({
            type: 'updateState',
            payload: {
              KBSUrl,
            },
          });
          return KBSUrl;
        }
      }
      return null;
    },
    *getKBSSearchAvailable({ payload }, { call, put }) {
      const { success, data = {} } = yield call(getPrcConfigParam, payload);
      if (success) {
        const KBSSearchAvailable = get(data, 'data.value') || '';
        if (KBSSearchAvailable) {
          yield put({
            type: 'updateState',
            payload: {
              KBSSearchAvailable,
            },
          });
          return KBSSearchAvailable;
        }
      }
      return null;
    },
  },

  reducers: {
    setContactInfo(state, { payload }) {
      return {
        ...state,
        contactInfo: payload,
      };
    },
    saveAsyncConfig(state, { payload }) {
      return {
        ...state,
        asyncConfig: payload,
      };
    },
    saveAsyncComponent(state, { payload }) {
      return {
        ...state,
        asyncComponent: payload,
      };
    },
    changeLayoutCollapsed(state, { payload }) {
      loginSession.saveData('collapsed', payload);
      return {
        ...state,
        collapsed: payload,
      };
    },
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    upateCustomer(state, action) {
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    },
    setStaffJobs(state, action) {
      return {
        ...state,
        savedstaffJobs: [...action.payload],
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`

      const unsubsurbe = history.listen(() => {
        dispatch({
          type: 'getContactInfo',
        });
        unsubsurbe && unsubsurbe();
      });

      return history.listen(({ pathname, search }) => {
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
        // 360 modify minwidth
        if (pathname.indexOf('/rws') > -1) {
          dispatch({ type: 'updateState', payload: { minWidthState: false } });
        } else {
          dispatch({ type: 'updateState', payload: { minWidthState: true } });
        }
      });
    },
  },
};
