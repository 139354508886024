'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _en_US = require('rc-picker/lib/locale/en_US');

var _en_US2 = _interopRequireDefault(_en_US);

var _en_US3 = require('antd/lib/time-picker/locale/en_US');

var _en_US4 = _interopRequireDefault(_en_US3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

// Merge into a locale object
var locale = {
    lang: (0, _extends3['default'])({ placeholder: 'Select date', yearPlaceholder: 'Select year', quarterPlaceholder: 'Select quarter', monthPlaceholder: 'Select month', weekPlaceholder: 'Select week', rangePlaceholder: ['Start date', 'End date'], rangeYearPlaceholder: ['Start year', 'End year'], rangeMonthPlaceholder: ['Start month', 'End month'], rangeWeekPlaceholder: ['Start week', 'End week'] }, _en_US2['default']),
    timePickerLocale: (0, _extends3['default'])({}, _en_US4['default'])
};
// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json
exports['default'] = locale;
module.exports = exports['default'];