"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patchRoutes = patchRoutes;
exports.render = render;

function patchRoutes(routes) {
  window.g_routes = routes;
}

function render(oldRender) {
  oldRender();
}