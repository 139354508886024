"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

/* eslint-disable no-undef, prefer-rest-params */
var ReactIntl = require('react-intl');
/* eslint-disable no-undef */


function setLocale(lang) {
  var localLocale = window.localStorage.getItem('umi_locale');

  if (lang && !/^([a-z]{2})-([A-Z]{2})$/.test(lang)) {
    // for reset when lang === undefined
    throw new Error('setLocale lang format error');
  }

  if (lang && localLocale !== lang) {
    window.localStorage.setItem('umi_locale', lang || '');
    window.location.reload();
  }
}

function getFloder(lang) {
  if (lang.match(/zh/g)) return 'zh-CN';
  if (lang.match(/en/g)) return 'en-US';
  return lang;
}

function getLocale() {
  var lang = window.localStorage.getItem('umi_locale');
  if (!lang || (typeof lang === 'string' && lang.length <= 0) || lang === 'null' || lang === 'undefined') {
    lang = window.g_lang;
  }
  return getFloder(window.g_lang);
} // init api methods


var intl;
var intlApi = {};
['formatMessage', 'formatHTMLMessage', 'formatDate', 'formatTime', 'formatRelative', 'formatNumber', 'formatPlural', 'now', 'onError'].forEach(function (methodName) {
  intlApi[methodName] = function () {
    if (intl && intl[methodName]) {
      var _intl$methodName;

      // _setIntlObject has been called
      return (_intl$methodName = intl[methodName]).call.apply(_intl$methodName, [intl].concat(Array.prototype.slice.call(arguments)));
    } else if (console && console.warn) {
      console.warn("[@whalecloud/portal-plugin-i18n] ".concat(methodName, " not initialized yet, you should use it after react app mounted."));
    }

    return null;
  };
}); // react-intl 没有直接暴露 formatMessage 这个方法
// 只能注入到 props 中，所以通过在最外层包一个组件然后组件内调用这个方法来把 intl 这个对象暴露到这里来
// TODO 查找有没有更好的办法

function _setIntlObject(theIntl) {
  // umi 系统 API，不对外暴露
  intl = theIntl;
  if (!window.g_locale) window.g_locale = intl;
}

module.exports = (0, _objectSpread2.default)({}, ReactIntl, intlApi, {
  setLocale: setLocale,
  getLocale: getLocale,
  _setIntlObject: _setIntlObject
});