import request from '@/utils/request';
import {
  PRC_HEADER_API,
  PARTNER_COMPANY_TYPE_TABLE_NAME,
  PARTNER_COMPANY_TYPE_COLUMN_NAME,
  PARTNER_CONTACT_TYPE_TABLE_NAME,
  PARTNER_CONTACT_TYPE_COLUMN_NAME,
} from '@/ecareDefaultSettings';

// Partner在自服务提交注册
export async function registerPartnerAPI(data) {
  return request(`${PRC_HEADER_API}/partner/register`, {
    method: 'POST',
    body: data,
  });
}

// Partner在自服务查询CompanyType
export async function qryCompanyTypeAPI() {
  return request(`${PRC_HEADER_API}/domains`, {
    method: 'GET',
    params: {
      tableName: PARTNER_COMPANY_TYPE_TABLE_NAME,
      columnName: PARTNER_COMPANY_TYPE_COLUMN_NAME,
    },
  });
}

// Partner自服务contact模块type下拉列表
export async function qryContactTypeAPI() {
  return request(`${PRC_HEADER_API}/domains`, {
    method: 'GET',
    params: {
      tableName: PARTNER_CONTACT_TYPE_TABLE_NAME,
      columnName: PARTNER_CONTACT_TYPE_COLUMN_NAME,
    },
  });
}

// Partner自服务service模块serviceType下拉列表
export async function qryServiceTypeAPI() {
  return request(`${PRC_HEADER_API}/qryServTypeByChannel`, {
    method: 'GET',
  });
}

// Partner自服务Service Information模块shortCode 弹出框展示
export async function qryShortCodesAPI(data) {
  return request(`${PRC_HEADER_API}/chooseShortCode`, {
    method: 'POST',
    body: data,
  });
}

// 自服务订单操作界面公共信息查询
export async function getBaseInfoAPI(params) {
  return request(`${PRC_HEADER_API}/partner/register/baseInfo`, {
    method: 'GET',
    params,
  });
}

// 自服务Partner下载NDA
export async function getNDAAPI(params) {
  return request(`${PRC_HEADER_API}/partner/register/getNDA`, {
    method: 'GET',
    params,
  });
}

// 自服务Partner下载NDA之后提交
export async function submitNDAAPI(data) {
  return request(`${PRC_HEADER_API}/partner/register/getNDA/submit`, {
    method: 'POST',
    data,
  });
}

// 自服务Partner下载NDA
export async function completeBankInfoAPI(params) {
  return request(`${PRC_HEADER_API}/partner/register/completeBankInfo`, {
    method: 'GET',
    params,
  });
}

// 自服务Partner完善Bank 信息之后提交
export async function submitBankInfoAPI(data) {
  return request(`${PRC_HEADER_API}/partner/register/completeBankInfo/submit`, {
    method: 'POST',
    data,
  });
}

// 自服务Partner下载Agreement
export async function getAgreementAPI(params) {
  return request(`${PRC_HEADER_API}/partner/register/baseInfo`, {
    method: 'GET',
    params,
  });
}

// 自服务Partner补充附件
export async function uploadNewDocAPI(params) {
  return request(`${PRC_HEADER_API}/partner/register/uploadNewDoc`, {
    method: 'GET',
    params,
  });
}

// 自服务Partner补充附件之后提交
export async function submitUploadNewDocAPI(data) {
  return request(`${PRC_HEADER_API}/partner/register/uploadNewDoc/submit`, {
    method: 'POST',
    data,
  });
}

// 自服务Partner上传支付证明
export async function getDepProofsAPI(params) {
  return request(`${PRC_HEADER_API}/partner/register/uploadDepProofs`, {
    method: 'GET',
    params,
  });
}

// 自服务Partner上传支付证明
export async function submitDepProofsAPI(data) {
  return request(`${PRC_HEADER_API}/partner/register/uploadDepProofs/submit`, {
    method: 'POST',
    data,
  });
}

// 查询Classification
export async function qryClassificationAPI() {
  return request(`${PRC_HEADER_API}/domains`, {
    method: 'GET',
    params: {
      tableName: 'PRC_SERVICE',
      columnName: 'CLASSIFICATION',
    },
  });
}

// Platform Partner采集
export async function getPlatformPartnerAPI(data) {
  return request(`${PRC_HEADER_API}/qryPartnerPlatform?subServTypeId=${data.subServTypeId}`);
}
// Registration No校验
export async function checkPartnerRegNumAPI(data) {
  return request(`${PRC_HEADER_API}/partners/checkPartnerRegNum`, {
    method: 'POST',
    body: {
      regNum: data,
    },
  });
}

// Registration No校验
export async function isExistSameEmailAPI(email) {
  return request(`${PRC_HEADER_API}/isExistSameEmail`, {
    method: 'GET',
    params: {
      email,
    },
  });
}
