import {
  qryServiceTypeAPI,
  qryCompanyTypeAPI,
  qryContactTypeAPI,
  registerPartnerAPI,
  qryShortCodesAPI,
  qryClassificationAPI,
  getPlatformPartnerAPI,
  checkPartnerRegNumAPI,
  isExistSameEmailAPI,
} from '@/services/join';
import Alert from '@/components/Alert';
import { get } from 'lodash';

export default {
  namespace: 'join',
  state: {
    serviceTypeList: [],
    serviceType: [],
    companyType: [],
    contactType: [],
    shortCodes: [],
    classification: [],
    platformPartner: {},
  },
  effects: {
    *qryServiceType(_, { call, put }) {
      const {
        data: { code, data },
      } = yield call(qryServiceTypeAPI);
      if (code === 200) {
        yield put({
          type: 'save',
          payload: {
            serviceTypeList: data,
          },
        });
        yield put({
          type: 'save',
          payload: {
            serviceType: (data || []).filter(
              item => item.subServTypeCode !== 'DFS' && item.subServTypeCode !== 'DES',
            ),
          },
        });
      }
      return data;
    },
    *filterServiceTypeList({ payload }, { select, put }) {
      const serviceType = yield select(state => state.join.serviceTypeList);
      const { partnerType } = payload;
      let tempArr = [];
      switch (partnerType) {
        case 'Financial':
          tempArr = serviceType.filter(item => item.subServTypeCode === 'DFS');
          break;
        case 'Enterprise':
          tempArr = serviceType.filter(item => item.subServTypeCode === 'DES');
          break;
        case 'Consumer':
          tempArr = serviceType.filter(
            item => item.subServTypeCode !== 'DFS' && item.subServTypeCode !== 'DES',
          );
          break;
        default:
      }
      yield put({
        type: 'save',
        payload: {
          serviceType: tempArr,
        },
      });
      return tempArr;
    },
    *qryCompanyType(_, { call, put }) {
      const {
        data: { code, data },
      } = yield call(qryCompanyTypeAPI);
      if (code === 200) {
        yield put({
          type: 'save',
          payload: {
            companyType: data,
          },
        });
      }
      return data;
    },
    *qryContactType(_, { call, put }) {
      const {
        data: { code, data },
      } = yield call(qryContactTypeAPI);
      if (code === 200) {
        yield put({
          type: 'save',
          payload: {
            contactType: data,
          },
        });
      }
      return data;
    },
    *registerPartner({ payload }, { call }) {
      const {
        data: { code, data, message },
      } = yield call(registerPartnerAPI, payload);
      if (code === 200) {
        return data;
      }
      Alert.warning({
        title: message,
      });
      return null;
    },
    *qryShortCodes({ payload }, { call, put }) {
      const {
        data: { code, data },
      } = yield call(qryShortCodesAPI, payload);
      if (code === 200) {
        yield put({
          type: 'save',
          payload: {
            shortCodes: data,
          },
        });
        return data;
      }
      return null;
    },
    *qryClassification({ payload }, { call, put }) {
      const {
        data: { code, data },
      } = yield call(qryClassificationAPI, payload);
      if (code === 200) {
        yield put({
          type: 'save',
          payload: {
            classification: data,
          },
        });
        return data;
      }
      return null;
    },
    // Platform Partner采集
    *getPlatformPartner({ payload }, { call, put, select }) {
      const { platformPartner } = yield select(state => state.join);
      const { subServTypeId } = payload;
      const { success, data } = yield call(getPlatformPartnerAPI, payload);
      if (success) {
        yield put({
          type: 'save',
          payload: {
            platformPartner: {
              ...platformPartner,
              [subServTypeId]: get(data, 'data'),
            },
          },
        });
      } else {
        Alert.warning({
          title: 'Registration failed',
        });
      }
    },
    *checkPartnerRegNum({ payload }, { call }) {
      const {
        data: { code, data },
      } = yield call(checkPartnerRegNumAPI, payload);
      if (code === 200) {
        return data;
      }
      return null;
    },
    *isExistSameEmail({ payload }, { call }) {
      const res = yield call(isExistSameEmailAPI, payload);
      const {
        data: { code },
        errorMsg,
      } = res;
      if (code === 417) {
        return errorMsg;
      }
      return '';
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
