import request from '@/utils/request';
import { NGPORTAL_HEADER_API, PRC_HEADER_API } from '@/ecareDefaultSettings';

export async function query() {
  return request(`${NGPORTAL_HEADER_API}/api/users`);
}

export async function queryCurrent() {
  return request(`${NGPORTAL_HEADER_API}/currentUser`);
}

export async function queryMenus(portalId) {
  return request(`${NGPORTAL_HEADER_API}/users/portals/${!portalId ? 1 : portalId}/dirmenus`);
}

export async function queryPortalsByUser(userId) {
  return request(`${NGPORTAL_HEADER_API}/users/${userId}/portals`);
}

export async function setCurrentPortal(portalId) {
  return request(`${NGPORTAL_HEADER_API}/portals/current`, {
    method: 'POST',
    body: { portalId },
  });
}

export async function queryFavoriteMenu(portalId) {
  return request(`${NGPORTAL_HEADER_API}/portals/${!portalId ? 1 : portalId}/user/favorites`);
}

export async function addFavoriteMenu() {
  return request(`${NGPORTAL_HEADER_API}/favorites`, {
    method: 'POST',
    // body: {"userId":1,"portalId":1,"menuId":4,"alias":"Privilege Management","isDefaultOpen":"N"}
    body: { userId: 112, portalId: 1, menuId: 132, alias: 'Iframe', isDefaultOpen: 'Y' },
  });
}

export async function setFavorites(param, method) {
  return request(`${NGPORTAL_HEADER_API}/favorites`, {
    method,
    body: param,
  });
}

export async function deleteFavorites(menuFavId) {
  return request(`${NGPORTAL_HEADER_API}/favorites/${menuFavId}`, {
    method: 'DELETE',
  });
}

export async function qryWorkerOrderAPI() {
  return request(`${PRC_HEADER_API}/queryToDoWorkOrderCount`, {
    method: 'GET',
  });
}

export async function confirmMcpAPI() {
  return request(`${PRC_HEADER_API}/confirmMcp`, {
    method: 'GET',
  });
}
