'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _zh_CN = require('rc-pagination/lib/locale/zh_CN');

var _zh_CN2 = _interopRequireDefault(_zh_CN);

var _zh_CN3 = require('../date-picker/locale/zh_CN');

var _zh_CN4 = _interopRequireDefault(_zh_CN3);

var _zh_CN5 = require('../time-picker/locale/zh_CN');

var _zh_CN6 = _interopRequireDefault(_zh_CN5);

var _zh_CN7 = require('../calendar/locale/zh_CN');

var _zh_CN8 = _interopRequireDefault(_zh_CN7);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

exports['default'] = {
    locale: 'zh-cn',
    Pagination: _zh_CN2['default'],
    DatePicker: _zh_CN4['default'],
    TimePicker: _zh_CN6['default'],
    Calendar: _zh_CN8['default'],
    // locales for all comoponents
    global: {
        placeholder: '请选择'
    },
    Table: {
        filterTitle: '筛选',
        filterConfirm: '确定',
        filterReset: '重置',
        selectAll: '全选当页',
        selectInvert: '反选当页',
        sortTitle: '排序',
        expand: '展开行',
        collapse: '关闭行'
    },
    Modal: {
        okText: '确定',
        cancelText: '取消',
        justOkText: '知道了'
    },
    Popconfirm: {
        cancelText: '取消',
        okText: '确定'
    },
    Transfer: {
        searchPlaceholder: '请输入搜索内容',
        itemUnit: '项',
        itemsUnit: '项'
    },
    Upload: {
        uploading: '文件上传中',
        removeFile: '删除文件',
        uploadError: '上传错误',
        previewFile: '预览文件',
        downloadFile: '下载文件'
    },
    Empty: {
        description: '暂无数据'
    },
    Icon: {
        icon: '图标'
    },
    Text: {
        edit: '编辑',
        copy: '复制',
        copied: '复制成功',
        expand: '展开'
    },
    PageHeader: {
        back: '返回'
    },
    ImageViewer: {
        previousOne: '上一张',
        nextOne: '下一张',
        zoomIn: '放大',
        zoomOut: '缩小'
    },
    DynamicAttribute: {
        upload: '上传',
        clickToUpload: '点击上传'
    },
    VideoPlayer: {
        name: 'zh_CN',
        content: {
            "OD": "原画",
            "FD": "流畅",
            "LD": "标清",
            "SD": "高清",
            "HD": "超清",
            "2K": "2K",
            "4K": "4K",
            "FHD": "全高清",
            "XLD": "极速",
            "Speed": "倍速",
            "Speed_05X_Text": "0.5X",
            "Speed_1X_Text": "正常",
            "Speed_125X_Text": "1.25X",
            "Speed_15X_Text": "1.5X",
            "Speed_2X_Text": "2X",
            "Quality_Change_Fail_Switch_Text": "不能播放，切换为",
            "Quality_Change_Text": "正在为您切换到 ",
            "Quality_The_Url": "此地址",
            "Refresh_Text": "刷新",
            "Detection_Text": "诊断",
            "Cancel": "取消",
            "Play_DateTime": "播放时间",
            "AutoPlayDelayDisplayText": "$$秒以后开始播放",
            "Error_Load_Abort_Text": "获取数据过程被中止",
            "Error_Network_Text": "网络错误加载数据失败",
            "Error_Decode_Text": "解码错误",
            "Error_Server_Network_NotSupport_Text": "服务器、网络错误或格式不支持",
            "Error_Offline_Text": "网络不可用，请确定",
            "Error_Play_Text": "播放出错啦",
            "Error_Retry_Text": "请尝试退出重试或刷新",
            "Error_AuthKey_Text": "可能鉴权过期、域名不在白名单或请求被拦截",
            "Error_H5_Not_Support_Text": "h5不支持此格式，请使用flash播放器",
            "Error_Not_Support_M3U8_Text": "浏览器不支持m3u8视频播放",
            "Error_Not_Support_MP4_Text": "浏览器不支持mp4视频播放",
            "Error_Not_Support_encrypt_Text": "h5不支持加密视频的播放，请设置useFlashPrism为true",
            "Error_Vod_URL_Is_Empty_Text": "获取播放地址为空",
            "Error_Vod_Fetch_Urls_Text": "获取地址出错啦，请尝试退出重试或刷新",
            "Fetch_Playauth_Error": "获取播放凭证出错啦，请尝试退出重试或刷新",
            "Error_Playauth_Decode_Text": "playauth解析错误",
            "Error_Vid_Not_Same_Text": "不能更新地址，vid和播放中的不一致",
            "Error_Playauth_Expired_Text": "凭证已过期，请尝试退出重试或刷新",
            "Error_MTS_Fetch_Urls_Text": "MTS获取取数失败",
            "Error_Load_M3U8_Failed_Text": "获取m3u8文件失败",
            "Error_Load_M3U8_Timeout_Text": "获取m3u8文件超时",
            "Error_M3U8_Decode_Text": "获取m3u8文件解析失败",
            "Error_TX_Decode_Text": "解析数据出错",
            "Error_Waiting_Timeout_Text": "缓冲数据超时，请尝试退出重试或刷新",
            "Error_Invalidate_Source": "无效地址",
            "Error_Fetch_NotStream": "此vid没有可播放视频",
            "Error_Not_Found": "播放地址不存在",
            "Live_End": "亲，直播已结束",
            "Play_Before_Fullscreen": "播放后再全屏",
            "Can_Not_Seekable": "不能seek到这里",
            "Cancel_Text": "取消",
            "OK_Text": "确认",
            "Auto_Stream_Tip​​_Text": "网络不给力，是否切换到$$",
            "Request_Block_Text": "浏览器安全策略视频地址不能为http协议，与网站https协议不一致",
            "Open_Html_By_File": "不能直接在浏览器打开html文件，请部署到服务端",
            "Maybe_Cors_Error": "请确认是否开启了允许跨域访问<a href='https://help.aliyun.com/document_detail/62950.html?spm=a2c4g.11186623.2.21.Y3n2oi' target='_blank'>参考文档</a>",
            "Speed_Switch_To": "倍速切换到 ",
            "Curent_Volume": "当前音量：",
            "Volume_Mute": "设置为静音",
            "Volume_UnMute": "设置为非静音",
            "ShiftLiveTime_Error": "直播开始时间不能大于直播结束时间",
            "Error_Not_Support_Format_On_Mobile": "移动端不支持flv、rmtp视频，请使用m3u8",
            "SessionId_Ticket_Invalid": "DRM视频播放，sessionId和ticket属性不能为空",
            "Http_Error": "Http网络请求失败",
            "Http_Timeout": "http请求超时",
            "DRM_License_Expired": "DRM license超时，请刷新",
            "Not_Support_DRM": "浏览器不支持DRM视频的播放",
            "Network_Error": "网络无法连接，请尝试检查网络后刷新试试",
            "Video_Error": "视频播放异常，请刷新试试",
            "Decode_Error": "播放数据解码错误",
            "Live_Not_Start": "亲，直播还未开始哦，敬请期待",
            "Live_Loading": "直播信息加载中，请刷新试试",
            "Live_Abrot": "当前直播信号中断，请刷新后重试",
            "Corss_Domain_Error": "请确认您的域名已完成备案和CNAME绑定，并处于启用状态，或资源允许跨越访问",
            "Url_Timeout_Error": "您所观看的视频地址连接超时，请刷新后重试",
            "Connetction_Error": "抱歉,该视频由于连接错误暂时不能播放,请观看其它视频",
            "Fetch_MTS_Error": "获取视频列表失败，请确认",
            "Token_Expired_Error": "请求接口失败，请确认Token是否过期",
            "Video_Lists_Empty_Error": "获取视频列表为空，请确认播放数据与格式",
            "Encrypted_Failed_Error": "获取视频加密秘钥错误，请确认播放权限",
            "Fetch_Failed_Permission_Error": "获取视频列表失败，请确认播放权限",
            "Invalidate_Param_Error": "无输入视频，请确认输入参数",
            "Fetch_MTS_NOT_NotStream_Error": "此vid没有可播放视频",
            "Cant_Use_Flash_On_Mobile": "移动端不支持Flash播放器，请使用h5播放器",
            "CC_Switch_To": "字幕切换到 ",
            "AudioTrack_Switch_To": "音轨切换到 ",
            "Subtitle": "字幕",
            "AudioTrack": "音轨",
            "Quality": "清晰度",
            "Auto": "自动",
            "Quality_Switch_To": "清晰度切换到 ",
            "Setting": "设置",
            "Volume": "音量",
            "CloseSubtitle": "关闭字幕",
            "OpenSubtitle": "打开字幕",
            "ExistFullScreen": "退出全屏",
            "Muted": "静音",
            "Retry": "重试",
            "Forbidden_​​Text": "内部信息，严禁外传",
            "Refresh": "刷新",
            "Diagnosis": "诊断",
            "Live_Finished": "直播已结束,谢谢观看",
            "Play": "播放",
            "Pause": "暂停",
            "Snapshot": "截图",
            "Replay": "重播",
            "Live": "直播",
            "Encrypt": "加密",
            "Sound": "声音",
            "Fullscreen": "全屏",
            "Exist_Fullscreen": "退出全屏",
            "Resolution": "清晰度",
            "Next": "下一集",
            "Brightness": "亮度",
            "Default": "默认",
            "Contrast": "对比度",
            "Titles_Credits": "片头片尾",
            "Skip_Titles": "跳过片头",
            "Skip_Credits": "跳过片尾",
            "Not_Support_Out_Site": "该视频暂不支持站外播放",
            "Watch_Now": "立即观看",
            "Caption_chinese": "中文",
            "Caption_english": "English",
            "Caption_japanese": "日本語",
            "Caption_off": "off",
            "Caption_ESP": "ESPAÑOL",
            "Caption_РУС": "РУССКИЙ",
            "Caption_POR": "PORTUGUÊS",
            "Caption_FRA": "FRANÇAIS",
            "Caption_LDL": "العربية",
            "Caption_DEU": "DEUTSCH",
            "Caption_HAN": "한국어",
            "Caption_ITA": "ITALIANO",
            "Caption": "字幕",
            "Track": "音轨",
            "Track_ENG": "英语",
            "Track_JPA": "日本語"
        }
    }
};
module.exports = exports['default'];