import { getTermsAndConditions } from '@/services/terms';

export default {
  namespace: 'terms',

  state: {
    termsAndConditionsHtml: '',
  },

  effects: {
    *getTermsAndConditions({ payload }, { call, put }) {
      const {
        data: { code, data },
      } = yield call(getTermsAndConditions, payload);
      if (code === 200) {
        yield put({
          type: 'save',
          payload: {
            termsAndConditionsHtml: data.content,
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
