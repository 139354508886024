import moment from 'moment';
import React from 'react';
import { Modal } from '@whalecloud/fdx';
import { routerRedux } from 'dva/router';
import { formatMessage, setLocale } from 'umi/locale';
import { parse, stringify } from 'qs';
import { get } from 'lodash';
import { PRC_UPLOAD_HEADER_API, FORMAT_DEFAULT_STRING } from '@/ecareDefaultSettings';
import { themeConfig } from '../../config/defaultSettings';
import loginSession from './loginSession';

const prefix = themeConfig['@ant-prefix'];

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  if (type === 'lastWeek') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const endTime = now.getTime() - (day * oneDay + 1000);
    return [moment(endTime - (7 * oneDay - 1000)), moment(endTime)];
  }

  if (type === 'lastMonth') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const lastDate = moment(now).subtract(1, 'months');
    const lastYear = lastDate.year();
    const LastMonth = lastDate.month();
    return [
      moment(`${lastYear}-${fixedZero(LastMonth + 1)}-01 00:00:00`),
      moment(moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    // eslint-disable-next-line no-console
    console.warn('Two path are equal');
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path,
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v || Number.isNaN(v)) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          styles={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            lineHeight: 20,
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

export function menuAdapter(srcData, key, parentKey, children) {
  if (!key || key === '' || !srcData) return [];

  if (Array.isArray(srcData)) {
    const r = [];
    const tmpMap = [];
    srcData.forEach(v => {
      tmpMap[v[key]] = v;
    });

    srcData.forEach(v => {
      const val = v;
      val.name = val.partyName;
      val.path = `${val.url ? val.url : val.partyId}`;
      if (val.path.indexOf('/') !== 0) val.path = `/${val.path}`;
      if (tmpMap[v[parentKey]] && v[key] !== v[parentKey]) {
        if (!tmpMap[v[parentKey]][children]) tmpMap[v[parentKey]][children] = [];
        tmpMap[v[parentKey]][children].push(v);
      } else {
        r.push(v);
      }
    });
    return r;
  }
  return [srcData];
}

export const arrayToTree = (
  array = [],
  key = 'key',
  parentKey = 'parentKey',
  children = 'children',
) => {
  if (!Array.isArray(array)) return [array];
  const tree = [];
  const dict = {};
  array.forEach(v => {
    dict[v[key]] = v;
  });

  array.forEach(v => {
    if ((v[parentKey] || v[parentKey] === 0) && dict[v[parentKey]]) {
      if (!dict[v[parentKey]][children]) {
        dict[v[parentKey]][children] = [];
      }
      dict[v[parentKey]][children].push(v);
    } else {
      tree.push(v);
    }
  });
  return tree;
};

/**
 * 数组对象全等判断
 * @param {array} a1
 * @param {array} a2
 */
export function compareArray(a1, a2) {
  let flag = true;
  if (a1 === a2) flag = true;
  if ((!a1 && a2) || (a1 && !a2)) flag = false;
  if (a1.length !== a2.length) flag = false;
  a1.forEach((item, index) => {
    if (JSON.stringify(item) !== JSON.stringify(a2[index])) flag = false;
  });
  return flag;
}

/**
 * table sort排序格式化
 * @param {object} sorter
 */
export function formatTableSort(sorter) {
  let orderFields;
  if (sorter.columnKey) {
    orderFields = `${sorter.columnKey} ${sorter.order === 'descend' ? 'desc' : 'asc'}`;
  }
  return orderFields;
}

export function getWebRoot() {
  let webroot = document.location.href;
  webroot = webroot.substring(webroot.indexOf('//') + 2, webroot.length);
  webroot = webroot.substring(webroot.indexOf('/') + 1, webroot.length);
  webroot =
    webroot.indexOf('#') === webroot.length - 1
      ? webroot.substring(0, webroot.indexOf('#'))
      : webroot;
  webroot = webroot.indexOf('/') > -1 ? webroot.substring(0, webroot.indexOf('/')) : webroot;
  webroot = webroot.replace('index.html', '');
  webroot = webroot === '#' ? '' : webroot;
  const rootpath = webroot.length <= 0 ? '' : `/${webroot}`;
  return rootpath;
}

export function checkWeakPwd(pwd, userCode) {
  if (userCode) {
    const pwdL = pwd.toLowerCase();
    const userCodeL = userCode.toLowerCase();
    const reversedUc = userCode
      .split('')
      .reverse()
      .join('');
    if (!(pwdL === userCodeL || pwdL === reversedUc)) {
      return true;
    }
    return false;
  }
  return true;
}

/**
 * 密码规则校验
 * @param {string} password
 * @param {object} composition 校验规则
 * @param {string} userCode 不可与用户名正反重复
 */
export function isMatchPwdRule(password, composition, userCode) {
  const userPwdMaxLength = 30;
  let msg = true;
  if (!password) {
    return formatMessage({ id: 'PWD_REQUIRED' });
  }
  if (/(.)\1{2}/.test(password)) {
    return formatMessage({ id: 'PWD_CONTINUOUS_CHAR_NUM' }).replace('{0}', 2);
  }
  if (!checkWeakPwd(password, userCode)) {
    return formatMessage({ id: 'PWD_NOT_USER_CODE' });
  }
  if (password.length < composition.userPwdMinLength || password.length > userPwdMaxLength) {
    msg = formatMessage({ id: 'PWD_LENGTH' })
      .replace('{1}', composition.userPwdMinLength)
      .replace('{2}', userPwdMaxLength);
    return msg;
  }
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSpecialChar = /[@#$%!&*]/.test(password);
  const hasDigitsChar = /[0-9]/.test(password);
  switch (composition.pwdComposition) {
    case '2':
      if (!hasDigitsChar || !(hasLowerCase || hasUpperCase)) {
        return formatMessage({ id: 'PWD_COMPOTION_2' });
      }
      break;
    case '3':
      if (!hasDigitsChar || !hasLowerCase || !hasUpperCase) {
        return formatMessage({ id: 'PWD_COMPOTION_3' });
      }
      break;
    case '4':
      if (!hasDigitsChar || !(hasLowerCase || hasUpperCase) || !hasSpecialChar) {
        return formatMessage({ id: 'PWD_COMPOTION_4' });
      }
      break;
    case '5':
      if (!hasDigitsChar || !hasLowerCase || !hasUpperCase || !hasSpecialChar) {
        return formatMessage({ id: 'PWD_COMPOTION_5' });
      }
      break;
    default:
      break;
  }
  return true;
}

/**
 * Abbreviate Name
 *
 * @param  {string}    full name
 * @return {string}    abbreviate name
 */
export function abbreviateName(name) {
  if (name) {
    const regEn = /.*[A-Za-z]+.*$/;
    const regCh = /.*[\u4e00-\u9fa5]+.*$/;
    const regSpecial = /.*[\s*\.*/g]+.*$/;
    const interceptName = (names, regs, language) => {
      const letterArr = names.split('');
      let firstPosition = null;
      let lastPosition = null;
      letterArr.map((item, index) => {
        if (regs.test(item) && !(!firstPosition >= 0)) {
          firstPosition = index;
        }
        if (
          (firstPosition === '0' || firstPosition) &&
          !regs.test(item) &&
          !regSpecial.test(item)
        ) {
          lastPosition = index;
        }
        return item;
      });
      let newArr = null;
      if (language === 'en') {
        newArr = lastPosition
          ? names.substring(firstPosition, lastPosition).split(' ')
          : names.substring(firstPosition, names.length).split(' ');
      }
      if (language === 'ch') {
        newArr = lastPosition
          ? names.substring(firstPosition, lastPosition)
          : names.substring(firstPosition, names.length);
      }
      return newArr;
    };
    if (regEn.test(name)) {
      // 取英文
      let enArr = interceptName(name, regEn, 'en');
      let newEnName = [];
      if (enArr.length > 2) {
        enArr = [enArr[0], enArr[enArr.length - 1]];
      }
      enArr.map(item => newEnName.push(item.substr(0, 1).toUpperCase()));
      newEnName = newEnName.join('');
      return newEnName;
    }
    if (!regEn.test(name) && regCh.test(name)) {
      // 取中文
      const chArr = interceptName(name, regCh, 'ch');
      if (chArr.length > 2) return chArr.substring(1, 3);
      if (chArr.length < 3) return chArr;
    }
  }
  return '';
}

/**
 * 获取指定日期
 *
 * @param  {number}  0:今天 -1：昨天  1：明天 2：后天依次排序
 * @return {Date}
 */
export function getAppointedDate(count = 0) {
  const countN = Number(count);
  const now = new Date();
  now.setDate(now.getDate() + countN);
  return now;
}

export function setCookie(name, value, expiredays) {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  const expire = expiredays == null ? '' : `;expires=${exdate.toGMTString()}`;
  document.cookie = `${name}=${escape(value)}${expire}`;
}

export function getCookie(cookieName) {
  const allcookies = document.cookie;
  let cookiePos = allcookies.indexOf(cookieName);
  if (cookiePos !== -1) {
    cookiePos = cookiePos + cookieName.length + 1;
    let cookieEnd = allcookies.indexOf(';', cookiePos);

    if (cookieEnd === -1) {
      cookieEnd = allcookies.length;
    }
    return unescape(allcookies.substring(cookiePos, cookieEnd));
  }
  return null;
}

/**
 * 导出为excel
 *
 * @param  {obj}
 */
export function exportData(param) {
  const dlform = document.createElement('form');
  dlform.style = 'display:none;';
  dlform.method = 'post';
  dlform.action = param.url;
  dlform.target = 'id_iframe';
  const hdnFilePath = document.createElement('input');
  hdnFilePath.type = 'hidden';
  hdnFilePath.name = 'ftfexpformat';
  hdnFilePath.value = 'xlsx';
  dlform.appendChild(hdnFilePath);

  const hdnftfexpcfg = document.createElement('input');
  hdnftfexpcfg.type = 'hidden';
  hdnftfexpcfg.name = 'ftfexpcfg';
  hdnftfexpcfg.value = param.configstr;
  dlform.appendChild(hdnftfexpcfg);

  const hdnparam = document.createElement('input');
  hdnparam.type = 'hidden';
  hdnparam.name = 'param';
  hdnparam.value = param.data;
  dlform.appendChild(hdnparam);

  const token = loginSession.getToken();
  const currentUser = loginSession.getUser() || {};
  const { _csrf = {} } = currentUser;
  const csrfParameterName = _csrf.parameterName;
  if (csrfParameterName) {
    const tokenParam = document.createElement('input');
    tokenParam.type = 'hidden';
    tokenParam.name = csrfParameterName;
    tokenParam.value = token;
    dlform.appendChild(tokenParam);
  }

  document.body.appendChild(dlform);
  dlform.acceptCharset = 'UTF-8';
  dlform.submit();
  document.body.removeChild(dlform);
}
export function exportTable(param) {
  const paramN = param;
  paramN.asyn = true;
  const { fileName, colModel } = paramN;
  paramN.configstr = JSON.stringify({ fileName, columns: colModel });
  paramN.data = JSON.stringify(paramN.serviceParam || {});
  exportData(paramN);
}

// /userinfo/2144/id => ['/userinfo','/useinfo/2144,'/userindo/2144/id']
// eslint-disable-next-line import/prefer-default-export
export function urlToList(url) {
  const urllist = url.split('/').filter(i => i);
  return urllist.map((urlItem, index) => `/${urllist.slice(0, index + 1).join('/')}`);
}

export function transformFormat(format) {
  let formatN = format.toUpperCase();
  formatN = formatN.replace('II', 'mm');
  formatN = formatN.replace('SS', 'ss');
  return formatN;
}

// 提供collapseMenu菜单方法给业务侧
export function collapseMenu() {
  const currentMenu = {
    url: window.location.hash.split('#')[1],
  };
  const mainMenu = {
    url: window.portal.defaultUrl,
  };
  return { mainMenu, currentMenu };
}

export function openMenu(url) {
  /* eslint-disable no-underscore-dangle */
  window.g_app._store.dispatch(routerRedux.push(url));
}

export function judgeIfContainCurrentRoute(pathname, routeData) {
  let routeAuthority = false;
  const getAuthority = (key, routes) => {
    routes.map(route => {
      const path = key.split('/')[1];
      if (route.path && route.path.toLowerCase().includes(path.toLowerCase())) {
        routeAuthority = true;
      } else if (route.routes) {
        routeAuthority = getAuthority(key, route.routes);
      }
      return route;
    });
    return routeAuthority;
  };
  return getAuthority(pathname, routeData);
}

export function tokenCountDown() {
  /* eslint-disable no-underscore-dangle */
  window.g_app._store
    .dispatch({
      type: 'global/getSessionTime',
    })
    .then(data => {
      delete window.openFlag;
      clearInterval(window.timeoutExit);
      const interval = Number(data.interval) || 30;
      const noticeTime = Number(data.noticeTime) || 100;
      window.openFlag = true;
      window.timeoutExit = setInterval(() => {
        window.g_app._store
          .dispatch({
            type: 'global/getSessionRemineTime',
          })
          .then(remineTime => {
            let routePath = window.location.hash.includes('/')
              ? window.location.hash.split('/')[1]
              : '';
            routePath = routePath.includes('?') ? routePath.split('?')[0] : routePath;
            if (routePath === 'userLogin') return;
            if (routePath === 'login') return;

            if (remineTime <= noticeTime && window.openFlag) {
              window.openFlag = false;
              // 弹出对话框提醒
              let secondsToGo = remineTime;
              const modal = Modal.warning({
                width: 650,
                title: formatMessage({ id: 'SYSTEM_AUTO_LOGOUT' }),
                content: `${formatMessage({
                  id: 'SYSTEM_SESSION_EXPIRE_NOTICE',
                })} ${secondsToGo} ${formatMessage({ id: 'SECONDS' })}.`,
                okText: formatMessage({ id: 'SYSTEM_SESSION_CONTINUE' }),
                onOk: () => {
                  window.g_app._store
                    .dispatch({
                      type: 'global/refreshSessionTime',
                    })
                    .then(() => {
                      window.openFlag = true;
                      clearInterval(window.countdown);
                      clearTimeout(window.exit);
                    });
                },
              });
              window.countdown = setInterval(() => {
                secondsToGo -= 1;
                modal.update({
                  content: `${formatMessage({
                    id: 'SYSTEM_SESSION_EXPIRE_NOTICE',
                  })} ${secondsToGo} ${formatMessage({ id: 'SECONDS' })}.`,
                });
              }, 1000);
              window.exit = setTimeout(() => {
                modal.destroy();
                delete window.openFlag;
                clearInterval(window.timeoutExit);
                clearInterval(window.countdown);
                clearTimeout(window.exit);
                window.g_app._store.dispatch({
                  type: 'login/logout',
                });
              }, secondsToGo * 1000);
            }
          });
      }, interval * 1000);
    });
}

// resize
/**
 * 获取页面page高度
 * @param  {string} 页面样式类前缀
 */
export function pageHeight(themePrefix = prefix) {
  const screenHeight = document.documentElement.clientHeight;
  const header = document.querySelector(`.${themePrefix}-layout-header`);
  const defaultHeaderHeight = 64;
  const headerHeight = header ? header.offsetHeight : defaultHeaderHeight;
  const mainContent = document.querySelector('.main-layout-content');
  const mainContentHeight = mainContent
    ? screenHeight - headerHeight - 2 * parseInt(window.getComputedStyle(mainContent).marginTop, 10)
    : 0;
  // 如果多页签模式有高度，不是高度就是0
  const multiTabBar = document.querySelector(
    `.main-layout-content > .${themePrefix}-tabs > .${themePrefix}-tabs-bar`,
  );
  const multiTabBarHeight = multiTabBar
    ? multiTabBar.offsetHeight + parseInt(window.getComputedStyle(multiTabBar).marginBottom, 10)
    : 0;
  const multiTabPane = document.querySelector(
    `.main-layout-content > .${themePrefix}-tabs > .${themePrefix}-tabs-content > .${themePrefix}-tabs-tabpane-active`,
  );
  const multiTabPanePadding = multiTabPane
    ? 2 * parseInt(window.getComputedStyle(multiTabPane).paddingTop, 10)
    : 0;
  const multiTabConCalcHeight = multiTabBar ? 1 : 0;
  const bodyHeight =
    mainContentHeight - multiTabBarHeight - multiTabPanePadding - multiTabConCalcHeight;
  return bodyHeight;
}

/**
 * 获取表格sroll高度
 * @param  {string} 表格类名
 * @param  {number} 页面固定部分的高度,一般为card,form等,获取方式document.querySelector().offsetHeight
 * @param  {string} 页面样式类前缀

 */
export function gridIncHeight(gridClassName, fixedHeight = 0, themePrefix = prefix) {
  const getPageHeight = pageHeight();
  const tableTitle = document.querySelector(`.${gridClassName} .${themePrefix}-table-title`);
  const tableTitleHeight = tableTitle ? tableTitle.offsetHeight : 0;
  const tableHeader = document.querySelector(`.${gridClassName} thead`);
  const tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 0;
  const defaultType = document.querySelector(`.${gridClassName} .${themePrefix}-table-default`);
  const calcTableHeader = defaultType ? 0 : 1;
  const tableBorder = document.querySelector(`.${gridClassName} .${themePrefix}-table-wrapper`);
  let calcTableborder;
  const explorer = window.navigator.userAgent;
  calcTableborder = tableBorder
    ? 2 * parseInt(window.getComputedStyle(tableBorder).borderWidth, 10)
    : 0;
  if (explorer.indexOf('Trident') > -1) {
    calcTableborder = tableBorder ? 2 * parseInt(tableBorder.currentStyle.borderWidth, 10) : 0;
  }
  if (explorer.indexOf('Edge') > -1) {
    calcTableborder = 2;
  }
  const scrollHeight =
    getPageHeight -
    fixedHeight -
    tableTitleHeight -
    tableHeaderHeight -
    calcTableHeader -
    calcTableborder;
  return scrollHeight;
}

/**
 * 获取分页表格行数pagesize
 * @param  {string} 表格类名
 * @param  {number} 页面固定部分的高度,一般为card,form等,获取方式document.querySelector().offsetHeight
 * @param  {string} 页面样式类前缀
 */
export function gridPageSize(gridClassName, fixedHeight = 0, themePrefix = prefix) {
  const incHeight = gridIncHeight(gridClassName, fixedHeight, themePrefix);
  // table row height
  const getTable = document.querySelector(`.${gridClassName}`);
  const tableRow = getTable ? getTable.getElementsByClassName(`${themePrefix}-table-row`) : '';
  const defaultTableRowHeight = 38;
  const tableRowHeight = tableRow.length > 0 ? tableRow[0].offsetHeight : defaultTableRowHeight;
  // table pagination height
  const getTablePagination = document.querySelector(`.${gridClassName} .${themePrefix}-pagination`);
  const defaultPaginationHeight = 56;
  const tablePaginationHeight = getTablePagination
    ? getTablePagination.offsetHeight +
      2 * parseInt(window.getComputedStyle(getTablePagination).marginTop, 10)
    : defaultPaginationHeight;
  const pageSizeHeight = incHeight - tablePaginationHeight;
  let pageSize = parseInt(pageSizeHeight / tableRowHeight, 10);
  pageSize = pageSize > 0 ? pageSize : 1;
  return pageSize;
}

/**
 * 获取原iconfont的type,以适合SVG的type
 * @param  {string} 类名
 */
export function getIconType(iconClassName) {
  const startPoint = iconClassName.indexOf('icon-');
  const endPoint = iconClassName.length;
  return iconClassName.substring(startPoint < 0 ? 0 : startPoint + 5, endPoint);
}

/**
 * 设置lang  cookie和localStorage
 * @param  {string} 前端存储的值
 */
export function setLang(umiLang) {
  setLocale(umiLang);
  const portalLocalKey = umiLang.indexOf('-') > -1 ? umiLang.split('-')[0] : umiLang;
  setCookie('ZSMART_LOCALE', portalLocalKey, 30);
}

// 动态数组合并
export const mergeArray = (arrA = [], arrB = []) => {
  const arr = arrA;
  arr.forEach(() => {
    arrB.forEach(itemB => {
      const index = arr.findIndex(item => item.attrCode === itemB.attrCode);
      if (index === -1) {
        arr.push(itemB);
      } else {
        arr[index] = itemB;
      }
    });
  });
  return arr;
};

// 通用下载 url = `${filePath}/${fileName}`
export const privateDownload = url => {
  const src = `${PRC_UPLOAD_HEADER_API}/downloadCloudDisk?fileName=${encodeURIComponent(url)}`;
  let exportFrame = null;
  if (!document.getElementById('frameHiddesenForExport')) {
    exportFrame = document.createElement('IFRAME');
    exportFrame.id = 'frameHiddesenForExport';
    exportFrame.style.display = 'none';
  } else {
    exportFrame = document.getElementById('frameHiddesenForExport');
  }

  exportFrame.src = src;
  document.body.appendChild(exportFrame);
};

// 科学计数法转数值字符串
export function toNonExponential(num) {
  const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
}

export function formatFloat(value, n) {
  const a = Number(n);
  // eslint-disable-next-line no-restricted-properties
  const f = Math.round(value * Math.pow(10, a)) / Math.pow(10, a);
  let s = f.toString();
  const rs = s.indexOf('.');
  if (rs < 0) {
    s += '.';
  }
  // eslint-disable-next-line no-plusplus
  for (let i = s.length - s.indexOf('.'); i <= a; i++) {
    s += '0';
  }
  return s;
}

// 结合上传组件重新组合后台需要的对象数据、多选
export function getAttachPaths(values) {
  const attachs = values.map(item => {
    const attachName = get(item, 'response.data.value[0].fileName');
    const path = get(item, 'response.data.value[0].filePath');
    return {
      attachName,
      path,
    };
  });
  return attachs;
}

export function hasSelectedValue(obj, value) {
  return Object.values(obj).some(item => item === value);
}

// 用于接口没返回数据时的空展示
export function formatText(value = FORMAT_DEFAULT_STRING) {
  if (value === null || value === '' || value === 'Invalid date') {
    return FORMAT_DEFAULT_STRING;
  }
  return value;
}

// 用于接口返回金钱类绝对值展示
export function formaAbs(value = FORMAT_DEFAULT_STRING) {
  if (value === null || value === '' || value === 'Invalid date') {
    return FORMAT_DEFAULT_STRING;
  }
  const str = value.replace(/-/g, '');
  return str;
}

// 用于关闭当前页面
export function closeWebPage() {
  if (navigator.userAgent.indexOf('MSIE') > 0) {
    // IE
    if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
      // IE6
      window.opener = null;
      window.close();
    } else {
      // IE6+
      window.open('', '_top');
      window.top.close();
    }
  } else if (
    navigator.userAgent.indexOf('Firefox') > 0 ||
    navigator.userAgent.indexOf('Presto') > 0
  ) {
    // FF和Opera
    window.location.href = 'about:blank';
    window.close(); // 火狐默认状态非window.open的页面window.close是无效的
  } else {
    window.opener = null;
    window.open('', '_self', '');
    window.close();
  }
}

// 查询配置的 offer 详情
export const getOfferValueByAttrCode = ({ attrCode, offerAttrDtoList = [] }) => {
  const index = offerAttrDtoList.findIndex(item => item.attrCode === attrCode);
  if (index === -1) {
    return null;
  }
  const value = offerAttrDtoList[index].defaultValue;
  const attrIndex = offerAttrDtoList.findIndex(item => item.attrCode === attrCode);
  if (attrIndex === -1) {
    return value;
  }
  const { prcAttrDto = {} } = offerAttrDtoList[attrIndex];
  const { inputType } = prcAttrDto;
  const prcAttrValDtos = get(prcAttrDto, 'prcAttrValDtos') || [];
  switch (inputType) {
    case 'A':
      // () => <Input placeholder="Please input" disabled={editable === 'N'} />
      return value;
    case 'D':
      //  () => <DatePicker placeholder="Please Select" showTime disabled={editable === 'N'} />
      return value;
    case 'L':
      //  () => <Input placeholder="Please input" disabled={editable === 'N'} />
      return value;
    case 'M':
      //  () => (
      //     <Select placeholder="Please select" disabled={editable === 'N'} mode="multiple">
      //       {prcAttrValDtos &&
      //         prcAttrValDtos.map((item, index) => {
      //           const key = index;
      //           const { valueMark, value } = item;
      //           return (
      //             <Option value={`${value}`} key={key}>
      //               {valueMark || value}
      //             </Option>
      //           );
      //         })}
      //     </Select>
      //   )
      const vv = value.split(',');
      const ob = [];
      vv.forEach(ii => {
        for (let m = 0; m < prcAttrValDtos.length; m++) {
          if (ii === prcAttrValDtos[m].value) {
            ob.push(prcAttrValDtos[m].valueMark);
            break;
          }
        }
      });
      return ob.toString();
    case 'O':
      //  () => <TextArea placeholder="Please input" disabled={editable === 'N'} rows={3} />
      return value;
    case 'P':
      //  () => <Input placeholder="Please input" disabled={editable === 'N'} />
      // case  'R': () => <Switch disabled={editable === 'N'} />
      return value;
    case 'S':
      //    () => (
      //   <Select placeholder="Please select" disabled={editable === 'N'}>
      //     {prcAttrValDtos &&
      //       prcAttrValDtos.map((item, index) => {
      //         const key = index;
      //         const { valueMark, value } = item;
      //         return (
      //           <Option value={`${value}`} key={key}>
      //             {valueMark || value}
      //           </Option>
      //         );
      //       })}
      //   </Select>
      // )
      // eslint-disable-next-line no-case-declarations
      const prcAttrIndexS = prcAttrValDtos.findIndex(item => item.value === value);
      if (prcAttrIndexS === -1) {
        return null;
      }
      return prcAttrValDtos[prcAttrIndexS].valueMark;
    case 'T':
      //  () => <Input placeholder="Please input" disabled={editable === 'N'} />
      return value;
    case 'Z':
      // () => <Input placeholder="Please input" disabled={editable === 'N'} />
      return value;
    default:
  }
  return null;
};
