import { stringify } from 'qs';
import request from '@/utils/request';
import { NGPORTAL_HEADER_API, PRC_HEADER_API } from '@/ecareDefaultSettings';

const rwsUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://dev.c85eaf0d05d04465a81befded3f4f608b.cn-shenzhen.alicontainer.com'
    : window.location.origin;

export function localMac() {
  return request('http://localhost:19999/localMac', {
    method: 'GET',
    headers: {
      'Content-Type': 'text/json',
    },
  });
}

export function login(params) {
  return request(
    `${NGPORTAL_HEADER_API}/login`,
    {
      method: 'POST',
      body: stringify(params),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    },
    false,
  );
}

// 获取倒计时
export function getVerifyEnabled() {
  return request(`${NGPORTAL_HEADER_API}/prod/verificationandsmscode/enabled`, {
    method: 'GET',
  });
}

// 获取验证码
export function getVerifyCode(data) {
  return request(`${NGPORTAL_HEADER_API}/prod/genCaptcha`, {
    method: 'POST',
    body: data,
  });
}

export function changeUserPwd(params) {
  return request(`${NGPORTAL_HEADER_API}/prod/users/self/pwd`, {
    method: 'PATCH',
    body: params,
  });
}

export function logout() {
  return request(`${NGPORTAL_HEADER_API}/logout`, {});
}

export function logged() {
  return request(`${NGPORTAL_HEADER_API}/logged`, {});
}

// 忘记密码：重置密码前检查用户信息
export function checkUserInfo(params) {
  return request(`${PRC_HEADER_API}/checkVerificationCodeForgetPwd`, {
    method: 'POST',
    body: params,
  });
}

export function current(param) {
  // const param = {
  //   menuId: '-2',
  // };
  return request(`${NGPORTAL_HEADER_API}/menus/current`, {
    method: 'POST',
    body: param,
  });
}

export function querySysparams() {
  return request(`${NGPORTAL_HEADER_API}/sysparams/common`);
}

export function qryCurrentSecurityRule() {
  return request(`${NGPORTAL_HEADER_API}/sysparams/securityrules/current`);
}

export function queryAdmin() {
  return request(`${NGPORTAL_HEADER_API}/users/admin`);
}

export function queryUserPortals(param) {
  return request(`${NGPORTAL_HEADER_API}/users/${param.userId}/portals`);
}

export function queryCurrent() {
  return request(`${NGPORTAL_HEADER_API}/users/current`);
}

export function queryMenuUrl() {
  return request(`${NGPORTAL_HEADER_API}/menus/url?url=`);
}

export function queryLocal() {
  return request(`${NGPORTAL_HEADER_API}/local`, {});
}

export function queryLangs() {
  return request(`${NGPORTAL_HEADER_API}/languages`, {});
}

export function searchCustomer(params) {
  return request(`${rwsUrl}/rwscxm/api/v1/customer/profile/get_customers_list`, {
    method: 'POST',
    body: params,
    headers: { Authorization: 'fCm7Pc1OXg7XXWPW4DUqO3s2fa8ObSX2' },
  });
}

// http://120.77.229.212/rwscxm/  本地开发url
export function fetchFavorites(params) {
  return request(`${rwsUrl}/rwscxm/api/v1/user/favorite/favorite_customers?${stringify(params)}`, {
    headers: { Authorization: 'fCm7Pc1OXg7XXWPW4DUqO3s2fa8ObSX2' },
  });
}

export function getInterval() {
  return request(`${NGPORTAL_HEADER_API}/online/timeout/interval`);
}

export function getRemainingTime() {
  return request(`${NGPORTAL_HEADER_API}/online/timeout/refresh`);
}

export function getNoticeTime() {
  return request(`${NGPORTAL_HEADER_API}/online/timeout/t1`);
}

export function recoverSession() {
  return request(`${NGPORTAL_HEADER_API}/online/refreshAccTime`);
}

export function addComponentPrivilege(method, params) {
  return request(`${NGPORTAL_HEADER_API}/components`, {
    method,
    body: params,
  });
}

export function checkComponentPrivilege(params) {
  return request(`${NGPORTAL_HEADER_API}/components/checked?${stringify(params)}`);
}

export function getMenuComponentPrivs(menuId) {
  return request(`${NGPORTAL_HEADER_API}/menus/${menuId}/out/components`);
}

export function delComponentPrivs(privId) {
  return request(`${NGPORTAL_HEADER_API}/components/${privId}`, {
    method: 'DELETE',
  });
}

export function getConfig(params) {
  return request(`${NGPORTAL_HEADER_API}/sysparams/?${stringify(params)}`);
}

export function getMagicCode(params) {
  return request(`${NGPORTAL_HEADER_API}/magiccode`, {
    method: 'POST',
    body: params,
    headers: {
      Authorization: `Basic ${btoa(`${params.targetUserCode}:${params.password}`)}`,
    },
  });
}

// 为用户新增默认角色权限
export function grantDefaultRole(userId, params) {
  return request(`${NGPORTAL_HEADER_API}/users/${userId}/roles`, {
    method: 'POST',
    body: params,
  });
}

export function queryUserRoles(userId) {
  return request(`${NGPORTAL_HEADER_API}/users/${userId}/roles`);
}

export function getVerifyAndSmsCodeEnabled() {
  return request(`${NGPORTAL_HEADER_API}/verificationandsmscode/enabled`);
}

export function getPartnerType(params) {
  return request(`${PRC_HEADER_API}/domains?${stringify(params)}`, {
    method: 'GET',
  });
}

// 修改接口
export function modServiceState(params) {
  return request(`${PRC_HEADER_API}/partnerOfferInst/batchModOfferInstState`, {
    method: 'POST',
    body: params,
  });
}

export function modSingleServiceState(params) {
  return request(`${PRC_HEADER_API}/partnerOfferInst/modifyState`, {
    method: 'POST',
    body: params,
  });
}

export function modPartnerState(params) {
  return request(`${PRC_HEADER_API}/modPartnerState`, {
    method: 'POST',
    body: params,
  });
}

export function qryAddrAttrValueList(params) {
  return request(`${PRC_HEADER_API}/qryAddrAttrValueList?${stringify(params)}`, {
    method: 'GET',
  });
}

export function qryPostAddrList(params) {
  return request(`${PRC_HEADER_API}/qryPostAddrList?${stringify(params)}`, {
    method: 'GET',
  });
}

export function prcConfigItemParam() {
  return request(`${PRC_HEADER_API}/prcConfigItemParam/PRC_ATTACH_MANDATORY_EVENT_TYPES`, {
    method: 'GET',
  });
}

export function checkOngoingOrder4LifeCycle(params) {
  return request(`${PRC_HEADER_API}/checkOngoingOrder4LifeCycle`, {
    method: 'POST',
    body: params,
  });
}

export function prcPartnerProfile() {
  return request(`${PRC_HEADER_API}/prcPartnerProfile`, {
    method: 'GET',
  });
}

export async function prcPartnerType() {
  return request(`${PRC_HEADER_API}/prcPartnerType`, {
    method: 'GET',
  });
}

// 附件允许上传大小
export function getUploadFileSize(params) {
  return request(`${PRC_HEADER_API}/prcConfigItemParam/${params.paramCode}`);
}
// 附件允许上传类型
export function getUploadFileType(params) {
  return request(`${PRC_HEADER_API}/prcConfigItemParam/${params.paramCode}`);
}

// 登录前，允许上传附件大小查询
export function getUploadFileSizePre(params) {
  return request(`${PRC_HEADER_API}/configItemParamWithoutSession/${params.paramCode}`);
}

// 登录前，允许上传附件类型查询
export function getUploadFileTypePre(params) {
  return request(`${PRC_HEADER_API}/configItemParamWithoutSession/${params.paramCode}`);
}

export function serviceLifeCyclePreCheck4UM(params) {
  return request(
    `${PRC_HEADER_API}/partnerOfferInst/serviceLifeCyclePreCheck4UM?${stringify(params)}`,
    {
      method: 'GET',
    },
  );
}

export function qryMcpPartner() {
  return request(`${PRC_HEADER_API}/confirmMcp`, {
    method: 'GET',
  });
}

export function getBankListByBankCountryCode(params) {
  return request(`${PRC_HEADER_API}/bankList/${params.bankCountryCode}`, {
    method: 'GET',
  });
}

export function getOrderBankList() {
  return request(`${PRC_HEADER_API}/orderBankCountry`, {
    method: 'GET',
  });
}

/**
 * PRC 获取配置项接口
 * @param {paramCode} params
 * @returns
 */
export function getPrcConfigParam(params) {
  return request(`${PRC_HEADER_API}/prcConfigItemParam/${params.configCode}`);
}

export function refreshImgCode(params) {
  return request(`${PRC_HEADER_API}/refreshImgCodeForgetPwd`, {
    method: 'POST',
    body: params,
  });
}

// 判断是否需要图形验证码
export function isCaptchaNeeded() {
  return request(`${NGPORTAL_HEADER_API}/verificationcode/enabled`, {
    method: 'GET',
  });
}

// 获取登录图形验证码
export function getCaptcha() {
  return request(`${NGPORTAL_HEADER_API}/verificationCode?${new Date().getTime()}`, {
    method: 'GET',
    responseType: 'arraybuffer',
  });
}

// 获取当前staff
export function getCurrentStaff(token) {
  return request(`${NGPORTAL_HEADER_API}/stafforg/staff/current`, {
    method: 'POST',
    headers: {
      'X-CSRF-TOKEN': token,
    },
  });
}

// 查询岗位信息
export function getStaffJob() {
  return request(`${NGPORTAL_HEADER_API}/stafforg/staffs/self/orgjobs`, {
    method: 'GET',
  });
}

// 获取当前staff岗位
export function getCurrentPosition(data) {
  return request(`${NGPORTAL_HEADER_API}/stafforg/staffjob/current`, {
    method: 'POST',
    body: data, // 接口需要body形式
  });
}

export function getContactInfo() {
  return request('/config/index.json', {
    method: 'get',
  });
}
