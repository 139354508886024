'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _en_US = require('rc-pagination/lib/locale/en_US');

var _en_US2 = _interopRequireDefault(_en_US);

var _en_US3 = require('../date-picker/locale/en_US');

var _en_US4 = _interopRequireDefault(_en_US3);

var _en_US5 = require('../time-picker/locale/en_US');

var _en_US6 = _interopRequireDefault(_en_US5);

var _en_US7 = require('../calendar/locale/en_US');

var _en_US8 = _interopRequireDefault(_en_US7);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

exports['default'] = {
    locale: 'en',
    Pagination: _en_US2['default'],
    DatePicker: _en_US4['default'],
    TimePicker: _en_US6['default'],
    Calendar: _en_US8['default'],
    global: {
        placeholder: 'Please select'
    },
    Table: {
        filterTitle: 'Filter menu',
        filterConfirm: 'OK',
        filterReset: 'Reset',
        selectAll: 'Select current page',
        selectInvert: 'Invert current page',
        sortTitle: 'Sort',
        expand: 'Expand row',
        collapse: 'Collapse row'
    },
    Modal: {
        okText: 'OK',
        cancelText: 'Cancel',
        justOkText: 'OK'
    },
    Popconfirm: {
        okText: 'OK',
        cancelText: 'Cancel'
    },
    Transfer: {
        titles: ['', ''],
        searchPlaceholder: 'Search here',
        itemUnit: 'item',
        itemsUnit: 'items'
    },
    Upload: {
        uploading: 'Uploading...',
        removeFile: 'Remove file',
        uploadError: 'Upload error',
        previewFile: 'Preview file',
        downloadFile: 'Download file'
    },
    Empty: {
        description: 'No Data'
    },
    Icon: {
        icon: 'icon'
    },
    Text: {
        edit: 'Edit',
        copy: 'Copy',
        copied: 'Copied',
        expand: 'Expand'
    },
    PageHeader: {
        back: 'Back'
    },
    ImageViewer: {
        previousOne: 'Previous',
        nextOne: 'Next',
        zoomIn: 'Zoom in',
        zoomOut: 'Zoom out'
    },
    DynamicAttribute: {
        upload: 'Upload',
        clickToUpload: 'Click to Upload'
    },
    VideoPlayer: {
        name: 'en_US',
        content: {
            "OD": "OD",
            "FD": "360p",
            "LD": "540p",
            "SD": "720p",
            "HD": "1080p",
            "2K": "2K",
            "4K": "4K",
            "FHD": "FHD",
            "XLD": "XLD",
            "Speed": "Speed",
            "Speed_05X_Text": "0.5X",
            "Speed_1X_Text": "Normal",
            "Speed_125X_Text": "1.25X",
            "Speed_15X_Text": "1.5X",
            "Speed_2X_Text": "2X",
            "Quality_Change_Fail_Switch_Text": "Cannot play, switch to ",
            "Quality_Change_Text": "Switch to ",
            "Quality_The_Url": "The url",
            "Refresh_Text": "Refresh",
            "Detection_Text": "Diagnosis",
            "Cancel": "Cancel",
            "Play_DateTime": "Time",
            "AutoPlayDelayDisplayText": "Play in $$ seconds",
            "Error_Load_Abort_Text": "Data abort erro",
            "Error_Network_Text": "Loading failed due to network error",
            "Error_Decode_Text": "Decode error",
            "Error_Server_Network_NotSupport_Text": "Network error or  the format of video is unsupported",
            "Error_Offline_Text": "The network is unreachable, please click Refresh",
            "Error_Play_Text": "Error occured while playing",
            "Error_Retry_Text": " Please close or refresh",
            "Error_AuthKey_Text": "Authentication expired or the domain is not in white list",
            "Error_H5_Not_Support_Text": "The format of video is not supported by h5 player，please use flash player",
            "Error_Not_Support_M3U8_Text": "The format of m3u8 is not supported by this explorer",
            "Error_Not_Support_MP4_Text": "The format of mp4 is not supported by this explorer",
            "Error_Not_Support_encrypt_Text": "The encrypted video is not supported by h5 player,please set useFlashPrism to true",
            "Error_Vod_URL_Is_Empty_Text": "The url is empty",
            "Error_Vod_Fetch_Urls_Text": "Error occured when fetch urls，please close or refresh",
            "Fetch_Playauth_Error": "Error occured when fetch playauth close or refresh",
            "Error_Playauth_Decode_Text": "PlayAuth parse failed",
            "Error_Vid_Not_Same_Text": "Cannot renew url due to vid changed",
            "Error_Playauth_Expired_Text": "Playauth expired, please close or refresh",
            "Error_MTS_Fetch_Urls_Text": "Error occurred while requesting mst server",
            "Error_Load_M3U8_Failed_Text": "The m3u8 file loaded failed",
            "Error_Load_M3U8_Timeout_Text": "Timeout error occored when the m3u8 file loaded",
            "Error_M3U8_Decode_Text": "The m3u8 file decoded failed",
            "Error_TX_Decode_Text": "Video decoded failed",
            "Error_Waiting_Timeout_Text": "Buffering timeout, please close or refresh",
            "Error_Invalidate_Source": "Invalid source",
            "Error_Fetch_NotStream": "The vid has no stream to play",
            "Error_Not_Found": "Url is not found",
            "Live_End": "Live has finished",
            "Play_Before_Fullscreen": "Please play before fullscreen",
            "Can_Not_Seekable": "Can not seek to this position",
            "Cancel_Text": "Cancel",
            "OK_Text": "OK",
            "Auto_Stream_Tip​​_Text": "Internet is slow, does switch to $$",
            "Request_Block_Text": "This request is blocked, the video Url should be over https",
            "Open_Html_By_File": "Html page should be on the server",
            "Maybe_Cors_Error": "please make sure enable cors,<a href='https://help.aliyun.com/document_detail/62950.html?spm=a2c4g.11186623.2.21.Y3n2oi' target='_blank'>refer to document</a>",
            "Speed_Switch_To": "Speed switch to ",
            "Curent_Volume": "Current volume: ",
            "Volume_Mute": "set to mute",
            "Volume_UnMute": "set to unmute",
            "ShiftLiveTime_Error": "Live start time should not be greater than over time",
            "Error_Not_Support_Format_On_Mobile": "flv、rmtp can't be supported on mobile，please use m3u8",
            "SessionId_Ticket_Invalid": "please assign value for sessionId and ticket properties",
            "Http_Error": " An HTTP network request failed with an error, but not from the server.",
            "Http_Timeout": "A network request timed out",
            "DRM_License_Expired": "DRM license is expired, please refresh",
            "Not_Support_DRM": "Browser doesn't support DRM",
            "CC_Switch_To": "Subtitle switch to ",
            "AudioTrack_Switch_To": "Audio tracks switch to ",
            "Subtitle": "Subtitle/CC",
            "AudioTrack": "Audio Track",
            "Quality": "Quality",
            "Auto": "Auto",
            "Quality_Switch_To": "Quality switch to ",
            "Setting": "Settings",
            "Volume": "Volume",
            "CloseSubtitle": "Close CC",
            "OpenSubtitle": "Open CC",
            "ExistFullScreen": "Exit Full Screen",
            "Muted": "Muted",
            "Retry": "Retry",
            "Refresh": "Refresh",
            "Diagnosis": "Diagnosis",
            "Play": "Play",
            "Pause": "Pause",
            "Snapshot": "Snapshot",
            "Replay": "Replay",
            "Live": "Live",
            "Encrypt": "Encrypt",
            "Sound": "Sound",
            "Fullscreen": "Fullscreen",
            "Exist_Fullscreen": "Exit Full Screen",
            "Next": "Next",
            "Brightness": "Brightness",
            "Default": "Default",
            "Contrast": "Contrast",
            "Skip_Titles": "Skip Titles",
            "Skip_Credits": "Skip Credits",
            "Not_Support_Out_Site": "Not Support Out Site",
            "Watch_Now": "Watch Now",
            "Caption_chinese": "中文",
            "Caption_english": "English",
            "Caption_japanese": "日本語",
            "Caption_off": "off",
            "Caption_ESP": "ESPAÑOL",
            "Caption_РУС": "РУССКИЙ",
            "Caption_POR": "PORTUGUÊS",
            "Caption_FRA": "FRANÇAIS",
            "Caption_LDL": "العربية",
            "Caption_DEU": "DEUTSCH",
            "Caption_HAN": "한국어",
            "Caption_ITA": "ITALIANO",
            "Caption": "Caption",
            "Track": "Track",
            "Track_ENG": "English",
            "Track_JPA": "日本語"
        }
    }
};
module.exports = exports['default'];