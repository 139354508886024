import { get } from 'lodash';
import * as services from '@/services/partnerService';

export default {
  namespace: 'partnerService',
  state: {
    eventTypeList: [
      { lookupName: 'Subscription', value: '1' },
      {
        lookupName: 'Onetime Charge',
        value: '2',
      },
    ],
    formItemList: [],
    serviceTypeList: [],
    serviceStateList: [
      {
        lookupName: 'Inactive',
        value: 'I',
      },
      {
        lookupName: 'Active',
        value: 'A',
      },
      {
        lookupName: 'Suspend',
        value: 'S',
      },
      {
        lookupName: 'Terminate',
        value: 'T',
      },
      {
        lookupName: 'Deactivate',
        value: 'D',
      },
      {
        lookupName: 'Delete',
        value: 'X',
      },
    ],
    classificationList: [],
    shortCodeList: [],
    platformPartnerList: [],
    offerList: [],
    offerDetailList: {},
    total: 0,
    successRecord: 0, // blockCharging成功条数
    failRecord: 0, // blockCharging失败条数
    fileAnalyzeCompleted: false,
    errorFileName: '', // blockCharging失败文件名
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询合作伙伴及其子伙伴的Offer列表
    *getOfferList({ payload }, { call, put }) {
      const response = yield call(services.getOfferList, payload);
      const { data: result } = response;
      if (result && result.code === 200) {
        yield put({
          type: 'updateState',
          payload: {
            offerList: get(result, 'data.list', []),
            total: get(result, 'data.pageInfo.total', 0),
          },
        });
      }
    },

    // 导出合作伙伴及其子伙伴的Offer列表：获取名字
    *exportOfferList({ payload }, { call }) {
      const response = yield call(services.exportOfferList, payload);
      const { data: result } = response;
      if (result && result.code === 200) {
        return result;
      }
      return null;
    },

    // 导出合作伙伴及其子伙伴的Offer列表：下载
    *downloadOfferList({ payload }, { call }) {
      const response = yield call(services.downloadOfferList, payload);
      const { data: result } = response;
      if (result) {
        const fileBlob = new Blob([result]);
        const blobUrl = window.URL.createObjectURL(fileBlob); // 创建下载的链接
        const a = document.createElement('a'); // 创建完成动作的元素
        a.style.display = 'none';
        a.download = payload.fileName; // 下载后的文件名
        document.body.appendChild(a); // 添加元素
        a.href = blobUrl;
        a.click();
        document.body.removeChild(a); // 下载完成移除元素
        window.URL.revokeObjectURL(blobUrl); // 释放掉blob对象
      }
      return null;
    },

    // 查询Offer详细信息
    *getOfferDetail({ payload }, { call, put }) {
      const response = yield call(services.getOfferDetail, payload);
      const { data: result } = response;
      if (result && result.code === 200) {
        yield put({
          type: 'updateState',
          payload: {
            offerDetailList: get(result, 'data', {}),
          },
        });
      }
    },

    // 获取Service Register中的ServiceType
    *getServiceType({ payload }, { call, put }) {
      const response = yield call(services.getServiceType, payload);
      const { data: result } = response;
      if (result && result.code === 200) {
        yield put({
          type: 'updateState',
          payload: {
            serviceTypeList: get(result, 'data', []),
          },
        });
      }
    },

    // 获取shortCode
    *getShortCode({ payload }, { call, put }) {
      const response = yield call(services.getShortCode, payload);
      const { data: result } = response;
      if (result && result.code === 200) {
        yield put({
          type: 'updateState',
          payload: {
            shortCodeList: get(result, 'data', []),
          },
        });
      }
    },

    // 获取Classification
    *getClassification({ payload }, { call, put }) {
      const response = yield call(services.getClassification, payload);
      const { data: result } = response;
      if (result && result.code === 200) {
        yield put({
          type: 'updateState',
          payload: {
            classificationList: get(result, 'data', []),
          },
        });
      }
    },

    // 动态获取表单列表
    *getFormItemList({ payload, fieldName }, { call, put, select }) {
      const response = yield call(services.getFormItemList, payload);
      const { data: result } = response;
      if (result && result.code === 200) {
        // 动态表单可能有多个，以数组形式存放，每个表单通过自己的fieldKey获取
        const formItemList = yield select(state => state.partnerService.formItemList);
        formItemList[fieldName] = get(result, 'data.bizObjAttrList', []);
        yield put({
          type: 'updateState',
          payload: {
            formItemList,
          },
        });
      }
    },

    // Platform Partner采集
    *getPlatformPartner({ payload }, { call, put }) {
      const { success, data } = yield call(services.getPlatformPartner, payload);
      if (success) {
        yield put({
          type: 'updateState',
          payload: {
            platformPartnerList: get(data, 'data', []),
          },
        });
      }
    },

    // 校验关键字合法性
    *checkKeywordLegality({ payload }, { call }) {
      const response = yield call(services.checkKeywordLegality, payload);
      const { data: result } = response;
      if (result && result.code === 200) {
        return result;
      }
      return null;
    },

    // 点击confirm注册
    *serviceRegister({ payload }, { call }) {
      const response = yield call(services.serviceRegister, payload);
      const { data: result } = response;
      if (result) {
        return result;
      }
      return null;
    },

    // 限制缴费：下载文件模板
    *templateDownload({ payload }, { call }) {
      const response = yield call(services.templateDownload, payload);
      const { data: result } = response;
      if (result) {
        const fileBlob = new Blob([result]);
        const blobUrl = window.URL.createObjectURL(fileBlob); // 创建下载的链接
        const a = document.createElement('a'); // 创建完成动作的元素
        a.style.display = 'none';
        a.download = 'File Template.xls'; // 下载后的文件名
        document.body.appendChild(a); // 添加元素
        a.href = blobUrl;
        a.click();
        document.body.removeChild(a); // 下载完成移除元素
        window.URL.revokeObjectURL(blobUrl); // 释放掉blob对象
      }
      return null;
    },

    // 限制缴费：确认
    *blockCharging({ payload }, { call }) {
      const { success, data } = yield call(services.blockCharging, payload);
      if (success) {
        return get(data, 'data');
      }
      return null;
    },

    // 间歇获取失败文件
    *getFailedFile({ payload, timer }, { call, put }) {
      const { success, data } = yield call(services.getFailedFile, payload);
      if (success) {
        const result = get(data, 'data');
        yield put({
          type: 'updateState',
          payload: {
            successRecord: result.successCount,
            failRecord: result.failCount,
          },
        });
        if (result && result.status === 'COMPLETED') {
          clearInterval(timer); // 清除定时器 - 间歇获取失败文件
          yield put({
            type: 'updateState',
            payload: {
              fileAnalyzeCompleted: true,
              errorFileName: result.errorFile,
            },
          });
          return result;
        }
        if (result && result.status === 'FAILED') {
          clearInterval(timer);
        }
      }
      return null;
    },

    // 下载失败文件
    *downloadFailedFile({ payload }, { call }) {
      const response = yield call(services.downloadOfferList, payload);
      const { data: result } = response;
      if (result) {
        const fileBlob = new Blob([result]);
        const blobUrl = window.URL.createObjectURL(fileBlob); // 创建下载的链接
        const a = document.createElement('a'); // 创建完成动作的元素
        a.style.display = 'none';
        a.download = payload.fileName; // 下载后的文件名
        document.body.appendChild(a); // 添加元素
        a.href = blobUrl;
        a.click();
        document.body.removeChild(a); // 下载完成移除元素
        window.URL.revokeObjectURL(blobUrl); // 释放掉blob对象
      }
    },
  },
};
