import { stringify } from 'qs';
import request from '@/utils/request';
import { PRC_HEADER_API } from '@/ecareDefaultSettings';

// 查询合作伙伴及其子伙伴的Offer列表
export async function getOfferList(data) {
  return request(`${PRC_HEADER_API}/queryOfferList`, {
    method: 'POST',
    body: data,
  });
}

// 导出合作伙伴及其子伙伴的Offer列表：获取名字
export async function exportOfferList(data) {
  return request(`${PRC_HEADER_API}/exportOfferList`, {
    method: 'POST',
    body: data,
  });
}

// 导出合作伙伴及其子伙伴的Offer列表：下载
export async function downloadOfferList(data) {
  return request(`${PRC_HEADER_API}/down/${data.fileName}`, {
    getResponse: true,
    responseType: 'blob',
  });
}

// 查询Offer详细信息
export async function getOfferDetail(data) {
  return request(`${PRC_HEADER_API}/queryOfferDetail/${data.offerId}`, {
    method: 'GET',
  });
}

// 获取serviceType
export async function getServiceType() {
  return request(`${PRC_HEADER_API}/qryServTypeByChannel`, {
    method: 'GET',
  });
}

// 获取shortCode
export async function getShortCode() {
  return request(`${PRC_HEADER_API}/shortcode`, {
    method: 'GET',
  });
}

// 获取Classification
export async function getClassification(data) {
  return request(`${PRC_HEADER_API}/domains?${stringify(data)}`, {
    method: 'GET',
  });
}

// 获取动态列表
export async function getFormItemList(data) {
  return request(
    `${PRC_HEADER_API}/getServiceAttrByServType/${data.subServTypeId}/${data.eventType}`,
    {
      method: 'GET',
    },
  );
}

// Platform Partner采集
export async function getPlatformPartner(data) {
  return request(`${PRC_HEADER_API}/qryPartnerPlatform?subServTypeId=${data.subServTypeId}`);
}

// 校验关键字合法性
export async function checkKeywordLegality(data) {
  return request(`${PRC_HEADER_API}/checkKeywordLegality/${data.Keyword}`, {
    method: 'GET',
  });
}

// 提交表单，服务注册
export async function serviceRegister(data) {
  return request(`${PRC_HEADER_API}/serviceRegister`, {
    method: 'POST',
    body: data,
  });
}

// 限制缴费：文件模板下载
export async function templateDownload(data) {
  return request(`${PRC_HEADER_API}/templateDownload?downloadType=${data.downloadType}`, {
    getResponse: true,
    responseType: 'blob',
  });
}

// 限制缴费：确认
export async function blockCharging(data) {
  return request(`${PRC_HEADER_API}/blockCharging`, {
    method: 'POST',
    body: data,
  });
}

// 限制缴费：失败处理
export async function getFailedFile(data) {
  return request(`${PRC_HEADER_API}/simpleFileBatch/${data.jobExecutionId}`);
}

// 查询MSICCode及其描述
export async function getMSICInfo(data) {
  return request(`${PRC_HEADER_API}/queryMSICInfo`, {
    method: 'POST',
    body: data,
  });
}
