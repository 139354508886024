import { message } from '@whalecloud/fdx';
import { formatMessage } from 'umi/locale';
import dynamic from 'umi/dynamic';
import {
  queryUserPortlets,
  setDefaultLayout,
  queryUserLastOper,
  queryUserLastLogin,
  queryUserFavorites,
  saveDashboardLayout,
  queryDashboardLayout,
} from '@/services/dashboard';

import { queryAdmin } from '@/services/api';

const loadjs = require('loadjs');

export default {
  namespace: 'dashboard',

  state: {
    lastOper: [],
    lastLogin: '',
    favorites: [],
    layoutId: null,
    allPortlets: [],
    currentLayout: null,
  },

  effects: {
    *qryAdmin(_, { call }) {
      const { data } = yield call(queryAdmin);
      return data;
    },
    *qryLastOper(_, { call, put }) {
      const { success, data } = yield call(queryUserLastOper);
      if (success) {
        yield put({
          type: 'save',
          payload: {
            lastOper: data,
          },
        });
      }
    },
    *qryLastLogin(_, { call, put }) {
      const { success, data } = yield call(queryUserLastLogin);
      if (success) {
        yield put({
          type: 'save',
          payload: {
            lastLogin: data,
          },
        });
      }
    },
    *qryFavorites({ payload }, { call, put }) {
      const { success, data } = yield call(queryUserFavorites, payload);
      if (success) {
        yield put({
          type: 'save',
          payload: {
            favorites: data,
          },
        });
      }
    },
    *qryPortlets({ payload }, { call, put }) {
      const { success, data } = yield call(queryUserPortlets, payload);
      if (success) {
        yield put({
          type: 'save',
          payload: {
            allPortlets: data.filter(v => v.state === 'A'),
          },
        });
      }
    },
    *remoteLoadComponent({ payload }) {
      const portlets = payload;
      /* eslint-disable no-plusplus */
      for (let index = 0; index < portlets.length; index++) {
        const item = portlets[index];
        if (item.url.includes('portalx-')) {
          // url中包含portalx-则认为是portalx提供的基本portlet，采用本地加载
          item.component = dynamic({
            loader: () => import(`@/pages/Dashboard/components/${item.url.split('-')[1]}`),
          });
        } else {
          yield loadjs([`${item.url}.js`, `${item.url}.css`], { returnPromise: true }).then(() => {
            item.component = window.g_umi.monorepo[0].routes[0].component;
            if (window.g_umi.monorepo[0].models.length > 0) {
              window.g_app.model(window.g_umi.monorepo[0].models[0]);
            }
          });
        }
      }
      return portlets;
    },
    *qryLayout({ payload }, { call, put }) {
      if (!payload) {
        return [];
      }
      const { success, data } = yield call(queryDashboardLayout, payload);
      if (success) {
        if (data.isDefault === 'Y') {
          const layout = JSON.parse(data.widgets);
          /* eslint-disable no-plusplus */
          for (let index = 0; index < layout.length; index++) {
            const item = layout[index];
            if (item.url) {
              if (item.url.includes('portalx-')) {
                // url中包含portalx-则认为是portalx提供的基本portlet，采用本地加载
                item.component = dynamic({
                  loader: () => import(`@/pages/Dashboard/components/${item.url.split('-')[1]}`),
                });
              } else {
                yield loadjs([`${item.url}.js`, `${item.url}.css`], {
                  returnPromise: true,
                }).then(() => {
                  item.component = window.g_umi.monorepo[0].routes[0].component;
                  if (window.g_umi.monorepo[0].models.length > 0) {
                    window.g_app.model(window.g_umi.monorepo[0].models[0]);
                  }
                });
              }
            }
          }
          yield put({
            type: 'save',
            payload: {
              currentLayout: data,
              layoutId: data.layoutId,
            },
          });
          return layout;
        }
        return [];
      }
      return [];
    },
    *saveLayout({ payload }, { call, put, select }) {
      const { layoutId } = yield select(state => state.dashboard);
      const { success, data } = yield call(saveDashboardLayout, payload);
      if (success) {
        message.success(formatMessage({ id: 'DASHBOARD_CUSTOMIZE_SUCCESS' }));
        if (data.layoutId && layoutId !== data.layoutId) {
          yield call(setDefaultLayout, { portalId: payload.portalId, LayoutId: data.layoutId });
          yield put({
            type: 'save',
            payload: {
              layoutId: data.layoutId,
            },
          });
        }
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
