const isDR = DR_ENV === 'DR';
module.exports = {
  NGPORTAL_HEADER_API: `${isDR ? '/partner' : ''}/ngportal`,
  PRC_HEADER_API: `${isDR ? '/partner' : ''}/prcSerlfserv/prc/selfserv`,
  PRC_UPLOAD_HEADER_API: '/partner/prcSerlfserv/prc/selfserv',
  PARTNER_EVENT_TYPE_TABLE_NAME: 'PRC_ORDER',
  PARTNER_EVENT_TYPE_COLUMN_NAME: 'EVENT_TYPE_ID',
  PARTNER_COMPANY_TYPE_TABLE_NAME: 'PRC_PARTNER',
  PARTNER_COMPANY_TYPE_COLUMN_NAME: 'COMPANY_TYPE',
  PARTNER_STATE_TABLE_NAME: 'PRC_PARTNER',
  PARTNER_STATE_COLUMN_NAME: 'PARTNER_STATE',
  PARTNER_CONTACT_TYPE_TABLE_NAME: 'PRC_CONTACT_MAN',
  PARTNER_CONTACT_TYPE_COLUMN_NAME: 'TYPE',
  PARTNER_ACCOUNT_TYPE_TABLE_NAME: 'PRC_PARTNER_ACCT',
  PARTNER_ACCOUNT_TYPE_COLUMN_NAME: 'ACCT_TYPE',
  PARTNER_CURRENCY_TYPE_TABLE_NAME: 'PRC_PARTNER_ACCT',
  PARTNER_CURRENCY_TYPE_COLUMN_NAME: 'CURRENCY_TYPE',
  OWNPROFILE_BASICINFO_ATTACHMENT_TABLE_DEFAULT_PAGE: 1,
  OWNPROFILE_BASICINFO_ATTACHMENT_TABLE_PAGESIZE: 5,
  NEW_PRC_Table_ID: 'NewPrcTableId',
  BASIC_INFO_TAB_KEY: '1',
  ACCOUNT_INFO_TAB_KEY: '2',
  STAFF_INFO_TAB_KEY: '3',
  ADD_NEW_ACCOUNT_TITLE: 'Add New Account',
  MODIFY_ACCOUNT_TITLE: 'Modify Account Information',
  ADD_NEW_BANK_TITLE: 'Add New Bank',
  MODIFY_BANK_TITLE: 'Modify Bank Information',
  ACCOUNT_INFO_FORM_KEYS: [
    'acctNbr',
    'acctName',
    'acctType',
    'billingCycleTypeId',
    'currencyType',
    'paymentTermId',
  ],
  UM_COUNTRY: 'UM_COUNTRY',
  UM_STATE: 'UM_STATE',
  UM_CITY: 'UM_CITY',
  STANDARD_ADDRESS: 'Enter Registered Address',
  CORRESPONDENCE_ADDRESS: 'Enter Correspondence Address',
  SUCCESS: 'Success.',
  ERROR: 'Error.',
  DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  MCP_PARTNER_DETAIL_BASICINFO_KEY: '1',
  MCP_PARTNER_DETAIL_CONTACTMAN_KEY: '2',
  MCP_PARTNER_DETAIL_ATTACHMENT_KEY: '3',
  FORMAT_DEFAULT_STRING: '-', // 默认展示
};
