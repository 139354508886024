import React from 'react';
import { ConfigProvider } from '@whalecloud/fdx';

const { themeConfig } = require('../config/defaultSettings.js');

export const dva = {
  config: {
    onError(err) {
      err.preventDefault();
      // eslint-disable-next-line no-console
      console.error(err.message);
    },
  },
};

export function patchRoutes(routes) {
  window.g_routes = routes;
  // console.log(window.g_routes);
}

export function render(oldRender) {
  oldRender();
}

export function rootContainer(container) {
  return <ConfigProvider prefixCls={themeConfig['@ant-prefix']}>{container}</ConfigProvider>;
}
