import { Modal } from 'antd';
import styles from './index.less';

function info(options) {
  Modal.info({
    className: styles.alertModal,
    okText: 'OK',
    centered: true,
    ...options,
  });
}

function success(options) {
  Modal.success({
    className: styles.alertModal,
    okText: 'OK',
    centered: true,
    ...options,
  });
}

function error(options) {
  Modal.error({
    className: styles.alertModal,
    okText: 'OK',
    centered: true,
    ...options,
  });
}

function warning(options) {
  Modal.warning({
    className: styles.alertModal,
    okText: 'OK',
    centered: true,
    ...options,
  });
}

function confirm(options) {
  // 根据视觉稿做了调整
  const { okText, cancelText, onOk, onCancel } = options;
  return Modal.confirm({
    ...options,
    className: styles.confirmModal,
    centered: true,
    width: 484,
    okText: cancelText,
    cancelText: okText,
    onOk: onCancel,
    onCancel: onOk,
  });
}

export default {
  info,
  success,
  error,
  warning,
  confirm,
};
