export const NODE_ACTIVITY_CODE = {
  PRC_PR_COMPLETE_SERV_DETAIL_BY_PARTNER: 'PRC_PR_COMPLETE_SERV_DETAIL_BY_PARTNER',
  PRC_PR_DOWNLOAD_NDA_BY_PARTNER: 'PRC_PR_DOWNLOAD_NDA_BY_PARTNER',
  PRC_PR_COMPLETE_BANK_INFO_BY_PARTNER: 'PRC_PR_COMPLETE_BANK_INFO_BY_PARTNER',
  PRC_PR_REVIEW_DRAFT_AGREEMENT_BY_PARTNER: 'PRC_PR_REVIEW_DRAFT_AGREEMENT_BY_PARTNER',
  PRC_PR_UPLOAD_NEW_DOCUMENTS_BY_PARTNER: 'PRC_PR_UPLOAD_NEW_DOCUMENTS_BY_PARTNER',
  PRC_PR_UPLOAD_DEPOSIT_PROOF_BY_PARTNER: 'PRC_PR_UPLOAD_DEPOSIT_PROOF_BY_PARTNER',
  PRC_PR_UPLOAD_SERV_DEPOSIT_PROOF_BY_PARTNER: 'PRC_PR_UPLOAD_SERV_DEPOSIT_PROOF_BY_PARTNER',
  PRC_PR_COMPLETE_TESTING_BY_PARTNER: 'PRC_PR_COMPLETE_TESTING_BY_PARTNER',
  PRC_PR_CONFIRM_LAUNCH_DATE_BY_PARTNER: 'PRC_PR_CONFIRM_LAUNCH_DATE_BY_PARTNER',
  PRC_SR_MCP_USER_APPROVAL: 'PRC_SR_MCP_USER_APPROVAL',
  PRC_SR_UPLOAD_NEW_DOCUMENTS_BY_PARTNER: 'PRC_SR_UPLOAD_NEW_DOCUMENTS_BY_PARTNER',
  PRC_SR_UPLOAD_DEPOSIT_PROOF_BY_PARTNER: 'PRC_SR_UPLOAD_DEPOSIT_PROOF_BY_PARTNER',
  PRC_SR_COMPLETE_TESTING_BY_PARTNER: 'PRC_SR_COMPLETE_TESTING_BY_PARTNER',
  PRC_SR_CONFIRM_LAUNCH_DATE_BY_PARTNER: 'PRC_SR_CONFIRM_LAUNCH_DATE_BY_PARTNER',
  PRC_AUDIT_RECONCILIATION_SELF: 'PRC_AUDIT_RECONCILIATION_SELF',
  PRC_AUDIT_RECONCILIATION: 'PRC_AUDIT_RECONCILIATION',
  PRC_AUDIT_INVOICE_SELF: 'PRC_AUDIT_INVOICE_SELF',
  PRC_AUDIT_INVOICE_BY_PASS_SELF: 'PRC_AUDIT_INVOICE_BY_PASS_SELF',
  CHE_SL_SUBMIT_INFO: 'CHE_SL_SUBMIT_INFO',
  CHG_PL_SUBMIT_INFO: 'CHG_PL_SUBMIT_INFO',
  PRC_AUDIT_PARTNER_RESUME: 'PRC_AUDIT_PARTNER_RESUME',
  PRC_AUDIT_PARTNER_SUSPEND: 'PRC_AUDIT_PARTNER_SUSPEND',
  PRC_AUDIT_PARTNER_TERMINATE: 'PRC_AUDIT_PARTNER_TERMINATE',
  PRC_AUDIT_SELF_PARTNER_RESUME: 'PRC_AUDIT_SELF_PARTNER_RESUME',
  PRC_AUDIT_SELF_PARTNER_SUSPEND: 'PRC_AUDIT_SELF_PARTNER_SUSPEND',
  PRC_AUDIT_SELF_PARTNER_TERMINATE: 'PRC_AUDIT_SELF_PARTNER_TERMINATE',
  PRC_AUDIT_SELF_SERVICE_RESUME: 'PRC_AUDIT_SELF_SERVICE_RESUME',
  PRC_AUDIT_SELF_SERVICE_SUSPEND: 'PRC_AUDIT_SELF_SERVICE_SUSPEND',
  PRC_AUDIT_SELF_SERVICE_TERMINATE: 'PRC_AUDIT_SELF_SERVICE_TERMINATE',
  PRC_AUDIT_SERVICE_RESUME: 'PRC_AUDIT_SERVICE_RESUME',
  PRC_AUDIT_SERVICE_SUSPEND: 'PRC_AUDIT_SERVICE_SUSPEND',
  PRC_AUDIT_SERVICE_TERMINATE: 'PRC_AUDIT_SERVICE_TERMINATE',
};

export const COMPLETESERVICEDETAIL_BYPARTNER_EVENTTYPE = {
  ONE_TIME_CHARGE: '2',
  SUBSCRIPTION: '1',
};

export const ORDERSERVSUBTYPE = {
  CPA_SERVICE: 'CPA Service',
  DCB_SERVICE: 'DCB Service',
};

export const SERVICE_TYPE = {
  CPA_SERVICE: 'CPA Service',
  DCB_SERVICE: 'DCB Service',
  BULK_SMS: 'Bulk SMS',
  DFS: 'DFS',
  DES: 'DES',
};

export const CLASSIFICATION = {
  SHORTCODE: 'H',
  KEYWORD: 'K',
};

export const WARNING_INFO = {
  SUCCESS: 'Success',
  ERROR: 'Error',
};

export const SELECT_TYPE_INFO = {
  PARTNER_EVENT_TYPE_TABLE_NAME: 'PRC_ORDER',
  PARTNER_EVENT_TYPE_COLUMN_NAME: 'EVENT_TYPE_ID',
  PARTNER_COMPANY_TYPE_TABLE_NAME: 'PRC_PARTNER',
  PARTNER_COMPANY_TYPE_COLUMN_NAME: 'COMPANY_TYPE',
  PARTNER_STATE_TABLE_NAME: 'PRC_PARTNER',
  PARTNER_STATE_COLUMN_NAME: 'PARTNER_STATE',
  PARTNER_CONTACT_TYPE_TABLE_NAME: 'PRC_CONTACT_MAN',
  PARTNER_CONTACT_TYPE_COLUMN_NAME: 'TYPE',
  PARTNER_ACCOUNT_TYPE_TABLE_NAME: 'PRC_PARTNER_ACCT',
  PARTNER_ACCOUNT_TYPE_COLUMN_NAME: 'ACCT_TYPE',
  PARTNER_CURRENCY_TYPE_TABLE_NAME: 'PRC_PARTNER_ACCT',
  PARTNER_CURRENCY_TYPE_COLUMN_NAME: 'CURRENCY_TYPE',
  SERVICE_REGISTER_CLASSIFICATION_TABLE_NAME: 'PRC_SERVICE',
  SERVICE_REGISTER_CLASSIFICATION_COLUMN_NAME: 'CLASSIFICATION',
  PARTNER_SERVICE_STATUS_TABLE_NAME: 'PRC_PARTNER_OFFER_INST',
  PARTNER_SERVICE_STATUS_COLUMN_NAME: 'STATE',
};

export const ADDRESS_UM_INFO = {
  UM_COUNTRY: 'UM_COUNTRY',
  UM_STATE: 'UM_STATE',
  UM_CITY: 'UM_CITY',
  STANDARD_ADDRESS: 'Enter Registered Address',
  CORRESPONDENCE_ADDRESS: 'Enter Correspondence Address',
};

export const PROFILE_INFO = {
  PARTNER_TERMINATE_EVENT_ID: 109,
  PARTNER_RESUME_EVENT_ID: 114,
  PARTNER_SUSPEND_EVENT_ID: 113,
  SERVICE_TERMINATE_EVENT_ID: 143,
  SERVICE_RESUME_EVENT_ID: 144,
  SERVICE_SUSPEND_EVENT_ID: 142,
  TERMINATE: 'TERMINATE',
  RESUME: 'RESUME',
  SUSPEND: 'SUSPEND',
};

export const ATTACHMENT_INFO = {
  PARTNER_MODULE_NAME: 'partnerLifecycleAttach',
  SERVICE_MODULE_NAME: 'serviceLifecycleAttach',
  PARTNER_PROFILE_MODULE_NAME: 'partnerAttach',
};

export const PARTNER_PROFILE_INFO = {
  BASIC_INFO_TAB_KEY: '1',
  ACCOUNT_INFO_TAB_KEY: '2',
  STAFF_INFO_TAB_KEY: '3',
  ADD_NEW_ACCOUNT_TITLE: 'Add New Account',
  MODIFY_ACCOUNT_TITLE: 'Modify Account Information',
  ADD_NEW_BANK_TITLE: 'Add New Bank',
  MODIFY_BANK_TITLE: 'Modify Bank Information',
  ACCOUNT_INFO_FORM_KEYS: [
    'acctNbr',
    'acctName',
    'acctType',
    'billingCycleTypeId',
    'currencyType',
    'paymentTermId',
  ],
};

export const MCP_PARTNER_INFO = {
  MCP_PARTNER_DETAIL_BASICINFO_KEY: '1',
  MCP_PARTNER_DETAIL_CONTACTMAN_KEY: '2',
  MCP_PARTNER_DETAIL_ATTACHMENT_KEY: '3',
};

export const LOGIN_INFO = {
  RETRIEVE_PASSWORD_TITLE: 'Forgot Password',
  CHANGE_PASSWORD_TITLE: 'Change Password',
  PASSWORD_VALIDATOR: /^(?=[~!@#$%^&*\-+=_.0-9a-zA-Z]*[~!@#$%^&*\-+=_.])(?=[~!@#$%^&*\-+=_.0-9a-zA-Z]*[0-9])(?=[~!@#$%^&*\-+=_.0-9a-zA-Z]*[a-z])(?=[~!@#$%^&*\-+=_.0-9a-zA-Z]*[A-Z])[~!@#$%^&*\-+=_.0-9a-zA-Z]{8,20}$/,
};

export const CURRENT_INFO = {
  DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  NEW_PRC_Table_ID: 'NewPrcTableId',
  TABLE_DEFAULT_PAGE: 1,
  TABLE_PAGESIZE: 10,
  PAGESIZE_OPTIONS: ['5', '10', '15'],
  DATE_FORMAT_YYYY_MM_DD: 'YYYY-MM-DD',
};

// T:T&C,N:NDA
export const AGREEMENT_TYPE = {
  T: 'T',
  N: 'N',
};

export const UPLOAD_FILE_LIMITATION = {
  OWN_PROFILE_SIZE: 'PARTNER_FILE_SIZE',
  OWN_PROFILE_TPYE: 'PARTNER_FILE_TYPE',
  SETTLEMENT_SIZE: 'SETTLEMENT_UOSFLOW_FILE_SIZE',
  SETTLEMENT_TYPE: 'SETTLEMENT_UOSFLOW_NORMAL_FILE_TYPE',
  SETTLEMENT_BILL_TYPE: 'SETTLEMENT_UOSFLOW_BILL_FILE_TYPE',
  CONTRACT_SIZE: 'CONTRACT_FILE_SIZE',
  CONTRACT_TYPE: 'CONTRACT_FILE_TYPE',
};

export const FILE_TYPE_MAP = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  mp3: 'audio/mpeg',
  mp4: 'audio/mp4, video/mp4',
  pdf: 'application/pdf',
  png: 'image/png',
  ppt: 'application/vnd.ms-powerpoint',
  txt: 'text/plain',
  zip: 'aplication/zip',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const E_MAIL_REGEXP = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

export const ORDER_ENTRY_WORK_ORDER_TYPE = [
  {
    title: 'Assign To Me',
    value: 'A1',
  },
  // {
  //   title: 'Assign To My Organization',
  //   value: 'A2',
  // },
  // {
  //   title: 'Assign To My Role',
  //   value: 'A3',
  // },
  {
    title: 'Processed Orders',
    value: 'B',
  },
  {
    title: 'My Created Orders',
    value: 'C',
  },
];

export const TRANSACTION_DETAIL = {
  PARTNER_ORDER_ID: 'Partner Order ID',
  ITEM_NAME: 'Item Name',
  SERVICE_URL: 'Service URL',
  CUSTOMER_CARE_NUMBER: 'Customer Care Number',
  CUSTOMER_CARE_EMAIL: 'Customer Care Email',
  SERVICE_CATEGORY: 'Service Category',
  SUBSCRIPTION_FREQUENCY: 'Subscription Frequency',
  CONTENT_FREQUENCY: 'Content Frequency',
  TONE_ID: 'Tone ID',
  SONG_TITLE: 'Song Title',
  ARTIST_NAME: 'Artist Name',
  USTORE_SERVICE_ID: 'U Store Service ID',
  TO_MSISDN: 'To MSISDN',
  TRANSFER_AMOUNT: 'Transfer Amount',
  LOAN_AMOUNT: 'Loan Amount',
  PAYMENT_AMOUNT: 'Payment Amount',
  OPEN_AMOUNT: 'Open Amount',
  COMM_AMOUNT: 'Service Fee',
  RET_COMM_AMOUNT: 'Payment Service Fee',
  REMAIN_COMM_AMOUNT: 'Open Service Fee',
};

export const PRC_AUDIT_TITLE = {
  RESUME_INFORMATION: 'Resume Information',
  SUSPEND_INFORMATION: 'Suspension Information',
  TERMINATION_INFORMATION: 'Termination Information',
  AUDIT_RESUME_INFORMATION: 'Approve Service Resume Information',
  AUDIT_SUSPEND_INFORMATION: 'Approve Service Suspension Information',
  AUDIT_TERMINATION_INFORMATION: 'Approve Service Termination Information',
};

export const TRACSACTION_AUTHENTICATION = {
  I: 'Controlled by FI',
  O: 'OTP',
  C: 'Captcha',
  B: 'OTP & Captcha',
  N: 'NA',
  S: 'SMS with URL and OTP',
  R: 'OTP replied to shortcode',
  D: 'DCB Customization',
  P: 'Controlled by partner',
  nodata: '-',
};

export const BANK_COUNTRY_CODE = {
  Malaysia: 'MYS',
};

export const FLAG_BANK_OTHER = 'Other';

export const FLAG_BANK_CHOOSE = {
  BANK_CURRENT: 'BANK_CURRENT',
  BANK_OTHER: 'BANK_OTHER',
};

export const DATA_PRIV_CODE = {
  PARTNER_BASIC_INFO: 'PARTNER_BASIC_INFO',
  PARTNER_BANK_INFO: 'PARTNER_BANK_INFO',
  PARTNER_STAFF_INFO: 'PARTNER_STAFF_INFO',
  PARTNER_SERVICE_INFO: 'PARTNER_SERVICE_INFO',
  PLATFORM_PARTNER_PROFILE: 'PLATFORM_PARTNER_PROFILE',
  PARTNER_TRANSACTION_QUERY: 'PARTNER_TRANSACTION_QUERY',
};

export const INQUIRY_TAB_PAGE = {
  TRANSACTION_QUERY_KEY: '/transaction',
  CUSTOMER_SUBSCRIPTION_KEY: '/customerSubscription',
};
