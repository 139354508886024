import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/blank',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../index'),
          LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
            .default,
        })
      : require('../index').default,
    exact: true,
  },
  {
    path: '/c',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/c/:paramCode?',
        name: 'customerConsent',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/pages/customerConsent/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () => import('../customerConsent'),
              LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                .default,
            })
          : require('../customerConsent').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/BlankLayout'),
          LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../../layouts'),
              LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts').default,
        routes: [
          {
            path: '/home',
            name: 'home',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../Home'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Home').default,
            exact: true,
          },
          {
            name: 'login',
            path: '/login',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../Login'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Login').default,
            exact: true,
          },
          {
            path: '/profile/mcp',
            name: 'MCP',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/pages/McpPartnerProfile/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../McpPartnerProfile'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../McpPartnerProfile').default,
            exact: true,
          },
          {
            path: '/profile/ownProfile',
            name: 'Own',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/pages/OwnPartnerProfile/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../OwnPartnerProfile'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../OwnPartnerProfile').default,
            exact: true,
          },
          {
            path: '/settlement',
            name: 'Settlement',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/pages/Settlement/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../Settlement'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Settlement').default,
            exact: true,
          },
          {
            path: '/service',
            name: 'service',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../Service'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Service').default,
            exact: true,
          },
          {
            path: '/onboardingProcess',
            name: 'onboardingprocess',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../OnboardingProcess'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../OnboardingProcess').default,
            exact: true,
          },
          {
            path: '/join/:type?',
            name: 'join',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../join'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../join').default,
            exact: true,
          },
          {
            path: '/partnerService',
            name: 'partnerService',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/pages/partnerService/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../partnerService'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../partnerService').default,
            exact: true,
          },
          {
            path: '/orderEntry',
            name: 'orderEntry',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/pages/OrderEntry/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../OrderEntry'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../OrderEntry').default,
            exact: true,
          },
          {
            path: '/inquiryTabsPage',
            name: 'InquiryTabsPage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../InquiryTabsPage'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../InquiryTabsPage').default,
            exact: true,
          },
          {
            path: '/customerBlockQuery',
            name: 'Customer Block Query',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/pages/CustomerBlockQuery/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../CustomerBlockQuery'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../CustomerBlockQuery').default,
            exact: true,
          },
          {
            path: '/report',
            name: 'Report',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../Report'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Report').default,
            exact: true,
          },
          {
            path: '/terms',
            name: 'Terms',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../TermsAndConditions'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../TermsAndConditions').default,
            exact: true,
          },
          {
            path: '/partnerCaseCreation',
            name: 'PartnerCaseCreation',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../PartnerCaseCreation'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../PartnerCaseCreation').default,
            exact: true,
          },
          {
            path: '/customerCaseCreation',
            name: 'CustomerCaseCreation',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../CustomerCaseCreation'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../CustomerCaseCreation').default,
            exact: true,
          },
          {
            path: '/allCaseInquiry',
            name: 'AllCaseInquiry',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../AllCaseInquiry'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../AllCaseInquiry').default,
            exact: true,
          },
          {
            path: '/taskCenter',
            name: 'TaskCenter',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../TaskCenter'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../TaskCenter').default,
            exact: true,
          },
          {
            path: '/distributionRule',
            name: 'Distribution Rule',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../DistributionRule'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../DistributionRule').default,
            exact: true,
          },
          {
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../404'),
                  LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                    .default,
                })
              : require('../404').default,
            exact: true,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../404'),
              LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../404'),
          LoadingComponent: require('/home/ZCIPClient/workpath/126775/code/prc/code/prc_selfcare_prod/src/components/PageLoading/index')
            .default,
        })
      : require('../404').default,
    exact: true,
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
