import { routerRedux } from 'dva/router';
import { get } from 'lodash';
import {
  localMac,
  login,
  logout,
  changeUserPwd,
  checkUserInfo,
  qryCurrentSecurityRule,
  getVerifyAndSmsCodeEnabled,
  refreshImgCode,
  isCaptchaNeeded,
  getCaptcha,
} from '@/services/api';
// eslint-disable-next-line import/no-unresolved
import loginSession from '@/utils/loginSession';
import { tokenCountDown, getPageQuery } from '@/utils/utils';
import qryMaintenance from '../pages/UserLogin/services/Login';

export default {
  namespace: 'login',

  state: {
    isSuccess: undefined,
    resetModal: false,
    needCaptcha: false,
    captchaUrl: '',
    securityRule: {},
    userMsg: {},
    maintenance: {
      state: 'X',
    },
    veriyfyConfig: {},
    userCode: '',
    imageInfo: {},
  },

  effects: {
    // 获取到客户端mac地址，存到全局变量
    *localMac(_, { call }) {
      const { success, data } = yield call(localMac);
      if (success) {
        const { macAddr = '' } = data;
        window.portal.macAddr = macAddr;
      }
    },

    *login({ payload, loginSuccess, loginFailed }, { call, put }) {
      const { success, data } = yield call(login, payload);
      if (success) {
        const { isSuccess } = data;
        if (isSuccess && isSuccess === 1) {
          if (loginSuccess) {
            loginSuccess();
            yield put({
              type: 'user/confirmMcp',
            });
          }
          yield put({
            type: 'changeLoginStatus',
            payload: {
              ...data,
              userCode: '',
            },
          });
          yield put({
            type: 'global/logged',
            payload: {
              from: 'Login',
              password: payload.password,
              redirect: getPageQuery().redirect,
            },
          });
          tokenCountDown();
          document.body.style.overflow = 'auto'; // 详看底部注释1
        }
      } else if (
        data.code === 'POT-ACCOUNT-00007' ||
        data.code === 'POT-LOGIN-00014' ||
        data.code === 'POT-LOGIN-00020'
      ) {
        // message.warning(`${errorMsg}, ${formatMessage({ id: 'CHANGE_PWD_MSG' })}`);
        // 首次登录 重置密码
        // 不符合安全规则 重置密码
        yield put({
          type: 'qryCurrentSecurityRule',
          payload,
        });
        yield put({
          type: 'save',
          payload: {
            userCode: payload.username,
          },
        });
      } else if (data.code === 'POT-LOGIN-00004' || (data.code === '46410029' && loginFailed)) {
        loginFailed(data.message);
      } else if (data.code === '46430045') {
        loginFailed(data.message);
      } else if (data.code === 'dynamicError') {
        loginFailed(data.message);
      } else if (data.code === 'POT-ACCOUNT-00002' && loginFailed) {
        loginFailed('The user is locked.');
      } else if (data.code === 'POT-ACCOUNT-00009') {
        loginFailed('The verification code error, please enter again.');
      } else if (data.code === 'POT-ACCOUNT-00004') {
        loginFailed(
          'The account is blocked, because the password input wrong has exceed retry limit.',
        );
      } else if (data.code === 'POT-ACCOUNT-00011') {
        loginFailed('The verification code error, Verification code are case sensitive.');
      } else if (loginFailed) {
        loginFailed('Fail to login selfservice system.');
      }
    },

    *changeUserPwd({ payload, changePwdsuccess, changePwderror }, { call, put }) {
      const { success, errorMsg } = yield call(changeUserPwd, payload);
      if (success) {
        // message.success(formatMessage({ id: 'CHANGE_PWD_SUCCESS' }));
        changePwdsuccess();
        yield put({
          type: 'closeModal',
        });
        yield put({
          type: 'login',
          payload: {
            username: payload.userCode,
            password: payload.newPwd,
          },
        });
      } else {
        changePwderror(errorMsg);
      }
    },

    *getVerifyAndSmsCodeEnabled(_, { call, put }) {
      const { success, data } = yield call(getVerifyAndSmsCodeEnabled);
      if (success) {
        yield put({
          type: 'save',
          payload: {
            veriyfyConfig: data,
          },
        });
      }
    },

    *qryCurrentSecurityRule({ payload }, { call, put }) {
      const { success, data } = yield call(qryCurrentSecurityRule);
      if (success) {
        yield put({
          type: 'save',
          payload: {
            securityRule: data,
            resetModal: true,
            userMsg: payload,
          },
        });
      }
    },

    *logout(_, { call, put }) {
      yield call(logout);
      loginSession.signOut();
      // window.portal.logonStatus = false;
      yield put({
        type: 'user/saveMenu',
        payload: {
          menuLoaded: false,
        },
      });
      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: false,
          currentAuthority: 'guest',
        },
      });
      yield put({
        type: 'global/updateState',
        payload: {
          currentUser: {},
        },
      });
      yield put({
        type: 'user/saveMenu',
        payload: {
          menu: [],
        },
      });
      const urlParams = new URL(window.location.href);
      const { hash } = urlParams;
      if (
        hash.indexOf('/join') <= -1 &&
        hash.indexOf('/login') <= -1 &&
        hash.indexOf('redirect') <= -1
      ) {
        yield put(
          routerRedux.push({
            pathname: '/login',
            // search:
            //   hash !== '#/'
            //     ? stringify({
            //       redirect: hash.indexOf('Own') > -1 ? `${hash.split('Own')[0]}Search` : hash,
            //     })
            //     : '',
          }),
        );
      }
    },

    // 忘记密码：重置密码前检查用户信息
    *checkUserInfo({ payload }, { call }) {
      const response = yield call(checkUserInfo, payload);
      return response;
    },

    *fetchMaintenance(_, { call, put }) {
      const { success, data } = yield call(qryMaintenance);
      if (success) {
        yield put({ type: 'updateMaintenance', payload: data });
      }
    },

    *refreshImgCode({ payload }, { call, put }) {
      const { data } = yield call(refreshImgCode, payload);
      if (data.code === 200) {
        yield put({
          type: 'save',
          payload: {
            imageInfo: get(data, 'data'),
          },
        });
      }
    },

    *isCaptchaNeeded(_, { call, put }) {
      const { success, data } = yield call(isCaptchaNeeded);
      if (success) {
        yield put({
          type: 'save',
          payload: {
            needCaptcha: data,
          },
        });
        return data;
      }
      return null;
    },

    *getCaptcha(_, { call, put }) {
      const { success, data: dataSet } = yield call(getCaptcha);
      if (success) {
        const imgUrl = `data: image/jpeg;base64,${btoa(
          new Uint8Array(dataSet).reduce((data, byte) => data + String.fromCharCode(byte), ''),
        )}`;
        yield put({
          type: 'save',
          payload: {
            captchaUrl: imgUrl,
          },
        });
      }
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    closeModal(state, { payload }) {
      return {
        ...state,
        ...payload,
        resetModal: false,
      };
    },
    updateMaintenance(state, { payload }) {
      return { ...state, maintenance: payload };
    },
  },
};

// 注释
// 1：在某些页面打开Modal层的情况下，会将文档流的body.style.overflow设置为hidden。
//    因为自服务设置的sessio过期自动登出机制，若长时间不操作则登出，但body.style.overflow的值被保留为hidden
//    导致进入自服务后不能对过长的页面进行滚动查看
