import get from 'lodash/get';
import {
  getOrderStates,
  getCustomerSubscriptionData,
  getSubtypeList,
  customerSubscriptionExport,
  downloadOfferList,
} from '@/pages/CustomerSubscription/services';

const initState = {
  eventType: [],
  orderStates: [],
  customerSubscriptionData: [],
  customerSubscriptionQueryParams: {},
  customerSubscriptionPagination: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
  subtypeList: [],
};

export default {
  namespace: 'customerSubscription',

  state: {
    ...initState,
  },

  effects: {
    *getOrderStates(_, { call, put }) {
      const { success, data = {} } = yield call(getOrderStates);
      if (success) {
        const orderStates = get(data, 'data') || [];
        yield put({
          type: 'save',
          payload: {
            orderStates,
          },
        });
      }
    },
    *getCustomerSubscriptionData({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          customerSubscriptionData: [],
        },
      });
      const { success = false, data = {} } = yield call(getCustomerSubscriptionData, payload);
      if (success) {
        const dataSource = get(data, 'data.list') || [];
        const customerSubscriptionPagination = get(data, 'data.pageInfo') || [];

        const customerSubscriptionData = dataSource.map(item => {
          const { offerName, subServTypeName, mainOfferName } = item;
          if (mainOfferName) {
            return {
              ...item,
              columnSerciveName: mainOfferName,
              columnSerciveType: subServTypeName,
              columnKeyword: offerName,
            };
          }
          return {
            ...item,
            columnSerciveName: offerName,
            columnSerciveType: subServTypeName,
            columnKeyword: '',
          };
        });

        yield put({
          type: 'save',
          payload: {
            customerSubscriptionData,
            customerSubscriptionPagination,
          },
        });
      }
    },
    *getSubtypeList(_, { call, put }) {
      const response = yield call(getSubtypeList);
      if (response) {
        const { data: result } = response;
        if (result && result.code === 200) {
          yield put({
            type: 'save',
            payload: {
              subtypeList: get(result, 'data') || [],
            },
          });
        }
      }
    },

    *customerSubscriptionExport({ payload }, { call, put }) {
      const { data: result } = yield call(customerSubscriptionExport, payload);
      if (result && result.code === 200) {
        return result;
      }
      return null;
    },

    // 导出合作伙伴及其子伙伴的Offer列表：下载
    *downloadOfferList({ payload }, { call }) {
      const { data: result, success } = yield call(downloadOfferList, payload);
      if (success) {
        const fileBlob = new Blob([result]);
        const blobUrl = window.URL.createObjectURL(fileBlob); // 创建下载的链接
        const a = document.createElement('a'); // 创建完成动作的元素
        a.style.display = 'none';
        a.download = payload.fileName; // 下载后的文件名
        document.body.appendChild(a); // 添加元素
        a.href = blobUrl;
        a.click();
        document.body.removeChild(a); // 下载完成移除元素
        window.URL.revokeObjectURL(blobUrl); // 释放掉blob对象
        return result;
      }
      return null;
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    reset() {
      return {
        ...initState,
      };
    },
  },
};
