/**
 * 登录信息/登出 会话管理
 * */
export default {
  saveToken(token) {
    return localStorage.setItem('X-CSRF-TOKEN', token);
  },

  getToken() {
    return localStorage.getItem('X-CSRF-TOKEN');
  },

  getUser() {
    let currentUser;
    try {
      currentUser = JSON.parse(localStorage.getItem('currentUser')) || {};
    } catch (e) {
      currentUser = {};
    }
    return currentUser;
  },

  saveUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  },

  getLanguage() {
    return localStorage.getItem('umi_locale');
  },

  setLanguage(language) {
    return localStorage.setItem('umi_locale', language);
  },

  getData(key) {
    let result;
    try {
      result = JSON.parse(localStorage.getItem(key)) || {};
    } catch (e) {
      result = {};
    }
    return result;
  },

  saveData(key, data) {
    localStorage.setItem(key, typeof data === 'string' ? data : JSON.stringify(data));
  },

  removeData(key) {
    localStorage.removeItem(key);
  },

  signOut() {
    localStorage.removeItem('X-CSRF-TOKEN');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentMenu');
    localStorage.removeItem('sysparams');
  },
};
