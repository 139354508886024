export default {
  LOG_SRC_IP: '源IP',
  USERMGR_USER_NAME: '用户名称',
  USERMGR_USER_CODE: '用户编码',
  LOG_EVENT_SOURCE: '事件源',
  LOG_EVENT_TYPE: '事件类型',
  LOG_EVENT_CODE: '事件编码',
  LOG_LOG_DATE: '日志时间',
  'navBar.lang': '语言',
  'menu.account.logout': '退出登录',
  'app.exception.back': '返回首页',
  'app.exception.description.403': '抱歉，你无权访问该页面',
  'app.exception.description.404': '抱歉，你访问的页面不存在',
  'app.exception.description.500': '抱歉，服务器出错了',
  OPERATE: '操作',
  COMMON_DETAIL: '详情',
  COMMON_YES: '是',
  COMMON_NO: '否',
  COMMON_ACTIVE: '启用',
  COMMON_INACTIVE: '禁用',
  COMMON_REMARKS: '备注',
  BTN_SEARCH: '查询',
  BTN_RESET: '重置',
  COMMON_NEW: '新建',
  COMMON_EDIT: '编辑',
  COMMON_DELETE: '删除',
  COMMON_CONFIRM: '确认',
  COMMON_MODAL: '弹出层',
  COMMON_OK: 'OK',
  COMMON_CANCEL: '取消',
  COMMON_CLOSE: '关闭',
  COMMON_GRANT: '授权',
  COMMON_PORTAL: '授权门户',
  COMMON_ROLE: '授权角色',
  COMMON_COMPONENT: '授权组件',
  COMMON_PORTLET: '授权Portlet',
  COMMON_LOCK: '锁定',
  COMMON_UNLOCK: '解锁',
  COMMON_DISABLE: '禁用',
  COMMON_ENABLE: '启用',
  COMMON_SELECT_ICON: '请选择',
  COMMON_MONTH: '本月',
  COMMON_LAST_MONTH: '上月',
  COMMON_WEEK: '本周',
  COMMON_LAST_WEEK: '上周',
  COMMON_DAY: '今日',
  COMMON_DATE_PLACE: '请选择日期',
  COMMON_RANGE_START_PLACE: '请选择开始时间',
  COMMON_RANGE_END_PLACE: '请选择结束时间',
  COMMON_REQUIRED: '必填',
  COMMON_NAME: '名称',
  COMMON_URL: 'URL',
  COMMON_REQUIRED_TIP: '不能为空。',
  COMMON_SEARCH: '搜索',
  COMMPN_DATA_EMPTY: '暂无数据',
  COMMON_STATE: '状态',
  COMMON_NO_DATA: '暂无数据。',
  SECONDS: '秒',
  REQUEST_ERROR: '请求出错',
  REQUEST_200: '服务器成功返回请求的数据',
  REQUEST_201: '新建或修改数据成功',
  REQUEST_202: '一个请求已经进入后台排队（异步任务）',
  REQUEST_204: '删除数据成功',
  REQUEST_400: '发出的请求有错误，服务器没有进行新建或修改数据的操作',
  REQUEST_401: '用户没有权限（令牌、用户名、密码错误）',
  REQUEST_403: '用户得到授权，但是访问是被禁止的',
  REQUEST_404: '发出的请求针对的是不存在的记录，服务器没有进行操作',
  REQUEST_406: '请求的格式不可得',
  REQUEST_410: '请求的资源被永久删除，且不会再得到的',
  REQUEST_422: '当创建一个对象时，发生一个验证错误',
  REQUEST_500: '服务器发生错误，请检查服务器',
  REQUEST_502: '网关错误',
  REQUEST_503: '服务不可用，服务器暂时过载或维护',
  REQUEST_504: '网关超时',
  BUTTON_ADD: '新增',
  FRAME_FAVOURITE_CUSTOMERS: '喜欢的客人',
  SYSTEM_SESSION_CONTINUE: '恢复session',
  SYSTEM_AUTO_LOGOUT: '你的系统session即将失效',
  SYSTEM_SESSION_EXPIRE_NOTICE: '距离自动登出还剩',
  ADD_COMPONENT_PRIVILEGE: '添加组件权限',
  PRIV_NAME_ERR: '请输入组件的权限名称',
  THEME_TITLE: '皮肤',
  COMMON_LOWER: '很低',
  COMMON_LOW: '低',
  COMMON_MODERATE: '中',
  COMMON_HIGH: '高',
  COMMON_HIGHER: '很高',
  COMMON_SUCCESS: '成功',
  'menu.account.customize': '首页定制',
  'menu.expand': '展开菜单',
  'menu.shrink': '收起菜单',
  'menu.home': '首页',
  REFRESH_PAGE: '刷新页面',
  'menu.account.aboutus': '版本信息',
  'menu.about': '关于',
  'menu.ok': '关闭',
  'menu.account.profile': '个人信息',
  COMMON_INPUT_REQUIRED: '不能为空。',
  COMMON_SUBMIT: '提交',
  COMMON_OPEN: '打开菜单',
  SUCCESS_OPEN: '打开菜单成功。',
  SUCCESS_FAVORITE_NEMU: '菜单收藏成功。',
  FAILED_FAVORITE_NEMU: '菜单收藏失败。',
  SUCCESS_MODIFY_FAVORITE_NEMU: '收藏菜单修改成功。',
  FAILED_MODIFY_FAVORITE_NEMU: '收藏菜单修改失败。',
  SUCCEED_DELETE_FAVORITE_NEMU: '删除收藏菜单成功。',
  FAILED_DELETE_FAVORITE_NEMU: '删除收藏菜单失败。',
  FAVORITE_NEMU_ONLYONE_TIPS: '默认打开菜单已经设置了，只有一个。',
  'menu.account.favorites': '收藏夹',
  'version.main.product': '包含产品：',
  'version.copyright': 'Copyright(c) 2010-2019 Whale Cloud Technology Co., Ltd.',
  'version.welcome':
    'Copyright(c) 2010-2019 Whale Cloud Technology Co., Ltd. All Rights Reserved. If you need any help or have any concern, please email to Branding_Public@iwhalecloud.com or dial our customer support hotline +86-25-6669555 Thank you for using Whale Cloud ZSmart. Warning: this computer program is protected by copyright law and international treaties. Unauthorized reproduction or distribution of this program, or any portion of it, may result in severe civil and criminal penalties, and will be prosecuted to the maximum extent possible under the law.',
  'version.thank.using': '感谢您使用ZSmart产品。',
  'version.main.first': '修复一些问题',
  'version.main.second': '做了一些优化',
  CHANGE_PWD_MSG: '请修改密码',
  CHANGE_PWD_SUCCESS: '密码修改成功',
};
